import { Type } from "@angular/core"
import { AxLoginComponent } from "./login/login.component"

export type StorageType = 'NONE' | 'SESSION' | 'LOCAL'

export interface AuthHandlerConfig { 

    host: string
    loginEndpoint: string
    validateEndpoint: string

    /**
     * list of regex patters for urls where pre processing of requests should be exclude.
     */
    excludeRequestUrls: string[]

    /**
     * list of regex patters for urls where post processing of responses should be exclude.
     */
    excludeResponseUrls: string[]

    /**
     * list of regex patters for urls where re-authentication will not be performed if the.
     * service returns an Unauthorized (401)
     */
    excludeReauthUrls: string[]
}

export interface JwtAuthHandlerConfig extends AuthHandlerConfig {

    jwtRequestHeaderName: string
    jwtResponseHeaderName: string
}

export const dfltAuthHandlerConfig: AuthHandlerConfig = {

    host: '',
    loginEndpoint: '/auth/login',
    validateEndpoint: '/auth/validate',

    excludeRequestUrls: [],
    excludeResponseUrls: [],
    excludeReauthUrls: [],
}

export const dfltJwtAuthHandlerConfig: JwtAuthHandlerConfig = {

    ...dfltAuthHandlerConfig,

    jwtRequestHeaderName: 'X-Auth-Token',
    jwtResponseHeaderName: 'X-Auth-Token',
}

export interface CredentialsProviderConfig {

    storageType: StorageType
    storageKeyName: string
}

export const dfltCredentialsProviderConfig: CredentialsProviderConfig = {

    storageType: 'NONE',
    storageKeyName: 'ax-auth-token',
}

export interface MatDialogLoginConfig extends CredentialsProviderConfig {

    usernameLabel: string
    passwordLabel: string
    loginSubmitLabel: string
    backdropClass: string
    panelClass: string
    loginComponent: Type<AxLoginComponent>
}

export const dfltMatDialogLoginConfig: MatDialogLoginConfig = {

    ...dfltCredentialsProviderConfig,

    usernameLabel: 'Username',
    passwordLabel: 'Password',
    loginSubmitLabel: 'Login',
    backdropClass: 'ax-login-backdrop',
    panelClass: 'ax-login-panel',
    loginComponent: AxLoginComponent
}

export interface LoginRouteConfig {

    homePath: string
    loginPath: string
    loginComponent: Type<AxLoginComponent>

}

export const dfltLoginRouteConfig: LoginRouteConfig = {

    homePath: '/',
    loginPath: '/login',
    loginComponent: AxLoginComponent
}