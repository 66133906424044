import { HttpClient } from '@angular/common/http'
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms'

import { combineLatest } from 'rxjs'

import { DashboardConfig } from '../../../../../dashboard.config'
import { DASHBOARD_CONFIG } from '../../../../../dashboard.config.token'
import { IMoneyOperator } from '../../../../../data/types'
import { MoneyOperatorComponent } from '../money-operator-component'

export interface IfscCode {
    code: string
    branch: string
}

const environment = {
    serverUrl: '',
}

@Component({
    selector: 'app-india-banks',
    templateUrl: './india-banks.component.html',
    styleUrls: ['./india-banks.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IndiaBanksComponent implements MoneyOperatorComponent, OnInit {

    ifscCtrl = new FormControl(null, [Validators.required, Validators.pattern(/^[A-Z0-9]{11}$/)])
    accountNumCtrl = new FormControl(null, [Validators.required, Validators.pattern(/^\d{2,16}$/)])

    bankName: string
    branchName: string

    private moneyOperator: IMoneyOperator

    private onModelChange = (val) => { }
    private onValidatorChange = () => { }

    constructor(
        private http: HttpClient,
        @Inject(DASHBOARD_CONFIG) private config: DashboardConfig
    ) {

        combineLatest([this.ifscCtrl.valueChanges, this.accountNumCtrl.valueChanges])
            .subscribe(([ifsc, accountNum]: [string, string]) => {
                this.onModelChange({ ifsc: ifsc, accountNum: accountNum })
            })

        combineLatest([this.ifscCtrl.statusChanges, this.accountNumCtrl.statusChanges])
            .subscribe(_ => this.onValidatorChange())

        this.ifscCtrl.valueChanges.subscribe((ifsc: string) => {

            if (ifsc.length == 11) {

                this.http.get(`${this.config.serverUrl}/v3/remit/agent/${this.moneyOperator.id}/get/${ifsc}`)
                    .subscribe({
                        next: (resp: any) => {

                            if (resp) {

                                this.bankName = resp.bank.name
                                this.branchName = resp.bank.branch.name

                                this.ifscCtrl.setErrors(null)

                            } else {

                                this.bankName = undefined
                                this.branchName = undefined
                                this.ifscCtrl.setErrors({ 'ifsc-invalid': true })
                            }
                        },
                        error: _ => {
                            this.bankName = undefined
                            this.branchName = undefined
                            this.ifscCtrl.setErrors({ 'ifsc-invalid': true })
                        }
                    })

            } else {

                this.bankName = undefined
                this.branchName = undefined
            }
        })
    }

    setMoneyOperator(moneyOperator: IMoneyOperator): void {
        this.moneyOperator = moneyOperator
    }

    setDisabledState?(isDisabled: boolean): void { }

    ngOnInit(): void { }

    focus(): void {
        if (this.ifscCtrl.invalid) {
            (this.ifscCtrl as any).focus()
        } else if (this.accountNumCtrl.invalid) {
            (this.accountNumCtrl as any).focus()
        }
    }

    writeValue(accountDetails: any): void {

        if (accountDetails) {

            this.ifscCtrl.setValue(accountDetails.ifsc)
            this.accountNumCtrl.setValue(accountDetails.accountNum)
        }
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any): void {
    }

    validate(control: AbstractControl): ValidationErrors {

        if (this.ifscCtrl.invalid) {
            return this.ifscCtrl.errors
        }

        return this.accountNumCtrl.errors
    }

    registerOnValidatorChange?(fn: () => void): void {

        this.onValidatorChange = fn
    }

    error(formControl: FormControl) {

        return formControl.errors && Object.entries(formControl.errors)[0][0];
    }
}
