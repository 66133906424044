import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { IMoneyOperator } from '../../../../../data/types'
import { MoneyOperatorComponent } from '../money-operator-component';

@Component({
    selector: 'app-account-num',
    templateUrl: './account-num.component.html',
    styleUrls: ['./account-num.component.scss']
})
export class AccountNumComponent implements MoneyOperatorComponent, OnInit {

    accountNumCtrl = new FormControl(null, [ Validators.required, Validators.pattern(/^\d{2,}$/) ])

    private onModelChange = (val) => {}
    private onValidatorChange = () => {}

    constructor() { }

    setMoneyOperator(moneyOperator: IMoneyOperator): void {
    }
    
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {

        this.accountNumCtrl
            .valueChanges
            .subscribe((accountNum: string) => {
                this.onModelChange({ accountNum: accountNum })
            })
        
        this.accountNumCtrl.statusChanges
            .subscribe(_ => this.onValidatorChange())
    }

    writeValue(accountDetails: any) {

        if (accountDetails) {

            this.accountNumCtrl.setValue(accountDetails.accountNum)
        }
    }

    registerOnChange(fn: any) {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any) {
    }

    validate(control: AbstractControl): ValidationErrors {
        
        return this.accountNumCtrl.errors 
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn
    }

    focus() {

        if (this.accountNumCtrl.invalid) {
            (this.accountNumCtrl as any).focus()
        }
    }

    error(formControl: FormControl) {

        return formControl.errors && Object.entries(formControl.errors)[0][0];
    }
}
