import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ISignUp } from '../types/customer';
import { Observable, catchError, throwError } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { ServerError } from '@rr/dashboard';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document) { }

  /**
   * signup / register a new customer
   * 
   * @param signUp newly signed up customer details
   * @returns 
   */
  signup(signUp: ISignUp): Observable<any> {

    return this.http
        .post(`${environment.serverUrl}/v3/register`, signUp)
        .pipe(
            catchError((resp: any) => throwError(() => new ServerError(resp.error)))
        )
  }

  /**
   * submit a forgotten password request
   * 
   * @param msisdn 
   * 
   * @returns 
   */
  forgotPassword(msisdn: string): Observable<any> {

    return this.http
        .post(`${environment.serverUrl}/v3/auth/password/forgotten`, { 
            msisdn: msisdn 
        })
        .pipe(
            catchError((resp: any) => throwError(() => new ServerError(resp.error)))
        )
  }

  /**
   * reset the password of a existing customer 
   * 
   * @param msisdn - the customers msisdn
   * @param token - the password reset token
   * @param newPassword - new password
   * @returns 
   */
  resetPassword(msisdn: string, token: string, newPassword: string): Observable<any> {

    return this.http
        .post(`${environment.serverUrl}/v3/auth/password/reset`, { 
            msisdn: msisdn, 
            token: token, 
            password: newPassword
        })
        .pipe(
            catchError((resp: any) => throwError(() => new ServerError(resp.error)))
        )
  }

  /**
   * goto the webapp 
   */
  gotoWebapp() {

    this.document.location.href = environment.webappUrl
  }

  /**
   * remember the users details
   *  - the previously used MSISDN 
   *  - that the user is registered
   * 
   * @param msisdn the latest MSISDN used
   */
  rememberDetails(msisdn: string) {

    localStorage.setItem('msisdn', msisdn)
    localStorage.setItem('registered', 'true')
  }

  /**
   * get the previously used MSISDN 
   * when details have been remembered
   * 
   * @returns the previously used MSISDN
   */
  getPreviousMsisdn(): string | null {

    return localStorage.getItem('msisdn')
  }

  /**
   * 
   * @returns if the user has previously registered with rocket remit
   */
  isRegistered(): boolean {

    // NOTE: `ls.rr-registered` is the legacy registered flag
    return (!!localStorage.getItem('registered') || !!localStorage.getItem('ls.rr-registered'))
  } 
}
