<!-- <div class="table-box">
    <div class="table-cell">
        <div class="modal-body">
            <h3 class="mod-title">
                <span class="txt">We have sent you an SMS!</span>
            </h3>
            <div class="mod-des">
                <span class="txt">Please check your mobile phone for our SMS.</span>
            </div>
            <div class="mod-info">
                <span class="txt">
                    To complete the transaction, we have sent an SMS message to your mobile phone.  Please read and follow the instructions in the SMS.
                </span>
            </div>

            <div class="buttons-wrap">
                <div class="buttons-row">
                    <button class="btn btn-primary" mat-dialog-close>Close</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div mat-dialog-title>We have sent you an SMS!</div>
<div mat-dialog-content>
    <div class="mod-des">
        Please check your mobile phone for our SMS.
    </div>
    <div class="mod-info">
        To complete the transaction, we have sent an SMS message to your mobile phone.  Please read and follow the instructions in the SMS.
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn btn-primary" mat-dialog-close>Close</button>
</div>