import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, last, mergeMap, share, tap } from 'rxjs/operators';
import { AuthHandler } from './auth.handler';
import { Credential } from './model';
import { CredentialsProvider } from './credentials.provider';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private inFlightAuth = false
    private inFlightAuth$!: Observable<HttpEvent<any>>

    constructor(
        private authHanlder: AuthHandler<Credential>,
        private credentialsProvider: CredentialsProvider<Credential>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(this.authHanlder.preRequest(req)).pipe(
            tap({
                next: (event: HttpEvent<any>) => {

                    if (event instanceof HttpResponse) {

                        this.authHanlder.postResponse(event as HttpResponse<any>)
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {

                console.log('request failed', req)
                console.log('can retreive new credentials', this.credentialsProvider, this.credentialsProvider.canRetrieveNewCredentials())

                if (this.credentialsProvider.canRetrieveNewCredentials() && error.status === 401) {

                    // if the url is should not trigger re-authentication throw the error
                    if (this.authHanlder.excludeReauth(req)) {
                        return throwError(() => error)
                    }

                    // the login / auth process is in-flight 
                    if (this.inFlightAuth) {


                        // wait until finished before retrying
                        return this.inFlightAuth$.pipe(
                            // last(),
                            mergeMap(_ => {
                                return this.intercept(req, next)
                            })
                        )

                    } else {

                        this.inFlightAuth = true

                        // perform login and reset auth in-flight when complete
                        this.inFlightAuth$ = this.credentialsProvider
                            .newCredentials()
                            .pipe(
                                // last(),
                                mergeMap((user) => {
                                    this.inFlightAuth = false
                                    return this.intercept(req, next)
                                }),
                                share()
                            )

                        return this.inFlightAuth$
                    }
                }
                
                return throwError(() => error)
            })
        )
    }
}