import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrls: ['./menu-bar.component.scss'],
    animations: [
        trigger('heightAnimation', [
            transition('* <=> *', [
                style({ height: '{{startHeight}}px', overflow: 'hidden' }),
                animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '{{endHeight}}px' }))
            ], { params: { startHeight: 0, endHeight: 0 } }) // Default parameters
        ])
    ]
})
export class MenuBarComponent implements AfterViewInit {
    @ViewChild('menuContent') menuContent!: ElementRef;
    startHeight: number = 0;
    endHeight: number = 0;

    routeName = '/'

    constructor(private router: Router) { }

    ngAfterViewInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: Event) => {
            setTimeout(() => {
                if (this.menuContent && this.menuContent.nativeElement) {
                    this.endHeight = this.menuContent.nativeElement.clientHeight;
                    this.triggerAnimation();
                    this.routeName = (event as NavigationEnd).url
                }
            });
        });
    }


    triggerAnimation() {
        console.log('Animating from', this.startHeight, 'to', this.endHeight); // Check the start and end values
        setTimeout(() => {
            this.startHeight = this.endHeight;
        }, 500);
    }
}
