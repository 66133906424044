import {
    Directive,
    Input,
    HostListener,
    OnInit,
    ElementRef,
    forwardRef
} from '@angular/core'
import {
    AbstractControl,
    ControlValueAccessor,
    Validator,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    ValidatorFn
} from '@angular/forms'

import { PhoneNumberUtil, PhoneNumberFormat, AsYouTypeFormatter, PhoneNumberType } from 'google-libphonenumber'


export function mobileRegionValidator(countryCode: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        const phoneUtil = PhoneNumberUtil.getInstance()

        try {
            const msisdn = phoneUtil.parse(control.value)

            if (!phoneUtil.isValidNumberForRegion(msisdn, countryCode)) {
                return { 'invalid-region': 'number is not valid for region' }
            }
        } catch (e) {}

        return null
    }
}

export function mobileTypeValidator(type: PhoneNumberType): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        const phoneUtil = PhoneNumberUtil.getInstance()

        try {
            
            const msisdn = phoneUtil.parse(control.value)

            if (phoneUtil.getNumberType(msisdn) !== type) {
                return { 'invalid-type': 'invalid phone number type'}
            }

        } catch (e) {}

        return null
    }
}

@Directive({
    selector: '[axMsisdn]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MsisdnDirective),
        multi: true,
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => MsisdnDirective),
        multi: true
    }]
})
export class MsisdnDirective implements OnInit, ControlValueAccessor, Validator {

    @Input()
    countryCode = 'ZZ'

    @Input()
    mobile = false

    private phoneUtil = PhoneNumberUtil.getInstance()
    private modelValue: string | null = null
    private asYouTypeFormatter!: AsYouTypeFormatter

    private onModelChange = (_: string | null) => {}
    private onValidationChange = () => {}

    constructor(private elementRef: ElementRef<HTMLInputElement>) {

        elementRef.nativeElement.type = 'tel'
        elementRef.nativeElement.inputMode = 'tel'
    }

    ngOnInit(): void {

        this.asYouTypeFormatter = new AsYouTypeFormatter(this.countryCode)
    }


    writeValue(msisdn: string): void {

        if (msisdn) {

            if (this.countryCode === 'ZZ') {
                this.modelValue = msisdn
            } else  {
                const msisdnObj = this.phoneUtil.parse(msisdn, this.countryCode)
                this.modelValue = this.phoneUtil.format(msisdnObj, PhoneNumberFormat.NATIONAL)
            }

            this.formatMsisdn(this.modelValue)
        }
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    validate(control: AbstractControl): ValidationErrors | null {

        try {

            const msisdn = this.phoneUtil.parse(control.value, this.countryCode)

            if (!this.phoneUtil.isValidNumber(msisdn)) {
                return { 'invalid-msisdn': 'number is not valid' }
            }

            // if (!this.phoneUtil.isValidNumberForRegion(msisdn, this.countryCode)) {
            //     return { 'invalid-msisdn': 'number is not valid for region' }
            // }

            // if (this.mobile && this.phoneUtil.getNumberType(msisdn) !== PhoneNumberType.MOBILE) {
            //     return { 'invalid-msisdn': 'number is not valid mobile' }
            // }

            return null

        } catch (e) {}

        return { 'invalid-msisdn': 'mobile number is not valid' }
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidationChange = fn
    }

    @HostListener('keydown', ['$event'])
    handleKeydown(event: any) {

        const keyCode = event.which || event.charCode || event.keyCode

        // backspace || delete || safari delete
        if (keyCode === 8 || keyCode === 46 || keyCode === 63272) {

            event.preventDefault()

            const viewValue = this.viewValue
            const viewValueLength = viewValue.length
            const selectionStart = this.inputSelection.selectionStart
            const selectionEnd = this.inputSelection.selectionEnd
            const selectionRange = Math.abs(selectionEnd - selectionStart) > 0

            let deleteStart = selectionStart
            let deleteEnd = selectionEnd

            // only process delete if no selection or election does not contain number
            if (!selectionRange || !/\d/.test(viewValue.slice(selectionStart, selectionEnd))) {

                // handle backspace if start of selection is not at beginning
                if (keyCode === 8 && selectionStart > 0) {

                    // find the location of the first proceeding deletable (numeric) character
                    let deletableChar = false
                    while (deleteStart > 0 && !deletableChar) {

                        const c = viewValue.charAt(deleteStart - 1)
                        deletableChar = /\d/i.test(c)
                        deleteStart--
                    }
                }

                // handle delete and selection is not at end of value
                if ((keyCode === 46 || keyCode === 63272) && selectionEnd < viewValue.length) {

                    // find the first deletable (numeric) character after the selection
                    let deletableChar = false
                    while (deleteEnd < viewValue.length && !deletableChar) {

                        const c = viewValue.charAt(deleteEnd)
                        deletableChar = /\d/i.test(c)
                        deleteEnd++
                    }
                }
            }

            const newViewModel = viewValue.slice(0, deleteStart) + viewValue.slice(deleteEnd)
            this.formatMsisdn(newViewModel)

            if (viewValueLength !== selectionStart) {
                this.setCursorPosition(deleteStart)
            }
        }
    }

    @HostListener('input', ['$event'])
    handleInput(event: any) {

        const selectionStart = this.inputSelection.selectionStart

        const viewValue = this.viewValue
        const viewValueLength = viewValue.length
        const keyCode = viewValue.charCodeAt(selectionStart - 1)

        const prefix = (this.countryCode === 'ZZ' && viewValue.charAt(0) !== '+') ? '+' : ''
        this.formatMsisdn(prefix + this.viewValue)

        if (viewValueLength !== selectionStart) {
            this.setCursorPosition(selectionStart)
        }

        event.preventDefault()
    }

    formatMsisdn(value: string) {

        if (value) {

            const leadingPlus = value.charAt(0) === '+'
            const rawMsisdn = (leadingPlus ? '+' : '') + value.replace(/\D/g, '')

            try {
                const msisdn = this.phoneUtil.parse(rawMsisdn, this.countryCode)
                this.modelValue = this.phoneUtil.format(msisdn, PhoneNumberFormat.E164)

            } catch (e) {

                this.modelValue = value ? ('+' + value.replace(/\D/g, '')) : null
            }

            let viewValue = ''
            this.asYouTypeFormatter.clear()

            rawMsisdn.split('').forEach((c, i) => viewValue = this.asYouTypeFormatter.inputDigit(c))

            this.viewValue = viewValue

            this.onModelChange(this.modelValue)
            // TODO: detect when validation has changed
            this.onValidationChange()
        }
    }

    get viewValue() {
        return this.elementRef.nativeElement && this.elementRef.nativeElement.value
    }

    set viewValue(viewValue: string) {
        this.elementRef.nativeElement.value = viewValue
    }

    get inputSelection(): any {

        let selectionStart = 0
        let selectionEnd = 0

        const nativeElement = this.elementRef.nativeElement

        if (typeof nativeElement.selectionStart === 'number' && typeof nativeElement.selectionEnd === 'number') {
            selectionStart = nativeElement.selectionStart
            selectionEnd = nativeElement.selectionEnd
        }

        return {
            selectionStart,
            selectionEnd
        }
    }

    setCursorPosition(position: number) {

        const nativeElement = this.elementRef.nativeElement

        if (nativeElement.setSelectionRange) {
            nativeElement.focus()
            nativeElement.setSelectionRange(position, position)
        }
    }
}
