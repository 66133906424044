import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../data/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { determinePasswordStrength, passwordStrengthValidator } from '../../../shared/data/functions/password.functions';
import { ServerError } from '@rr/dashboard';

/**
 * `ResetPasswordComponent` allows users to reset their password by providing a reset code and a new password.
 * The component utilizes Angular forms for data binding, validation, and submission.
 * It also uses the `PasswordService` to evaluate the strength of the password.
 *
 * @property {FormGroup} resetPasswordForm - Form group for the reset password form.
 * @property {FormControl} passwordCtrl - Form control for the user's new password.
 * @property {string} passwordStrength - A string indicating the strength of the password.
 */
@Component({
  selector: 'app-password-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent {

    resetPasswordForm: FormGroup;  // Define the form group
    tokenCtrl = new FormControl('', { 
        nonNullable: true, 
        validators: [ Validators.required ] 
    })
    passwordCtrl = new FormControl('', { 
        nonNullable: true, 
        validators: [
            Validators.required, 
            passwordStrengthValidator
        ] 
    });

    msisdn: string | null

    /**
     * Constructor initializes FormBuilder and PasswordService dependencies,
     * and defines the form group and controls for the reset password form.
     *
     * @param {FormBuilder} fb - The FormBuilder for creating form controls and groups.
     * @param {PasswordService} passwordService - The service for handling password-related operations.
     */
    constructor(
        fb: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        route: ActivatedRoute
    ) {
        this.resetPasswordForm = fb.group({
            token: this.tokenCtrl,  // Define resetCode form control with Validators.required
            password: this.passwordCtrl  // Define newPassword form control with Validators.required and password strength validation
        });

        this.msisdn = route.snapshot.queryParamMap.get('msisdn')
    }

    /**
     * Handles the reset password form submission, and logs the form data
     * if the form is valid. Otherwise, marks all controls as touched to
     * trigger validation error messages.
     */
    resetPassword(): void {

        if (this.resetPasswordForm.valid && this.msisdn) {

            this.authService
                .resetPassword(this.msisdn, this.tokenCtrl.value, this.passwordCtrl.value)
                .subscribe({
                    next: _ => {

                        if (this.msisdn) {
                            this.authService.rememberDetails(this.msisdn)
                        }
                        this.router.navigate([ '/' ])
                    },
                    error: (error: ServerError) => {

                        // the server returned the warning code for unauthorised token
                        if (error.code == 'WR-401' || error.code == 'WR-404') {
                            this.tokenCtrl.setErrors({ invalid: true })
                        } else {
                            // TODO: deal with generic server errors
                        }
                    }
                })

        } else {
            // Handle form validation errors
            this.resetPasswordForm.markAllAsTouched();
        }
    }

    get passwordStrength(): string {

        return determinePasswordStrength(this.passwordCtrl.value)
    }
}
