@use "sass:math";
@import 'variables';

$infix-padding: math.div(17, 24);
/*
.btn-hide-form {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;

    .ico {
        background-image: url('../../../../assets/close-ico.svg'); // replace with server sided asset
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px; // Set dimensions accordingly
        height: 24px;
        margin-left: 10px; // Add some space between the text and the icon
    }
}
*/
.login {
    // background-color: $brand-colour-1;
    // h2 {
    //   padding-top: 7vh;
    //   color: $body-bg;
    //   margin-bottom: 60px;
    //   font-size: 3.214286em;
    //   font-weight: bold;
    // }
    // // .mat-mdc-form-field {
    // //     display: block;
    // //     @media (max-width: 768px) {
    // //         margin: 0 5vw; // Add margin on left and right
    // //     }
    // // }

    // .btn.btn-primary {
    //     max-width: 80%;
    //     border: 2px solid $body-bg;
    //     margin-bottom: 5vh;
    //     &:hover {
    //         color: $brand-colour-1;
    //         background: $body-bg;
    //     }
    //     span {
    //         font-size: 1.4em;
    //     }
    // }

    // .alert {
    //     margin-top: 15px;
    // }

    .additional-links {
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      a {
        color: $body-bg;
        margin: 5px 0;
        display: block;
      }
    }
}