import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { AxLoginComponent, AxUser } from '@assentinel/auth'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends AxLoginComponent implements OnInit {

    loginForm = new FormGroup({
        msisdn: this.usernameCtrl,
        password: this.passwordCtrl
    })

    ngOnInit(): void {
        
        // get msisdn from local storage and patch into username
        const msisdn = localStorage.getItem('msisdn')

        if (msisdn) {

            this.usernameCtrl.patchValue(msisdn)
        }

        // deal with loggedIn events
        this.loggedIn.subscribe({
            next: (user: AxUser) => {
                localStorage.setItem('msisdn', user.username)
            }
            // error: _ => {
            //     this.loginForm.setErrors({
            //         notAuthenticated: true
            //     })
            // }
        })
    }

    login() {
    
        if (this.loginForm.valid) {

            super.login()
        }
    }
}
