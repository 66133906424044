import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, of, throwError } from 'rxjs';
import { catchError, last, map, mergeMap, share, tap } from 'rxjs/operators';
import { AuthHandler } from './auth.handler';
import { ROLE_PRIVILEGES } from './auth.injectors';
import { AxUser, Credential, RolePrivileges } from './model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user$!: Subject<AxUser>
    private _userPrivileges: string[] = []

    constructor(
        private authHandler: AuthHandler<Credential>,
        @Optional() @Inject(ROLE_PRIVILEGES) private rolePrivileges: RolePrivileges) { }

    get authenticated(): Observable<boolean> {
        return this.isAuthenticated()
    }

    isAuthenticated(): Observable<boolean> {

        return this.authHandler
            .validate(false)
            .pipe(
                map((user: AxUser) => {

                    if (!this._user$) {
                        this._user$ = new ReplaySubject(1)
                    }
                    this._user$.next(user)

                    return (!!user)
                }),
                catchError(() => of(false))
            )
    }
/*
    private setUser(user: AxUser) {

        if (!this._user$) {
            this._user$ = new BehaviorSubject(user)
        } else {
            this._user$.next(user)
        }

        this._userPrivileges = []
        if (this.rolePrivileges) {
            user.roles.forEach((role: string) => 
                this.rolePrivileges[role].forEach((privilege: string) => { 
                    if (!this._userPrivileges.some((p) => p === privilege)) { 
                        this._userPrivileges.push(privilege) 
                    } 
                })
            )
        }

        console.log('user privileges', this._userPrivileges)
    }
*/
    get user(): Observable<AxUser> {

        if (!this._user$) {
            
            this._user$ = new ReplaySubject(1)

            this.authHandler.validate(true)
                .subscribe((user: AxUser) => this._user$.next(user))
        }

        return this._user$.asObservable()
    }

    refreshUser() {

        if (!this._user$) {
            this._user$ = new ReplaySubject(1)
        }

        this.authHandler.validate(true)
            .subscribe((user: AxUser) => this._user$.next(user))
    }

    hasRole(roleToCheck: string): boolean {

        if (this._user$) {

            //return this._user$.roles.some((role: string) => role === roleToCheck)
        }

        return false
    }

    hasPrivilege(privilegeToCheck: String) {

        if (this.user) {

            return this._userPrivileges.some((privilege) => privilege === privilegeToCheck)
        }

        return false
    }

    validate(): Observable<AxUser> {

        return this.authHandler.validate(true).pipe(tap({
            next: (user: AxUser) => { 
                
                if (!this._user$) {
                    this._user$ = new ReplaySubject(1)
                }

                this._user$.next(user)
            }
        }))
    }

    authenticate(credential: Credential): Observable<AxUser> {

        return this.authHandler
            .authenticate(credential)
            .pipe(
                tap({
                    next: (user: AxUser) => {

                        if (!this._user$) {
                            this._user$ = new ReplaySubject(1)
                        }
        
                        this._user$.next(user)
                    }
                })
            )
    }

    // login(): Observable<AxUser> {
    //     return this.authHandler.login().pipe(tap({
    //         next: (user: AxUser) => this.setUser(user)
    //     }))
    // }

    logout(): Observable<boolean> {
        return this.authHandler.logout()
    }
}
