import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'rr-remit-complete',
    templateUrl: './remit-complete.component.html',
    // styles: [ require('./remit-complete.component.scss').toString() ],
    // styleUrls: ['./remit-complete.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RemitCompleteComponent {

    // constructor() {}

    // ngOnInit() {}

}
