import { Inject, Injectable, Optional } from '@angular/core'
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { map, share } from 'rxjs/operators';

import { CredentialsProviderConfig, dfltCredentialsProviderConfig, dfltMatDialogLoginConfig, MatDialogLoginConfig } from "./auth.config";
import { CREDENTIAL_PROVIDER_CONFIG, MAT_DIALOG_LOGIN_CONFIG } from './auth.injectors';
import { Credential, AuthToken, AxUser } from "./model";

/**
 * manage credentials 
 */
export abstract class CredentialsProvider<T extends Credential> {

    private _credential: T | undefined

    protected _config: CredentialsProviderConfig = dfltCredentialsProviderConfig

    constructor(config: CredentialsProviderConfig) {

        this._config = {
            ...dfltCredentialsProviderConfig,
            ...config
        }
    }

    saveCredential(credential: T) {

        switch (this._config.storageType) {
            case 'NONE':
                this._credential = credential
                break
            case 'SESSION':
                window.sessionStorage.setItem(this._config.storageKeyName, JSON.stringify(credential))
                break
            case 'LOCAL':
                window.localStorage.setItem(this._config.storageKeyName, JSON.stringify(credential))
                break
        }
    }

    retrieveCredential(): T | void {

        let credentialStr

        switch (this._config.storageType) {
            case 'NONE':
                return this._credential
            case 'SESSION':
                credentialStr = window.sessionStorage.getItem(this._config.storageKeyName)
                return credentialStr ? JSON.parse(credentialStr) : null
            case 'LOCAL':
                credentialStr = window.localStorage.getItem(this._config.storageKeyName)
                return credentialStr ? JSON.parse(credentialStr) : null
        }
    }

    removeCredentials(): void {

        switch (this._config.storageType) {
            case 'NONE':
                this._credential = undefined
                return
            case 'SESSION':
                window.sessionStorage.removeItem(this._config.storageKeyName)
                return
            case 'LOCAL':
                window.localStorage.removeItem(this._config.storageKeyName)
                return
        }
    }

    abstract canRetrieveNewCredentials(): boolean

    abstract newCredentials(): Observable<T | void>
}

@Injectable()
export class NoopCredentialsProvider extends CredentialsProvider<AuthToken> {

    constructor(
        private dialog: MatDialog,
        @Optional() @Inject(CREDENTIAL_PROVIDER_CONFIG) config: CredentialsProviderConfig
    ) {

        super({
            ...dfltCredentialsProviderConfig,
            ...config
        } as CredentialsProviderConfig)
    }

    canRetrieveNewCredentials(): boolean {
        return false
    }

    newCredentials(): Observable<AuthToken | void> {
        throw new Error('unable to obtain new credentials for NoopCredentialsProvider')
    }
}

/**
 * 
 */
@Injectable()
export class MatDialogLoginFormCredentialsProvider extends CredentialsProvider<AuthToken> {

    inflightCredentials = false
    inflightCredentials$!: Observable<AuthToken | void>

    constructor(
        private dialog: MatDialog,
        @Optional() @Inject(MAT_DIALOG_LOGIN_CONFIG) config: MatDialogLoginConfig
    ) {

        super({
            ...dfltMatDialogLoginConfig,
            ...config
        } as MatDialogLoginConfig)
    }

    canRetrieveNewCredentials(): boolean {

        return !!(this._config as MatDialogLoginConfig).loginComponent
    }

    newCredentials(): Observable<AuthToken | void> {
        
        if ((this._config as MatDialogLoginConfig).loginComponent) {

            const matDialogRef = this.dialog
                    .open((this._config as MatDialogLoginConfig).loginComponent, {
                        disableClose: true,
                        maxWidth: 400,
                        minWidth: '50vw',
                        width: '100%',
                        position: { top: '48px' },
                        backdropClass: (this._config as MatDialogLoginConfig).backdropClass,
                        panelClass: (this._config as MatDialogLoginConfig).panelClass
                    })

            matDialogRef.componentInstance.loggedIn
                .pipe(
                    share()
                )
                .subscribe((user: AxUser) => matDialogRef.close(user))

            return matDialogRef.componentInstance.loggedIn
                .pipe(
                    map(_ => this.retrieveCredential())
                )
        }

        throw new Error('unable to obtain new credentials for MatDialogLoginFormCredentialsProvider: no loginComponent defined')
        // should be able to return the `matDialogRef.componentInstance.loggedIn` but for some reason it is being treated as a cold observable
        // return matDialogRef.afterClosed()
    }
}