import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core'
import { FormControl, AbstractControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'

import { Subscription } from 'rxjs'

import { IFee, ILimit, ILimitsAndFees } from '../../../../data'
import { CustomerService, NavigationService } from '../../../../data/service'

import { PoliFirstTimeLoadComponent } from './poli-first-time-load/poli-first-time-load.component'

@Component({
    selector: 'rr-poli',
    templateUrl: './poli.component.html',
    styleUrls: ['./poli.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PoliComponent {

    loadAmountCtrl = new FormControl()
    loadFormGrp = new FormGroup({ amount: this.loadAmountCtrl })

    loadSubscription: Subscription

    limit: ILimit
    fee: IFee

    @Output()
    back = new EventEmitter()

    constructor(
        private dialog: MatDialog,
        private customerService: CustomerService,
        private navService: NavigationService) { }

    ngOnInit(): void {

        this.customerService.getLimitsAndFees('POLI')
            .subscribe((limitsAndFees: ILimitsAndFees) => {

                this.limit = limitsAndFees.limit
                this.fee = limitsAndFees.fee

                this.loadAmountCtrl.addValidators(Validators.min(this.limit.min))
                if (this.limit.max) {
                    this.loadAmountCtrl.addValidators(Validators.max(this.limit.max))
                }
            })
    }

    load() {
        console.log('load', this.loadAmountCtrl)

        if (this.loadAmountCtrl.valid) {

            this.loadSubscription = this.customerService
                .poliLoad(this.loadAmountCtrl.value)
                .subscribe({
                    next: (response: any) => {

                        if (response.quarantine) {

                            this.dialog.open(PoliFirstTimeLoadComponent, {
                                backdropClass: 'rr-light',
                                disableClose: true,
                                position: { top: '30px' }
                            })
                                .afterClosed()
                                .subscribe(_ => {
                                    this.navService.redirectGet(response.poliUrl)
                                })
                        } else {

                            this.navService.redirectGet(response.poliUrl)
                        }
                    },
                    error: (error) => {

                        console.log('error', error)

                        switch (error.code) {

                            case 'ER-230': // send min limit
                                this.loadAmountCtrl.setErrors({
                                    min: {
                                        min: error.metadata.limit,
                                        actual: this.loadAmountCtrl.value
                                    }
                                })
                                break

                            case 'ER-240': // send max limit
                                this.loadAmountCtrl.setErrors({
                                    max: {
                                        max: error.metadata.limit,
                                        actual: this.loadAmountCtrl.value
                                    }
                                })
                                break

                            default:
                                this.loadAmountCtrl.setErrors({ 'server-error': true })
                        }
                    }
                })
        }

    }

    firstError(control: AbstractControl) {

        return control.errors && Object.entries(control.errors)[0][0]
    }
}
