import { formatNumber } from '@angular/common'

enum Round {
    UP = 'UP',
    DOWN = 'DOWN',
    DEFAULT = 'DEFAULT'
}

export class Currency {

    static readonly ADP = new Currency('ADP', 2, 0)
    static readonly AED = new Currency('AED', 2, 2)
    static readonly AFA = new Currency('AFA', 2, 2)
    static readonly AFN = new Currency('AFN', 2, 2)
    static readonly ALL = new Currency('ALL', 2, 2)
    static readonly AMD = new Currency('AMD', 2, 2)
    static readonly ANG = new Currency('ANG', 2, 2)
    static readonly AOA = new Currency('AOA', 2, 2)
    static readonly ARS = new Currency('ARS', 2, 2)
    static readonly ATS = new Currency('ARS', 2, 2)
    static readonly AUD = new Currency('AUD', 2, 2)
    static readonly AWG = new Currency('AWG', 2, 2)
    static readonly AYM = new Currency('AYM', 2, 2)
    static readonly AZM = new Currency('AZM', 2, 2)
    static readonly AZN = new Currency('AZN', 2, 2)
    static readonly BAM = new Currency('BAM', 2, 2)
    static readonly BBD = new Currency('BBD', 2, 2)
    static readonly BDT = new Currency('BDT', 2, 2)
    static readonly BEF = new Currency('BEF', 2, 2)
    static readonly BGL = new Currency('BGL', 0, 0)
    static readonly BGN = new Currency('BGN', 2, 2)
    static readonly BHD = new Currency('BHD', 3, 3)
    static readonly BIF = new Currency('BIF', 2, 0)
    static readonly BMD = new Currency('BMD', 2, 2)
    static readonly BND = new Currency('BND', 2, 2)
    static readonly BOB = new Currency('BOB', 2, 2)
    static readonly BOV = new Currency('BOV', 2, 2)
    static readonly BRL = new Currency('BRL', 2, 2)
    static readonly BSD = new Currency('BSD', 2, 2)
    static readonly BTC = new Currency('BTC', 8, 8)
    static readonly BTN = new Currency('BTN', 2, 2)
    static readonly BWP = new Currency('BWP', 2, 2)
    static readonly BYB = new Currency('BYB', 0, 0)
    static readonly BYN = new Currency('BYN', 2, 2)
    static readonly BYR = new Currency('BYR', 0, 0)
    static readonly BZD = new Currency('BZD', 2, 2)
    static readonly CAD = new Currency('CAD', 2, 2)
    static readonly CDF = new Currency('CDF', 2, 2)
    static readonly CHE = new Currency('CHE', 2, 2)
    static readonly CHF = new Currency('CHF', 2, 2)
    static readonly CHW = new Currency('CHW', 2, 2)
    static readonly CLF = new Currency('CLF', 4, 4)
    static readonly CLP = new Currency('CLP', 0, 0)
    static readonly CNY = new Currency('CNY', 2, 2)
    static readonly COP = new Currency('COP', 2, 2)
    static readonly COU = new Currency('COU', 2, 2)
    static readonly CRC = new Currency('CRC', 2, 2)
    static readonly CSD = new Currency('CSD', 2, 2)
    static readonly CUC = new Currency('CUC', 2, 2)
    static readonly CUP = new Currency('CUP', 2, 2)
    static readonly CVE = new Currency('CVE', 2, 2)
    static readonly CYP = new Currency('CYP', 2, 2)
    static readonly CZK = new Currency('CZK', 2, 2)
    static readonly DEM = new Currency('DEM', 2, 2)
    static readonly DJF = new Currency('DJF', 2, 0)
    static readonly DKK = new Currency('DKK', 2, 2)
    static readonly DOP = new Currency('DOP', 2, 2)
    static readonly DZD = new Currency('DZD', 2, 2)
    static readonly EEK = new Currency('EEK', 2, 2)
    static readonly EGP = new Currency('EGP', 2, 2)
    static readonly ETH = new Currency('ETH', 1, 18)
    static readonly ERN = new Currency('ERN', 2, 2)
    static readonly ESP = new Currency('ESP', 2, 0)
    static readonly ETB = new Currency('ETB', 2, 2)
    static readonly EUR = new Currency('EUR', 2, 2)
    static readonly FIM = new Currency('FIM', 2, 2)
    static readonly FJD = new Currency('FJD', 2, 2)
    static readonly FKP = new Currency('FKP', 2, 2)
    static readonly FRF = new Currency('FRF', 2, 2)
    static readonly GBP = new Currency('GBP', 2, 2)
    static readonly GEL = new Currency('GEL', 2, 2)
    static readonly GHC = new Currency('GHC', 2, 2)
    static readonly GHS = new Currency('GHS', 2, 2)
    static readonly GIP = new Currency('GIP', 2, 2)
    static readonly GMD = new Currency('GMD', 2, 2)
    static readonly GNF = new Currency('GNF', 2, 0)
    static readonly GRD = new Currency('GRD', 0, 0)
    static readonly GTQ = new Currency('GTQ', 2, 2)
    static readonly GWP = new Currency('GWP', 2, 2)
    static readonly GYD = new Currency('GYD', 2, 2)
    static readonly HKD = new Currency('HKD', 2, 2)
    static readonly HNL = new Currency('HNL', 2, 2)
    static readonly HRK = new Currency('HRK', 2, 2)
    static readonly HTG = new Currency('HTG', 2, 2)
    static readonly HUF = new Currency('HUF', 2, 2)
    static readonly IDR = new Currency('IDR', 2, 0)
    static readonly IEP = new Currency('IEP', 2, 2)
    static readonly ILS = new Currency('ILS', 2, 2)
    static readonly INR = new Currency('INR', 2, 2)
    static readonly IQD = new Currency('IQD', 3, 3)
    static readonly IRR = new Currency('IRR', 2, 2)
    static readonly ISK = new Currency('ISK', 2, 0)
    static readonly ITL = new Currency('ITL', 2, 0)
    static readonly JMD = new Currency('JMD', 2, 2)
    static readonly JOD = new Currency('JOD', 2, 2)
    static readonly JPY = new Currency('JPY', 2, 0)
    static readonly KES = new Currency('KES', 2, 2)
    static readonly KGS = new Currency('KGS', 2, 2)
    static readonly KHR = new Currency('KHR', 2, 2)
    static readonly KMF = new Currency('KMF', 2, 0)
    static readonly KPW = new Currency('KPW', 2, 2)
    static readonly KRW = new Currency('KRW', 2, 0)
    static readonly KWD = new Currency('KWD', 3, 3)
    static readonly KYD = new Currency('KYD', 2, 2)
    static readonly KZT = new Currency('KZT', 2, 2)
    static readonly LAK = new Currency('LAK', 2, 2)
    static readonly LBP = new Currency('LBP', 2, 2)
    static readonly LKR = new Currency('LKR', 2, 2)
    static readonly LRD = new Currency('LRD', 2, 2)
    static readonly LSL = new Currency('LSL', 2, 2)
    static readonly LTL = new Currency('LTL', 2, 2)
    static readonly LUF = new Currency('LUF', 2, 0)
    static readonly LVL = new Currency('LVL', 2, 2)
    static readonly LYD = new Currency('LYD', 3, 3)
    static readonly MAD = new Currency('MAD', 2, 2)
    static readonly MDL = new Currency('MDL', 2, 2)
    static readonly MGA = new Currency('MGA', 2, 2)
    static readonly MGF = new Currency('MGF', 2, 0)
    static readonly MKD = new Currency('MKD', 2, 2)
    static readonly MMK = new Currency('MMK', 2, 2)
    static readonly MNT = new Currency('MNT', 2, 2)
    static readonly MOP = new Currency('MOP', 2, 2)
    static readonly MRO = new Currency('MRO', 2, 2)
    static readonly MRU = new Currency('MRU', 2, 2)
    static readonly MTL = new Currency('MTL', 2, 2)
    static readonly MUR = new Currency('MUR', 2, 2)
    static readonly MVR = new Currency('MVR', 2, 2)
    static readonly MWK = new Currency('MWK', 2, 0)
    static readonly MXN = new Currency('MXN', 2, 2)
    static readonly MXV = new Currency('MXV', 2, 2)
    static readonly MYR = new Currency('MYR', 2, 2)
    static readonly MZM = new Currency('MZM', 2, 2)
    static readonly MZN = new Currency('MZN', 2, 2)
    static readonly NAD = new Currency('NAD', 2, 2)
    static readonly NGN = new Currency('NGN', 2, 2)
    static readonly NIO = new Currency('NIO', 2, 2)
    static readonly NLG = new Currency('NLG', 2, 2)
    static readonly NOK = new Currency('NOK', 2, 2)
    static readonly NPR = new Currency('NPR', 2, 2)
    static readonly NZD = new Currency('NZD', 2, 2)
    static readonly OMR = new Currency('OMR', 3, 3)
    static readonly PAB = new Currency('PAB', 2, 2)
    static readonly PEN = new Currency('PEN', 2, 2)
    static readonly PGK = new Currency('PGK', 2, 2)
    static readonly PHP = new Currency('PHP', 2, 2)
    static readonly PKR = new Currency('PKR', 2, 0)
    static readonly PLN = new Currency('PLN', 2, 2)
    static readonly PTE = new Currency('PTE', 2, 0)
    static readonly PYG = new Currency('PYG', 2, 0)
    static readonly QAR = new Currency('QAR', 2, 2)
    static readonly ROL = new Currency('ROL', 2, 2)
    static readonly RON = new Currency('RON', 2, 2)
    static readonly RSD = new Currency('RSD', 2, 2)
    static readonly RUB = new Currency('RUB', 2, 2)
    static readonly RUR = new Currency('RUR', 2, 2)
    static readonly RWF = new Currency('RWF', 2, 0)
    static readonly SAR = new Currency('SAR', 2, 2)
    static readonly SBD = new Currency('SBD', 2, 2)
    static readonly SCR = new Currency('SCR', 2, 2)
    static readonly SDD = new Currency('SDD', 2, 2)
    static readonly SDG = new Currency('SDG', 2, 2)
    static readonly SEK = new Currency('SEK', 2, 2)
    static readonly SGD = new Currency('SGD', 2, 2)
    static readonly SHP = new Currency('SHP', 2, 2)
    static readonly SIT = new Currency('SIT', 2, 2)
    static readonly SKK = new Currency('SKK', 2, 2)
    static readonly SLL = new Currency('SLL', 2, 2)
    static readonly SLE = new Currency('SLE', 2, 2)
    static readonly SOS = new Currency('SOS', 2, 2)
    static readonly SRD = new Currency('SRD', 2, 2)
    static readonly SRG = new Currency('SRG', 2, 2)
    static readonly SSP = new Currency('SSP', 2, 2)
    static readonly STD = new Currency('STD', 2, 2)
    static readonly SVC = new Currency('SVC', 2, 2)
    static readonly SYP = new Currency('SYP', 2, 2)
    static readonly SZL = new Currency('SZL', 2, 2)
    static readonly THB = new Currency('THB', 2, 2)
    static readonly TJS = new Currency('TJS', 2, 2)
    static readonly TMM = new Currency('TMM', 2, 2)
    static readonly TMT = new Currency('TMT', 2, 2)
    static readonly TND = new Currency('TND', 3, 3)
    static readonly TOP = new Currency('TOP', 2, 2)
    static readonly TPE = new Currency('TPE', 0, 0)
    static readonly TRL = new Currency('TRL', 0, 0)
    static readonly TRY = new Currency('TRY', 2, 2)
    static readonly TTD = new Currency('TTD', 2, 2)
    static readonly TWD = new Currency('TWD', 2, 2)
    static readonly TZS = new Currency('TZS', 2, 0)
    static readonly UAH = new Currency('UAH', 2, 2)
    static readonly UGX = new Currency('UGX', 2, 0)
    static readonly USD = new Currency('USD', 2, 2)
    static readonly USN = new Currency('USN', 2, 2)
    static readonly USS = new Currency('USS', 2, 2)
    static readonly UYI = new Currency('UYI', 0, 0)
    static readonly UYU = new Currency('UYU', 2, 2)
    static readonly UZS = new Currency('UZS', 2, 2)
    static readonly VEB = new Currency('VEB', 2, 2)
    static readonly VEF = new Currency('VEF', 2, 2)
    static readonly VND = new Currency('VND', 2, 0)
    static readonly VUV = new Currency('VUV', 2, 0)
    static readonly WST = new Currency('WST', 2, 2)
    static readonly XAF = new Currency('XAF', 2, 0)
    static readonly XCD = new Currency('XCD', 2, 2)
    static readonly XDR = new Currency('XDR', 2, 2)
    static readonly XOF = new Currency('XOF', 2, 0)
    static readonly XPF = new Currency('XPF', 2, 0)
    static readonly XTS = new Currency('XTS', 0, 0)
    static readonly YER = new Currency('YER', 2, 2)
    static readonly YUM = new Currency('YUM', 2, 2)
    static readonly ZAR = new Currency('ZAR', 2, 2)
    static readonly ZMK = new Currency('ZMK', 2, 2)
    static readonly ZMW = new Currency('ZMW', 2, 2)
    static readonly ZWD = new Currency('ZWD', 2, 2)
    static readonly ZWL = new Currency('ZWL', 2, 2)
    static readonly ZWN = new Currency('ZWN', 2, 2)
    static readonly ZWR = new Currency('ZWR', 2, 2)

    private static currencyCodes: Record<string, Currency> = {
        'ADP': Currency.ADP,
        'AED': Currency.AED,
        'AFA': Currency.AFA,
        'AFN': Currency.AFN,
        'ALL': Currency.ALL,
        'AMD': Currency.AMD,
        'ANG': Currency.ANG,
        'AOA': Currency.AOA,
        'ARS': Currency.ARS,
        'ATS': Currency.ATS,
        'AUD': Currency.AUD,
        'AWG': Currency.AWG,
        'AYM': Currency.AYM,
        'AZM': Currency.AZM,
        'AZN': Currency.AZN,
        'BAM': Currency.BAM,
        'BBD': Currency.BBD,
        'BDT': Currency.BDT,
        'BEF': Currency.BEF,
        'BGL': Currency.BGL,
        'BGN': Currency.BGN,
        'BHD': Currency.BHD,
        'BIF': Currency.BIF,
        'BMD': Currency.BMD,
        'BND': Currency.BND,
        'BOB': Currency.BOB,
        'BOV': Currency.BOV,
        'BRL': Currency.BRL,
        'BSD': Currency.BSD,
        'BTC': Currency.BTC,
        'BTN': Currency.BTN,
        'BWP': Currency.BWP,
        'BYB': Currency.BYB,
        'BYN': Currency.BYN,
        'BYR': Currency.BYR,
        'BZD': Currency.BZD,
        'CAD': Currency.CAD,
        'CDF': Currency.CDF,
        'CHE': Currency.CHE,
        'CHF': Currency.CHF,
        'CHW': Currency.CHW,
        'CLF': Currency.CLF,
        'CLP': Currency.CLP,
        'CNY': Currency.CNY,
        'COP': Currency.COP,
        'COU': Currency.COU,
        'CRC': Currency.CRC,
        'CSD': Currency.CSD,
        'CUC': Currency.CUC,
        'CUP': Currency.CUP,
        'CVE': Currency.CVE,
        'CYP': Currency.CYP,
        'CZK': Currency.CZK,
        'DEM': Currency.DEM,
        'DJF': Currency.DJF,
        'DKK': Currency.DKK,
        'DOP': Currency.DOP,
        'DZD': Currency.DZD,
        'EEK': Currency.EEK,
        'EGP': Currency.EGP,
        'ETH': Currency.ETH,
        'ERN': Currency.ERN,
        'ESP': Currency.ESP,
        'ETB': Currency.ETB,
        'EUR': Currency.EUR,
        'FIM': Currency.FIM,
        'FJD': Currency.FJD,
        'FKP': Currency.FKP,
        'FRF': Currency.FRF,
        'GBP': Currency.GBP,
        'GEL': Currency.GEL,
        'GHC': Currency.GHC,
        'GHS': Currency.GHS,
        'GIP': Currency.GIP,
        'GMD': Currency.GMD,
        'GNF': Currency.GNF,
        'GRD': Currency.GRD,
        'GTQ': Currency.GTQ,
        'GWP': Currency.GWP,
        'GYD': Currency.GYD,
        'HKD': Currency.HKD,
        'HNL': Currency.HNL,
        'HRK': Currency.HRK,
        'HTG': Currency.HTG,
        'HUF': Currency.HUF,
        'IDR': Currency.IDR,
        'IEP': Currency.IEP,
        'ILS': Currency.ILS,
        'INR': Currency.INR,
        'IQD': Currency.IQD,
        'IRR': Currency.IRR,
        'ISK': Currency.ISK,
        'ITL': Currency.ITL,
        'JMD': Currency.JMD,
        'JOD': Currency.JOD,
        'JPY': Currency.JPY,
        'KES': Currency.KES,
        'KGS': Currency.KGS,
        'KHR': Currency.KHR,
        'KMF': Currency.KMF,
        'KPW': Currency.KPW,
        'KRW': Currency.KRW,
        'KWD': Currency.KWD,
        'KYD': Currency.KYD,
        'KZT': Currency.KZT,
        'LAK': Currency.LAK,
        'LBP': Currency.LBP,
        'LKR': Currency.LKR,
        'LRD': Currency.LRD,
        'LSL': Currency.LSL,
        'LTL': Currency.LTL,
        'LUF': Currency.LUF,
        'LVL': Currency.LVL,
        'LYD': Currency.LYD,
        'MAD': Currency.MAD,
        'MDL': Currency.MDL,
        'MGA': Currency.MGA,
        'MGF': Currency.MGF,
        'MKD': Currency.MKD,
        'MMK': Currency.MMK,
        'MNT': Currency.MNT,
        'MOP': Currency.MOP,
        'MRO': Currency.MRO,
        'MRU': Currency.MRU,
        'MTL': Currency.MTL,
        'MUR': Currency.MUR,
        'MVR': Currency.MVR,
        'MWK': Currency.MWK,
        'MXN': Currency.MXN,
        'MXV': Currency.MXV,
        'MYR': Currency.MYR,
        'MZM': Currency.MZM,
        'MZN': Currency.MZN,
        'NAD': Currency.NAD,
        'NGN': Currency.NGN,
        'NIO': Currency.NIO,
        'NLG': Currency.NLG,
        'NOK': Currency.NOK,
        'NPR': Currency.NPR,
        'NZD': Currency.NZD,
        'OMR': Currency.OMR,
        'PAB': Currency.PAB,
        'PEN': Currency.PEN,
        'PGK': Currency.PGK,
        'PHP': Currency.PHP,
        'PKR': Currency.PKR,
        'PLN': Currency.PLN,
        'PTE': Currency.PTE,
        'PYG': Currency.PYG,
        'QAR': Currency.QAR,
        'ROL': Currency.ROL,
        'RON': Currency.RON,
        'RSD': Currency.RSD,
        'RUB': Currency.RUB,
        'RUR': Currency.RUR,
        'RWF': Currency.RWF,
        'SAR': Currency.SAR,
        'SBD': Currency.SBD,
        'SCR': Currency.SCR,
        'SDD': Currency.SDD,
        'SDG': Currency.SDG,
        'SEK': Currency.SEK,
        'SGD': Currency.SGD,
        'SHP': Currency.SHP,
        'SIT': Currency.SIT,
        'SKK': Currency.SKK,
        'SLL': Currency.SLL,
        'SLE': Currency.SLE,
        'SOS': Currency.SOS,
        'SRD': Currency.SRD,
        'SRG': Currency.SRG,
        'SSP': Currency.SSP,
        'STD': Currency.STD,
        'SVC': Currency.SVC,
        'SYP': Currency.SYP,
        'SZL': Currency.SZL,
        'THB': Currency.THB,
        'TJS': Currency.TJS,
        'TMM': Currency.TMM,
        'TMT': Currency.TMT,
        'TND': Currency.TND,
        'TOP': Currency.TOP,
        'TPE': Currency.TPE,
        'TRL': Currency.TRL,
        'TRY': Currency.TRY,
        'TTD': Currency.TTD,
        'TWD': Currency.TWD,
        'TZS': Currency.TZS,
        'UAH': Currency.UAH,
        'UGX': Currency.UGX,
        'USD': Currency.USD,
        'USN': Currency.USN,
        'USS': Currency.USS,
        'UYI': Currency.UYI,
        'UYU': Currency.UYU,
        'UZS': Currency.UZS,
        'VEB': Currency.VEB,
        'VEF': Currency.VEF,
        'VND': Currency.VND,
        'VUV': Currency.VUV,
        'WST': Currency.WST,
        'XAF': Currency.XAF,
        'XCD': Currency.XCD,
        'XDR': Currency.XDR,
        'XOF': Currency.XOF,
        'XPF': Currency.XPF,
        'XTS': Currency.XTS,
        'YER': Currency.YER,
        'YUM': Currency.YUM,
        'ZAR': Currency.ZAR,
        'ZMK': Currency.ZMK,
        'ZMW': Currency.ZMW,
        'ZWD': Currency.ZWD,
        'ZWL': Currency.ZWL,
        'ZWN': Currency.ZWN,
        'ZWR': Currency.ZWR
    }

    readonly code: string
    readonly exponent: number
    readonly precision: number
    readonly rounding: Round = Round.DEFAULT

    constructor(code: string, exponent: number, precision: number, rounding?: Round) {

        this.code = code
        this.exponent = exponent
        this.precision = precision
        
        if (rounding) {
            this.rounding = rounding
        }
    }

    static of(code: string): Currency {

        const match = code.match(/^([A-Z]{3})(?:\{(.*)})?$/)

        if (match) {

            const currenyCode = match[1]
            const properties = match[2]

            const currency = this.currencyCodes[currenyCode]

            let exponent!: number
            let precision!: number
            let rounding!: Round

            if (properties) {

                properties.split(',').forEach((property: string) => {

                    const keyVal = property.split(':', 2)
                    const key = keyVal[0]
                    const val = keyVal[1]

                    switch (key.trim()) {
						case 'exponent':
							exponent = +val.trim()
							break
						case 'precision':
							precision = +val.trim()
							break
						case 'rounding':
							rounding = val.trim() as Round
							break
						default:
							console.warn(`unknown currency properties: ${key}: ${val}`)
					}
                })

                if (!exponent) {
                    exponent = currency.exponent
                }
    
                if (!precision) {
                    precision = currency.precision
                }
                
                if (!rounding) {
                    rounding = currency.rounding
                }

                return new Currency(currency.code, exponent, precision, rounding)
            }

            if (currency) { return currency }
            throw Error(`unknown currency code '${currenyCode}'`)
        }

        throw Error(`unknown currency code '${code}'`)
    }

    toAmountMinor(amount: number): number {

        // if the exponent is zero do not apply rounding
        if (this.exponent === 0) { return amount }

        switch (this.rounding) {
            case Round.UP:
                return Math.ceil(amount * Math.pow(10, this.exponent))
            case Round.DOWN:
                return Math.floor(amount * Math.pow(10, this.exponent))
        }

        return Math.round(amount * Math.pow(10, this.exponent))
    }

    toAmountMajor(amount: number): number {

		let pInt = this.precision > 0 ? Math.floor(this.precision) : Math.ceil(this.precision)
		const p2 =  Math.round(Math.abs((this.precision - pInt) * 10))

        pInt = Math.ceil(this.precision)

        const pExp = Math.pow(10, pInt - this.exponent)

        let rdMode
		switch (this.rounding) {
			case Round.UP:
				rdMode = Math.ceil
				break
			case Round.DOWN:
				rdMode = Math.floor
				break
			default:
				rdMode = Math.round
		}

        let a2 = rdMode(amount * pExp)

		if (p2 > 0) {
			a2 = rdMode(a2 / p2) * p2
		}

		return (a2 / pExp) / Math.pow(10, this.exponent)
    }

    format(amount: number, precision?: number): string {

        precision = Math.ceil(precision ? precision : this.precision)

        if (precision > 0) {
            return formatNumber(this.toAmountMajor(amount), 'en-US', `1.${precision}-${precision}`)
        }

        return formatNumber(this.toAmountMajor(amount), 'en-US')
    }

    toString(): string {

        return this.code;
    }
}
