
import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms'


import { combineLatest } from 'rxjs'
import { IMoneyOperator } from '../../../../../data/types'
import { MoneyOperatorComponent } from '../money-operator-component'

@Component({
  selector: 'account_num_national_id',
  templateUrl: './account-num-national-id.component.html',
  styleUrls: ['./account-num-national-id.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class AccountNumNationalIdComponent implements MoneyOperatorComponent, OnInit {


  accountNumCtrl = new FormControl(null, [Validators.required, Validators.pattern(/^\d{2,16}$/)])
  nationalIdCtrl = new FormControl(null, [Validators.required, Validators.pattern(/^\d{8,16}$/)])

  bankName: string
  branchName: string

  private moneyOperator: IMoneyOperator

  private onModelChange = (val) => { }
  private onValidatorChange = () => { }


  constructor(
    private http: HttpClient
  ) {

    combineLatest([this.accountNumCtrl.valueChanges, this.nationalIdCtrl.valueChanges])
      .subscribe(([accountNum, nationalId]: [string, string]) => {
        this.onModelChange({ accountNum: accountNum, nationalId: nationalId })
      })
  }


  setMoneyOperator(moneyOperator: IMoneyOperator): void {
    this.moneyOperator = moneyOperator
  }

  setDisabledState?(isDisabled: boolean): void { }

  ngOnInit(): void {

  }

  focus(): void {

    if (this.accountNumCtrl.invalid) {
      (this.accountNumCtrl as any).focus()
    }
  }

  writeValue(accountDetails: any): void {
    if (accountDetails) {
      this.accountNumCtrl.setValue(accountDetails.accountNum)
      this.nationalIdCtrl.setValue(accountDetails.nationalId)
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn
  }

  registerOnTouched(fn: any): void {
  }

  validate(control: AbstractControl): ValidationErrors {


    if (this.accountNumCtrl.invalid) {
      return this.accountNumCtrl.errors
    }
    return this.nationalIdCtrl.errors
  }

  registerOnValidatorChange?(fn: () => void): void {

    this.onValidatorChange = fn
  }

  error(formControl: FormControl) {

    return formControl.errors && Object.entries(formControl.errors)[0][0];
  }
}

