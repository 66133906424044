
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http' 
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'

import { AuthHandler, JwtAuthHandler } from './auth.handler';
import { AuthInterceptor } from './auth.interceptor';
import { CredentialsProvider, MatDialogLoginFormCredentialsProvider } from './credentials.provider';
import { AxLoginComponent } from './login/login.component';
import { AxLoginRouteComponent } from './login-route/login-route.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [ 
    AxLoginComponent, 
    AxLoginRouteComponent 
  ],
  exports: [ AxLoginRouteComponent ],
  providers: [ 
    AuthInterceptor,
    { provide: HTTP_INTERCEPTORS, useExisting: AuthInterceptor, multi: true },
    { provide: AuthHandler, useClass: JwtAuthHandler },
    { provide: CredentialsProvider, useClass: MatDialogLoginFormCredentialsProvider }
  ]
})
export class AxAuthModule { }
