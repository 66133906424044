<div mat-dialog-content>
    <div class="mod-des">
        As this is the first time you are loading using POLi, funds are subject to physical funds clearance.
    </div>
    <div class="mod-info">
        This means it may take 1 or 2 business days for funds to appear in your Rocket Remit account. Once your account is established, POLi transfers will be instantaneous.
    </div>
</div>
<div mat-dialog-actions>
    <button class="btn btn-primary" mat-dialog-close>Continue</button>
</div>