<div class="row">
    <div class="form-group">
        <mat-form-field>
            <mat-label>national id</mat-label>
            <input matInput [formControl]="nationalIdCtrl" type="text" inputmode="numeric">
            <div *ngIf="nationalIdCtrl.errors" class="alert-block">
                <span class="ico"></span>
                <div [ngSwitch]="error(nationalIdCtrl)" class="help-tooltip">
                    <span *ngSwitchCase="'required'" class="txt">national id required</span>
                    <span *ngSwitchDefault class="txt">national id is invalid</span>
                    <span class="rect"></span>
                </div>
            </div> 
        </mat-form-field>
    </div>
</div>