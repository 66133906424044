import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { throwError, Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'


import {
    ICorridorInfo,
    IRemitAdvice,
    ServerError
} from '../types'

import { DASHBOARD_CONFIG } from '../../dashboard.config.token'
import { DashboardConfig } from '../../dashboard.config'
// import { CordovaService } from '../cordova.service'

@Injectable({
    providedIn: 'root'
})
export class RemitService {

    constructor(
        private http: HttpClient,
        @Inject(DASHBOARD_CONFIG) private config: DashboardConfig
    ) { }

    getCorridorInfo(sourceCountry: string, destinationCountry: string): Observable<ICorridorInfo> {

        return this.http
            .get<ICorridorInfo>(`${this.config.serverUrl}/v3/remit/corridor/${sourceCountry}/${destinationCountry}`)
            .pipe(
                catchError((resp: any) => throwError(() => new ServerError(resp.error))),
            )
      } 

    remitAdvice(remitAdvice: IRemitAdvice): Observable<any> {

        return this.http
            .post(`${this.config.serverUrl}/v3/remit/advice`, remitAdvice, { 
                headers: {
                    'RR-User-Agent': this.config.userAgent
                }
            })
            .pipe(
                catchError((resp: any) => throwError(() => new ServerError(resp.error)))
            )
    }
}
