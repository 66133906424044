import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AxCurrencyModule } from '@assentinel/forms';

import { CorridorModule } from '@rr/dashboard';

import { RateCalculatorComponent } from './components/rate-calculator/rate-calculator.component';


@NgModule({
    declarations: [
        RateCalculatorComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AxCurrencyModule,
        CorridorModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
    ],
    providers: []
})
export class RateCalculatorModule {

    constructor(injector: Injector) {

        const rateCalcElement = createCustomElement(RateCalculatorComponent, { injector })
        customElements.define('rate-calculator', rateCalcElement)
    }
}
