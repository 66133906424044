import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

import { AuthenticationService } from '../../data/services/authentication.service';
import { AuthService } from '@assentinel/auth';

@Component({
  templateUrl: './menu-auth.component.html',
  styleUrls: ['./menu-auth.component.scss']
})
export class MenuAuthComponent implements OnInit {

    authenticated = false

    constructor(
        private elementRef: ElementRef,
        private render: Renderer2,
        private axAuthService: AuthService,
        private authService: AuthenticationService) {}

    ngOnInit(): void {
    
        this.axAuthService.isAuthenticated()
            .subscribe((authenticated: boolean) => {

                this.authenticated = authenticated

                if (authenticated) {

                    this.render.removeChild(this.elementRef.nativeElement, this.elementRef.nativeElement.firstElementChild)
        
                } else if (this.authService.isRegistered()) {
        
                    const login = this.elementRef.nativeElement.querySelector('[login]')
                    this.render.addClass(login, 'highlight')
        
                } else {
                    const register = this.elementRef.nativeElement.querySelector('[register]')
                    this.render.addClass(register, 'highlight')
                }
            })
    }

    logout() {

        this.axAuthService
            .logout()
            .subscribe(
                (window as any).location.reload()
            );
    }
}
