import { Component, OnInit, ViewEncapsulation, ViewChild, Output, EventEmitter, 
    ViewContainerRef,
    ɵcreateInjector as createInjector, 
    inject, Injector, NgZone } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray, FormGroupDirective, ValidationErrors, ValidatorFn } from '@angular/forms'

import { Observable, combineLatest, Subscription, BehaviorSubject } from 'rxjs'
import { map, startWith, filter, distinctUntilChanged, take } from 'rxjs/operators'

import { Platform } from '@angular/cdk/platform'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatDialog } from '@angular/material/dialog'

import { AsYouTypeFormatter, PhoneNumberUtil } from 'google-libphonenumber'

import { AuthService } from '@assentinel/auth'
import { Currency } from '@assentinel/forms'

import { AnalyticsService } from '@rr/shared'

import { CustomerService } from '../../../../data/service/customer.service'
import { RemitService } from '../../../../data/service/remit.service'

import { 
    IFavourite, 
    ICorridorInfo, 
    IBalance, 
    IMoneyOperator, 
    IRemitAdvice,
    ServerError
} from '../../../../data/types'

import { RemitCompleteComponent } from '../remit-complete/remit-complete.component'
import { MoneyOperatorComponent } from '../money-operator/money-operator-component'

import { MoneyOperatorModule } from '../money-operator/money-operator.module'




async function loadMoneyOperator() {
    await import('../money-operator/money-operator.module')   
}

const nameValidator = (control: AbstractControl): { [key: string]: any } | null => {

    const name = control.value
    if (!name) {
        return { required: true }
    }

    if (/[^\x00-\x7f]/.test(name)) {
        return { 'diacritic': { value: name } }
    }

    if (/[^a-zA-Z \-']/.test(name)) {
        return { 'invalid-char': { value: name } }
    }
}

function addressValidator(country: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        const address = control.value
        if (!address) {
            return { required: true }
        }

        // strip all non alpha characters from address
        const alphaAddress = address.replace(/[^a-zA-Z]/g, '')
        // test for post box combinations in address
        if (/pobox/ig.test(alphaAddress) || /postbox/ig.test(alphaAddress) || /postoffice/ig.test(alphaAddress) || /officebox/ig.test(alphaAddress)) {
            return { 'pobox': { value: address } }
        }

        // strip all non alpha characters from address
        const alphaAddress2 = address.replace('0', 'o').replace(/[^a-zA-Z ]/g, '').replace(/ +/, ' ')
        if (/pobox/ig.test(alphaAddress2) ||
            /^po /ig.test(alphaAddress2) || /^p o /ig.test(alphaAddress2) || /^box /ig.test(alphaAddress2) ||
            / po /ig.test(alphaAddress2) || / p o /ig.test(alphaAddress2) || / box /ig.test(alphaAddress2)) {
            return { 'pobox': { value: address } }
        }

        if (/[^\x00-\x7f]/.test(address)) {
            return { 'diacritic': { value: address } }
        }

        let filteredAddress = address
        if (country) {
            filteredAddress = address.toUpperCase().replace(country.toUpperCase(), '')
        }
        let words = filteredAddress.split(' ')
        // strip out all short words
        words = words.filter((word: String) => !!word && word.length > 1)

        return words.length < 2 ? { 'insufficent': { value: address } } : null
    }
}

function cityTownValidator(country: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        const cityTown = control.value
        if (!cityTown) {
            return { required: true }
        }

        if (/[^\x00-\x7f]/.test(cityTown)) {
            return { 'diacritic': { value: cityTown } }
        }

        let filteredAddress = cityTown
        if (country) {
            filteredAddress = cityTown.toUpperCase().replace(country.toUpperCase(), '')
        }
        let words = filteredAddress.split(' ')
        // strip out all short words
        words = words.filter((word: String) => !!word && word.length > 1)

        return words.length < 1 ? { 'insufficent': { value: cityTown } } : null
    }
}

@Component({
    selector: 'rr-remit-mobile-wallet',
    templateUrl: './mobile-wallet.component.html',
    // template: require('./mobile-wallet.component.html'),

    // styles: [ require('./mobile-wallet.component.scss').toString() ],
    styleUrls: [ './mobile-wallet.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class MobileWalletComponent implements OnInit {

    analyticsService = inject(AnalyticsService)

    @Output()
    completeRegistration = new EventEmitter()

    balance: IBalance;

    remitFormGroup: FormGroup;
    remitSubscription: Subscription;

    filteredBeneficiaries: Observable<IFavourite[]>;
    private selectedBeneficiary = new BehaviorSubject<IFavourite>(null)

    corridorInfo: ICorridorInfo;

    @ViewChild(FormGroupDirective, { static: false })
    remitForm: FormGroupDirective;

    @ViewChild('moneyOperatorComponent', { read: ViewContainerRef })
    moneyOperatorRef: ViewContainerRef

    private asYouTypeFormatter = new AsYouTypeFormatter(null);
    private phoneUtil = PhoneNumberUtil.getInstance();

    moneyOperatorCtrl = new FormControl()
    beneficiaryCountry: string | null = null
    sendAmountCtrl = new FormControl({ value: null, disabled: true })
    beneficiaryAmountCtrl = new FormControl({ value: null, disabled: true })

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private authService: AuthService,
        private customerService: CustomerService,
        private remitService: RemitService,
        private injector: Injector,
        private platform: Platform,
        private ngZone: NgZone) {}

    ngOnInit() {

        // setup the form group for sending to beneficiary
        this.remitFormGroup = this.fb.group({
            moneyOperator: this.moneyOperatorCtrl,
            beneficiary: this.fb.group({
                msisdn: [ null ],
                firstName: [ null, nameValidator ],
                surname: [ null, nameValidator ],
                address: [ null ],
                cityTownSuburb: [ null ],
                countryCode: null,
                accountDetails: [ null ]
            }),
            sendAmount: this.sendAmountCtrl,
            beneficiaryAmount: this.beneficiaryAmountCtrl
        });


        this.authService.user.subscribe(user => {
            this.registrationStatus = user.registrationStatus
            this.suspended = user.suspended

            if (!this.canRemit) {
                this.remitFormGroup.disable();
            }
        })

        this.customerService
            .getBalance()
            .subscribe((balance: IBalance) => this.balance = balance)
        
        // create observable that watches the beneficiary msisdn and filters the favorites
        this.filteredBeneficiaries = combineLatest([
            this.remitFormGroup
                .get('beneficiary')
                .get('msisdn')
                .valueChanges
                .pipe(
                    startWith('')
                ),
            this.customerService
                .getFavorites()
                .pipe(
                    startWith([])
                )])
            .pipe(
                map(([ beneficiary, favorites ]: [string, IFavourite[]]): IFavourite[] => {

                    return beneficiary ?
                        favorites.filter(favorite => favorite.msisdn.indexOf(beneficiary) === 0) :
                        favorites
                })
            );

        // update the beneficiary group when the selectedBeneficiary changes
        this.selectedBeneficiary.subscribe((favourite: IFavourite) => {
            
            const beneficiaryFormGroup = this.remitFormGroup.get('beneficiary')

            this.corridorInfo = null
            this.moneyOperatorCtrl.clearValidators()
            this.moneyOperatorCtrl.setValue(null)

            if (favourite) {    

                beneficiaryFormGroup.patchValue({
                    msisdn: favourite.msisdn,
                    firstName: favourite.firstName,
                    surname: favourite.surname,
                    address: favourite.address,
                    cityTownSuburb: favourite.cityTownSuburb,
                    countryCode: favourite.countryCode,
                    accountDetails: favourite.accountDetails
                })
            
                beneficiaryFormGroup.markAllAsTouched()

            } else {

                beneficiaryFormGroup.patchValue({
                    firstName: null,
                    surname: null,
                    address: null,
                    cityTownSuburb: null,
                    countryCode: null,
                    accountDetails: null
                })

                this.beneficiaryCountry = null

                this.getControl('beneficiary', 'address').setValidators([ Validators.required ])
                this.getControl('beneficiary', 'cityTownSuburb').setValidators([ Validators.required ])
                this.sendAmountCtrl.disable()
                this.beneficiaryAmountCtrl.disable()
                this.beneficiaryAmountCtrl.setValue(null, { emitEvent: false })
            }
        })

        this.moneyOperatorCtrl
            .valueChanges
            .subscribe((mo: IMoneyOperator) => this.changedMoneyOperator(mo))

        // subscribe to the beneficiary state
        // when valid then get corridor info (fees, limits and fxRate)
        // when invalid clear corridor info
        this.remitFormGroup
            .get('beneficiary')
            .get('msisdn')
            .statusChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe((status) => {
                if (status === 'VALID') {
                    this.getCorridorInfo()
                } else {
                    this.selectedBeneficiary.next(null)
                }
            })

        // subscribe to the send amount and set receive amount based on fxRate
        this.sendAmountCtrl
            .valueChanges
            .pipe(
                // filter((amount) => amount),                                     // filter out zero of null amounts
                filter(() => this.corridorInfo && !!this.corridorInfo.fxRate)   // filter if no fxRate defined
            )
            .subscribe(sendAmount => {

                const beneficiaryAmount = (sendAmount - this.corridorInfo.fee) * this.corridorInfo.fxRate

                if (beneficiaryAmount > 0) {
                    this.beneficiaryAmountCtrl.setValue(beneficiaryAmount, { emitEvent: false })
                } else {
                    this.beneficiaryAmountCtrl.setValue(null, { emitEvent: false })
                }
            })

        // subscribe to the receive amount and set send amount based on fxRate
        this.beneficiaryAmountCtrl
            .valueChanges
            .pipe(
                // filter((amount) => amount),                                  // filter out zero or null amounts
                filter(() => this.corridorInfo && !!this.corridorInfo.fxRate)   // filter if no fxRate defined
            )
            .subscribe(beneficiaryAmount => {

                if (beneficiaryAmount) {

                    const sendAmount = (beneficiaryAmount / this.corridorInfo.fxRate) + this.corridorInfo.fee

                    this.sendAmountCtrl.setValue(sendAmount, { emitEvent: false })

                } else {

                    this.sendAmountCtrl.setValue(null)
                }
            });


    }

    registrationStatus!: string
    suspended = true

    get verified(): boolean {
        return this.registrationStatus === 'complete'
    }

    get verificationPending(): boolean {
        return this.registrationStatus === 'verificationPending'
    }

    get canRemit(): boolean {
        return !this.suspended && this.verified
    }

    /**
     * get the display MSISDN and format for the favorites autocomplete input
     */
    get displayMsisdn() {

        return (beneficiary) => {

            let beneficiaryMsisdn = '';

            if (typeof beneficiary === 'string') {
                beneficiaryMsisdn = beneficiary;
            } else if (beneficiary && beneficiary.msisdn) {
                beneficiaryMsisdn = beneficiary.msisdn;
            }

            let formattedMsisdn = '';
            this.asYouTypeFormatter.clear();

            beneficiaryMsisdn.split('').forEach((c, i) => formattedMsisdn = this.asYouTypeFormatter.inputDigit(c));

            return formattedMsisdn;
        };
    }

    /**
     * callback when beneficiary favorite has been selected.
     *
     * @param event autocomplete selection event
     */
    beneficiarySelected(event: MatAutocompleteSelectedEvent) {

        const selectedBeneficiary = event.option.value

        if (selectedBeneficiary) {

            this.selectedBeneficiary.next(selectedBeneficiary)
        }
    }

    deleteFav(favId: number) {

        // remove the selected beneficiary from the list of favorites
        this.customerService.deleteFavorite(favId);

        const selectedBeneficiary = this.selectedBeneficiary.getValue()
        if (selectedBeneficiary && selectedBeneficiary.id === favId) {
            this.selectedBeneficiary.next(null)
        }
    }

    getRegionCodeForNumber(msisdn: string): string {

        const msisdnObj = this.phoneUtil.parse(msisdn)
        return this.phoneUtil.getRegionCodeForNumber(msisdnObj)
    }

    get beneficiaryPrefixIcon(): string {
        
        const beneficiaryMsisdnCtrl = this.getControl('beneficiary', 'msisdn')

        if (beneficiaryMsisdnCtrl.valid && beneficiaryMsisdnCtrl.value) {
            return this.getRegionCodeForNumber(beneficiaryMsisdnCtrl.value)
        } else {
            return 'XX'
        }
    }

    get collectMoneyOperator(): boolean {

        return this.corridorInfo && this.corridorInfo.moneyOperators && this.corridorInfo.moneyOperators.length > 1
    }

    get moneyOperatorName(): string | null {
    
        const moneyOperator = this.moneyOperatorCtrl.value

        return moneyOperator ? moneyOperator.name : null
    }

    get moneyOperatorTiers(): number[] | null {

        const moneyOperator = this.moneyOperatorCtrl.value as IMoneyOperator

        if (moneyOperator?.beneficiaryTiers) {

            const minBeneficiaryAmount = Math.max(
                this.corridorInfo.receiveLimit ? this.corridorInfo.receiveLimit.min : 0,
                (this.corridorInfo.sendLimit.min - this.corridorInfo.fee) * this.corridorInfo.fxRate)

            const maxBeneficiaryAmount = Math.min(
                this.corridorInfo.receiveLimit ? this.corridorInfo.receiveLimit.max : Number.MAX_SAFE_INTEGER,
                this.corridorInfo.sendLimit.max ? (this.corridorInfo.sendLimit.max - this.corridorInfo.fee) * this.corridorInfo.fxRate : Number.MAX_SAFE_INTEGER)

            return moneyOperator.beneficiaryTiers.filter((tier: number) => tier >= minBeneficiaryAmount && tier <= maxBeneficiaryAmount)
        }

        return null
    }

    getCorridorInfo() {

        const beneficiaryMsisdnCtrl = this.getControl('beneficiary', 'msisdn')

        if (beneficiaryMsisdnCtrl.valid) {

            try {

                const beneficiaryCountryCode = this.getRegionCodeForNumber(beneficiaryMsisdnCtrl.value)

                const beneficiaryCountryCtrl = this.getControl('beneficiary', 'countryCode')

                if (!beneficiaryCountryCtrl.value) {
                    beneficiaryCountryCtrl.setValue(beneficiaryCountryCode)
                }

                this.remitService
                    .getCorridorInfo('AU', beneficiaryCountryCode)
                    .subscribe({
                        next: (corridorInfo: ICorridorInfo) => {

                            this.corridorInfo = corridorInfo;

                            let moneyOperator = null

                            // if there is only one money operator
                            if (this.corridorInfo.moneyOperators.length === 1) {

                                moneyOperator = this.corridorInfo.moneyOperators[0]

                            } else if (this.corridorInfo.moneyOperators.length > 1) {

                                const selectedBeneficiary = this.selectedBeneficiary.getValue()

                                if (selectedBeneficiary && selectedBeneficiary.moneyOperator) {

                                    moneyOperator = this.corridorInfo.moneyOperators.find((moneyOperator: IMoneyOperator) => selectedBeneficiary.moneyOperator.id === moneyOperator.id)
                                }
                            }

                            this.moneyOperatorCtrl.setValue(moneyOperator)

                            if (this.collectMoneyOperator) {

                                this.moneyOperatorCtrl.setValidators([Validators.required])

                            } else {

                                this.sendAmountCtrl.enable();
                                this.beneficiaryAmountCtrl.enable();
                            }

                            this.beneficiaryCountry = corridorInfo.receiveCountry

                            // add validators for the beneficiary address
                            const beneficiaryAddressCtrl = this.getControl('beneficiary', 'address')
                            beneficiaryAddressCtrl.setValidators([addressValidator(corridorInfo.receiveCountry)])

                            const beneficiaryCityTownCtrl = this.getControl('beneficiary', 'cityTownSuburb')
                            beneficiaryCityTownCtrl.setValidators([cityTownValidator(corridorInfo.receiveCountry)])

                            // if the beneficiary address has been validated then 
                            if (beneficiaryAddressCtrl.value) {
                                beneficiaryAddressCtrl.updateValueAndValidity()
                            }

                            this.sendAmountCtrl.setValidators([
                                Validators.required,
                                Validators.min(this.corridorInfo.sendLimit.min),
                                Validators.max(this.corridorInfo.sendLimit.max),
                                // perform available balance validation
                                (control: AbstractControl): ValidationErrors => {

                                    if (control.value == null || this.balance == null) {
                                        return null;
                                    }

                                    return !isNaN(control.value) && control.value > this.balance.available ? {
                                        'insufficient': {
                                            'availableBalance': this.balance.available,
                                            'actual': control.value
                                        }
                                    } : null;
                                }
                            ]);
                            // if there is a receiver limit then set validators on receive amount control
                            if (this.corridorInfo.receiveLimit) {

                                this.beneficiaryAmountCtrl.setValidators([
                                    Validators.required,
                                    Validators.min(this.corridorInfo.receiveLimit.min),
                                    Validators.max(this.corridorInfo.receiveLimit.max)
                                ]);
                            } else {
                                this.beneficiaryAmountCtrl.setValidators([Validators.required]);
                            }
                        },
                        error: (error) => {
                            console.log('error handler: ', error.code, error);
                            beneficiaryMsisdnCtrl.setErrors({ unsupported: true });
                        }
                    });

            } catch (e) {
                // there was an exception parsing the MSISDN
                beneficiaryMsisdnCtrl.setErrors({ unsupported: true });
            }
        }
    }

    private changedMoneyOperator(moneyOperator: IMoneyOperator) {

        if (moneyOperator) {

            this.beneficiaryAmountCtrl.enable()

            if (moneyOperator.beneficiaryTiers) {

                this.sendAmountCtrl.disable()

                const beneficiaryAmount = this.beneficiaryAmountCtrl.value

                if (beneficiaryAmount) {

                    const tierIdx = moneyOperator.beneficiaryTiers.findIndex((amount: number) => amount > beneficiaryAmount)
                    if (tierIdx === 0) {
                        this.sendAmountCtrl.setValue(null)
                        this.beneficiaryAmountCtrl.setValue(null)
                    } else if (tierIdx === -1) {
                        this.beneficiaryAmountCtrl.setValue(moneyOperator.beneficiaryTiers[moneyOperator.beneficiaryTiers.length - 1])
                    } else {
                        this.beneficiaryAmountCtrl.setValue(moneyOperator.beneficiaryTiers[tierIdx - 1])
                    }
                }
            
            } else {

                this.sendAmountCtrl.enable()
            }
        
        } else if (this.collectMoneyOperator) {

            this.sendAmountCtrl.disable()
            this.beneficiaryAmountCtrl.disable()
        } 

        this.updateMoneyOperatorComponent(moneyOperator)
    }

    private async updateMoneyOperatorComponent(moneyOperator: IMoneyOperator) {

        this.moneyOperatorRef.clear()

        const accountDetailsCtrl = this.getControl('beneficiary', 'accountDetails')

        if (moneyOperator && moneyOperator.component) {

            // lazy-loading is not designed to work at library level. see: https://github.com/ng-packagr/ng-packagr/issues/1562
            await import('../money-operator/money-operator.module')

            // import('../money-operator/money-operator.module').then(({ MoneyOperatorModule }) => {

                const injector = createInjector(MoneyOperatorModule, this.injector)
                const moneyOperatorModule = injector.get(MoneyOperatorModule)

                const componentFactory = moneyOperatorModule.resolveMoneyOperatorComponentFactory(moneyOperator.component)

                if (componentFactory) {

                    const moneyOperatorComponentRef = this.moneyOperatorRef.createComponent<MoneyOperatorComponent>(componentFactory)

                    moneyOperatorComponentRef.instance.setMoneyOperator(moneyOperator)
                    moneyOperatorComponentRef.instance.writeValue(accountDetailsCtrl.value);

                    (accountDetailsCtrl as any).focus = () => moneyOperatorComponentRef.instance.focus()
                    moneyOperatorComponentRef.instance.registerOnChange((value: any) => { accountDetailsCtrl.setValue(value) })
                    moneyOperatorComponentRef.instance.registerOnValidatorChange(() => accountDetailsCtrl.updateValueAndValidity())
                    accountDetailsCtrl.setValidators((control: AbstractControl) => (moneyOperatorComponentRef.instance as any).validate(control))

                    accountDetailsCtrl.updateValueAndValidity()
                }
            // })
        
        } else {

            (accountDetailsCtrl as any).focus = () => {}
            accountDetailsCtrl.clearValidators()
            accountDetailsCtrl.setValue(null)
        }
    }

    /**
     * calculate the fxRate from the corridor info.
     * The fxRate returned in the corridor info is relative to the send and receive currency exponents.
     * This needs to be converted into a human understandable fxRate.
     */
    get fxRate(): number | null {

        if (this.corridorInfo) {

            const sendCurrency = Currency.of(this.corridorInfo.sendCurrency);
            const receiveCurrency = Currency.of(this.corridorInfo.receiveCurrency);

            const displayRate = this.corridorInfo.fxRate * Math.pow(10, sendCurrency.exponent - receiveCurrency.exponent);
            const precision = Math.pow(10, Math.min(3, Math.max(0, 4 - Math.floor(Math.log10(displayRate)))));

            return Math.round(displayRate * precision) / precision;
        }

        return null;
    }

    private moSelectReopened = false

    /**
     * hack to reopen the matSelect in order to realign the select panel on iOS after virtual keyboard 
     * is dismissed
     * 
     * TODO: find a better solution to ensure the alignment of select panel.
     * 
     * @param matSelect 
     * @param opened 
     */
    moSelectOpened(matSelect, opened) {

        if (this.platform.IOS && opened && !this.moSelectReopened) {
            
            this.moSelectReopened = true

            matSelect.close()

            this.ngZone.onStable.pipe(take(1)).subscribe(() => {

                matSelect.open()
            })
        }
    }

    getControl(name: string, sub?: string) {

        if (sub) {
            return this.remitFormGroup.get(name).get(sub);
        }

        return this.remitFormGroup.get(name);
    }

    error(control: AbstractControl) {

        return control.errors && Object.entries(control.errors)[0][0];
    }

    remit() {

        console.log('send', this.remitFormGroup, this.remitForm);

        const firstErrorFormControl = (this.firstErrorFormControl(this.remitFormGroup) as any);

        if (firstErrorFormControl) {
            firstErrorFormControl.focus();
        }

        if (this.remitFormGroup.valid || this.remitFormGroup.getError('server-error')) {

            const remitAdvice = {
                beneficiary: this.remitFormGroup.get('beneficiary').value,
                sendAmount: this.sendAmountCtrl.value,
                beneficiaryAmount: Math.floor(this.beneficiaryAmountCtrl.value)
            } as IRemitAdvice;

            const moneyOperator = this.moneyOperatorCtrl.value

            if (moneyOperator) {

                remitAdvice.moneyOperator = { 
                    id: moneyOperator.id,
                    name: moneyOperator.name
                } as IMoneyOperator
            }

            this.remitSubscription = this.remitService
                .remitAdvice(remitAdvice)
                .subscribe({
                    error: (error: ServerError) => {

                        switch (error.code) {

                            case 'ER-121': // suspended account
                                this.remitFormGroup.setErrors({ 'suspended': true });
                                break;

                            case 'ER-400': // unsupported corridor
                                this.getControl('beneficiary', 'msisdn').setErrors({ unsupported: true });
                                break;

                            case 'ER-230.010': // send min limit
                                this.sendAmountCtrl.setErrors({
                                    min: {
                                        min: error.metadata.limit,
                                        actual: this.sendAmountCtrl.value
                                    }
                                });
                                break;

                            case 'ER-240.010': // send max limit
                                this.sendAmountCtrl.setErrors({
                                    max: {
                                        max: error.metadata.limit,
                                        actual: this.sendAmountCtrl.value
                                    }
                                });
                                break;

                            case 'ER-230.020': // receive min limit
                                this.beneficiaryAmountCtrl.setErrors({
                                    min: {
                                        min: error.metadata.limit,
                                        actual: this.beneficiaryAmountCtrl.value
                                    }
                                });
                                break;

                            case 'ER-240.020': // receive max limit
                                this.beneficiaryAmountCtrl.setErrors({
                                    max: {
                                        max: error.metadata.limit,
                                        actual: this.beneficiaryAmountCtrl.value
                                    }
                                });
                                break;

                            case 'ER-210': // insufficient funds
                                this.sendAmountCtrl.setErrors({
                                    'insufficient': error.metadata
                                });
                                break;

                            case 'ER-250': // money operator not active
                                this.remitFormGroup.setErrors({ 'invalid-money-operator': true })
                                break

                            default:
                                this.remitFormGroup.setErrors({ 'server-error': true })
                        }
                    },
                    complete: () => {

                        this.analyticsService.gtmEvent('remittance', {
                            srcCountry: 'AU',
                            destCountry: this.getRegionCodeForNumber(remitAdvice.beneficiary.msisdn),
                            moneyOperator: remitAdvice.moneyOperator?.name,
                            amount: remitAdvice.sendAmount
                        })

                        // note: we need to reset the FormGroupDirective as this will set the form to not submitted
                        //       which sets materal inputs as not invalid. Calling FormGroup.reset() resets the inputs
                        //       but does not clear the validation errors.
                        this.remitForm.resetForm();

                        // the act of performing a remitAdvice adds the favorite.
                        // the favourites should be refreshed after completion remitAdvice
                        this.customerService.refreshFavorites();

                        this.dialog.open(RemitCompleteComponent, {
                            backdropClass: 'rr-light',
                            disableClose: true,
                            position: { top: '30px' }
                        });
                    }
                });
        }
    }

    private firstErrorFormControl(formGroup: FormGroup) {

        return this.firstErrorAbstractControls(Object.entries(formGroup.controls)
            // sort the form group controls by the key name
            // .sort((a, b) => {
            //     if (a[0] < b[0]) { return -1 }
            //     if (a[0] > b[0]) { return 1 }
            //     return 0
            // })
            .map((value) => value[1]));
    }

    private firstErrorAbstractControls(controls: AbstractControl[]) {

        for (let control of controls) {

            if (control instanceof FormGroup) {
                const firstErrorCtrl = this.firstErrorFormControl(control);
                if (firstErrorCtrl) { return firstErrorCtrl; }
            }
            if (control instanceof FormArray) {
                const firstErrorCtrl = this.firstErrorAbstractControls(control.controls);
                if (firstErrorCtrl) { return firstErrorCtrl; }
            }
            if (control instanceof FormControl && control.errors) {
                console.log('found error control', control);
                return control;
            }
        }

        return null;
    }
}