import { HttpBackend, HttpClient, HttpErrorResponse, HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http"
import { Inject, Injectable, Optional } from "@angular/core"
import { Observable, of, throwError } from "rxjs"
import { catchError, filter, last, map, mergeMap, share, switchMap, tap } from "rxjs/operators"
import { AuthHandlerConfig, JwtAuthHandlerConfig, dfltAuthHandlerConfig, dfltJwtAuthHandlerConfig } from "./auth.config"
import { JWT_AUTH_HANDLER_CONFIG } from "./auth.injectors"
import { CredentialsProvider } from "./credentials.provider"

import { AuthToken, AxUser, Credential } from "./model"

export abstract class AuthHandler<C extends Credential> {

    private readonly _cfg1: AuthHandlerConfig

    constructor(
        protected credentialsProvider: CredentialsProvider<C>,
        private config: AuthHandlerConfig
    ) {

        this._cfg1 = {
            ...dfltAuthHandlerConfig,
            ...config
        }
    }

    excludeReauth(req: HttpRequest<any>): boolean {
    
        return !!this._cfg1.excludeReauthUrls.find((url: string) => req.url.match(`^${url}$`))
    }

    abstract findCredential(res: HttpResponse<any>): C | null

    abstract addCredential(req: HttpRequest<any>, crendential: C): HttpRequest<any>

    /**
     * Pre-process the HttpRequest prior to sending the the server.
     * Pre-processing can be used to add an authenitcation token to the request headers.
     * 
     * @param res 
     */
    preRequest(req: HttpRequest<any>): HttpRequest<any> {

        const path = new URL(req.url).pathname

        if (!this._cfg1.excludeRequestUrls.find((url: string) => path.match(`^${url}$`))) {

            const credential = this.credentialsProvider.retrieveCredential()

            if (credential) {
                return this.addCredential(req, credential)
            }
        }

        return req
    }

    /**
     * Post-process the HttpResponse from the server
     * Post-processing can be used to retrieve an updated authentication token from the response. This would 
     * be required if the server updates an auth token to keep it from expiring. 
     * 
     * @param res
     */
    postResponse(res: HttpResponse<any>): void {
    
        if (!this._cfg1.excludeResponseUrls.find((url: string) => res.url?.match(`^${url}$`))) {

            const credential = this.findCredential(res)

            if (credential) {

                this.credentialsProvider.saveCredential(credential)
            }
        }
    }

    abstract validate(withAuth: boolean): Observable<AxUser>

    abstract authenticate(credential: Credential): Observable<AxUser>

    abstract logout(): Observable<boolean>

    // logout(): Observable<boolean> {

    //     this.credentialsProvider.removeCredentials()
    //     return of(true)
    // }
}

@Injectable()
export class JwtAuthHandler extends AuthHandler<AuthToken> {

    private readonly _config: JwtAuthHandlerConfig

    private inflightValidate = false

    private inFlightAuth = false
    private inFlightAuth$!: Observable<AxUser>

    constructor(
        private httpBackend: HttpBackend,
        private http: HttpClient,
        credentialsProvider: CredentialsProvider<AuthToken>,
        @Optional() @Inject(JWT_AUTH_HANDLER_CONFIG) config: JwtAuthHandlerConfig
    ) {
        super(credentialsProvider, config)

        this._config = {
            ...dfltJwtAuthHandlerConfig,
            ...config
        }
    }

    validate(withAuth: boolean): Observable<AxUser> {

        if (withAuth) {
            return this.http.get<AxUser>(this._config.host + this._config.validateEndpoint)
        }

        const httpClient = new HttpClient(this.httpBackend)

        const req = this.preRequest(new HttpRequest('GET', this._config.host + this._config.validateEndpoint, { observe: 'response' }))

        return httpClient.request<any>(req).pipe(
            filter((event: HttpEvent<any>) => event instanceof HttpResponse),
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                    this.postResponse(event)
                    return event.body
                }
            }),
            // catchError((error: HttpErrorResponse) => {

            //     if (withAuth && this.credentialsProvider.canRetrieveNewCredentials() && (error.status === 401 || )) {

            //         return this.credentialsProvider
            //                 .newCredentials()
            //                 .pipe(
            //                     switchMap((user) => {
            //                         console.log('login after validate complete', user)
            //                         return this.validate(false)
            //                     })
            //                 )

            //         // // the login / auth process is in-flight 
            //         // if (this.inFlightAuth) {
            //         //     return this.inFlightAuth$

            //         // } else {

            //         //     this.inFlightAuth = true
            //         //     console.log('perform login')

            //         //     this.inFlightAuth$ = this.credentialsProvider
            //         //             .newCredentials()
            //         //             .pipe(
            //         //                 last(),
            //         //                 switchMap((user) => {
            //         //                     console.log('login complete', user)
            //         //                     this.inFlightAuth = false
            //         //                     return this.validate(false)
            //         //                 }),
            //         //                 share()
            //         //             )
            //         //     return this.inFlightAuth$
            //         // }
            //     }

            //     return throwError(() => error)
            // })
        )
    }

    authenticate(credential: Credential): Observable<AxUser> {
       
        const httpClient = new HttpClient(this.httpBackend)

        return httpClient.post<any>(this._config.host + this._config.loginEndpoint, credential, { observe: 'response' })
            .pipe(
                tap({
                    next: (res: HttpResponse<any>) => {

                        this.postResponse(res)
                    }
                }),
                map((res: HttpResponse<any>) => res.body)
            )
    }

    findCredential(res: HttpResponse<any>): AuthToken | null {

        const token = res.headers.get(this._config.jwtResponseHeaderName)

        if (token) {
            return { token: token } as AuthToken
        }

        return null
    }

    addCredential(req: HttpRequest<any>, authToken: AuthToken): HttpRequest<any> {
        
        if (authToken) {

            return req.clone({
                headers: req.headers.set(this._config.jwtRequestHeaderName, authToken.token)
            })
        }

        return req
    }

    logout(): Observable<boolean> {
        // no need to perform logout request to server
        this.credentialsProvider.removeCredentials()

        return of(true)
    }
}