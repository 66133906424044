import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poli-first-time-load',
  templateUrl: './poli-first-time-load.component.html',
  styleUrls: ['./poli-first-time-load.component.scss']
})
export class PoliFirstTimeLoadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
