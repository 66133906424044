<div class="rr-load">

    <h3 class="dsb-title">
        <span class="txt">Load your Rocket Remit account:</span>
    </h3>

    <div class="rr-bank-transfer">

        <div class="tab-content">
            <span class="btn-close" (click)="closeAction()">
                <span class="txt">close</span>
                <span class="ico"></span>
            </span>
            <h3 class="lc-title">
                <span class="ico bank-transfer"></span>
                <span class="txt">Bank Transfer:</span>
            </h3>
            <div class="description">
                <p>Load your Rocket Remit account using the "Pay Anyone" feature of your Internet Banking with the
                    following details:</p>
            </div>
            <div class="info-table" [class.loading]="loading | async">
                <div class="table-box overlay">
                    <div class="table-row">
                        <div class="table-cell">
                            <span class="txt">BSB:</span>
                        </div>
                        <div class="table-cell">
                            <span class="txt">{{ bsb }}</span>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">
                            <span class="txt">Acct. No:</span>
                        </div>
                        <div class="table-cell">
                            <span class="txt">{{ accountNum }}</span>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">
                            <span class="txt">Name:</span>
                        </div>
                        <div class="table-cell">
                            <span class="txt">{{ accountName }}</span>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">
                            <span class="txt">Description:</span>
                        </div>
                        <div class="table-cell">
                            <span class="txt">{{ msisdn | async | msisdn:'NATIONAL' }}</span>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">
                            <span class="txt">Amount:</span>
                        </div>
                        <div class="table-cell">
                            <span class="txt">&lt;the amount&gt;</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="spinner | async" class="spinner-wrapper"><span class="spinner"></span></div>
            </div>
            <!--
        <a href="#" class="print-card">
            <span class="ico"></span>
            <span class="txt">Print this information.</span>
        </a>
        -->
            <div class="description">
                <span class="info">Note - please allow 1-2 business days for funds to be applied to your Rocket Remit
                    account.</span>
            </div>
        </div>
    </div>
</div>