<div class="registration-form">
    <a class="btn-hide-form" [routerLink]="['/']">
        <span class="txt">close</span>
        <span class="ico"></span>
      </a>
    <div class="form-box">
      <div class="wrapper">
        <form (ngSubmit)="passwordForgot()" novalidate autocomplete="off">
          <h2 class="form-title">
            <span class="txt">Forgotten Password</span>
          </h2>
          <div class="row">
            <div class="col-md-offset-3 col-md-6">
                <mat-form-field hideRequiredMarker="true">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput axMsisdn countryCode="AU" name="mobileNumber" [formControl]="msisdnCtrl" required="true" />
                    <div class="alert-block">
                        <span class="ico"></span>
                        <div [ngSwitch]="error(msisdnCtrl)" class="help-tooltip">
                            <span class="rect"></span>
                            <span *ngSwitchCase="'required'" class="txt">Mobile number is required</span>
                            <span *ngSwitchDefault class="txt">Mobile number is invalid</span>
                            <!-- Add more errors as necessary -->
                        </div>
                    </div>
                </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <button type="submit" class="btn btn-primary"><span class="btn-label">NEXT</span></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  