import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DASHBOARD_CONFIG } from "../../dashboard.config.token";
import { DashboardConfig } from "../../dashboard.config";


@Injectable()
export class UserAgentInterceptor implements HttpInterceptor {

    // constructor(@Inject(DASHBOARD_CONFIG) private config: DashboardConfig) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log('intercept request, inject custom user agent');

        const updatedReq = req.clone({
            headers: req.headers.set('RR-User-Agent', 'WEB')
        });

        return next.handle(updatedReq);
    }
}