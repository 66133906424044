import { Pipe, PipeTransform } from '@angular/core'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

@Pipe({
    name: 'msisdn'
})
export class MsisdnPipe implements PipeTransform {

    private phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()

    transform(value: any, format?: string): any {

        let phoneFormat = PhoneNumberFormat.INTERNATIONAL

        switch (format) {
            case 'NATIONAL':
                phoneFormat = PhoneNumberFormat.NATIONAL
                break
            case 'INTERNATIONAL':
                phoneFormat = PhoneNumberFormat.INTERNATIONAL
                break
            case 'E164':
                phoneFormat = PhoneNumberFormat.E164
                break
            case 'RFC3966':
                phoneFormat = PhoneNumberFormat.RFC3966
                break
        }

        try {

            const msisdn = this.phoneUtil.parse(value)

            return this.phoneUtil.format(msisdn, phoneFormat)

        } catch {}

        return value
    }
}
