import { 
    Directive, 
    Input, 
    ElementRef, 
    forwardRef,  
    ViewContainerRef, 
    NgZone, 
    ChangeDetectorRef, 
    Inject, 
    Optional, 
    Host, 
    Injector, 
    OnInit, 
    HostListener
} from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { Overlay, ViewportRuler } from '@angular/cdk/overlay'
import { Directionality } from '@angular/cdk/bidi'
import { MatFormField } from '@angular/material/form-field'
import { MatAutocompleteTrigger, MatAutocomplete, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete'
import { AbstractControl, Validator, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms'

import { MsisdnDirective } from '@assentinel/forms';


@Directive({
  selector: '[rrMsisdnFavorites]',
  host: {
    '[attr.autocomplete]': 'autocompleteAttribute',
    '[attr.role]': 'autocompleteDisabled ? null : "combobox"',
    '[attr.aria-autocomplete]': 'autocompleteDisabled ? null : "list"',
    '[attr.aria-activedescendant]': '(panelOpen && activeOption) ? activeOption.id : null',
    '[attr.aria-expanded]': 'autocompleteDisabled ? null : panelOpen.toString()',
    '[attr.aria-owns]': '(autocompleteDisabled || !panelOpen) ? null : autocomplete?.id',
    '[attr.aria-haspopup]': '!autocompleteDisabled',
    // note: we use `focusin`, as opposed to `focus`, in order to open the panel
    // a little earlier. This avoids issues where IE delays the focusing of the input.
    '(focusin)': '_handleFocus()',
    '(blur)': '_onTouched()',
    // '(input)': '_handleInput($event)',
    // '(keydown)': '_handleKeydown($event)',
  },
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MsisdnFavouritesDirective),
    multi: true
  }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MsisdnFavouritesDirective),
      multi: true
  }]
})
export class MsisdnFavouritesDirective extends MatAutocompleteTrigger implements OnInit, Validator {

    @Input('rrMsisdnFavorites') 
    declare autocomplete: MatAutocomplete;

    private msisdnDirective: MsisdnDirective;

    constructor(elementRef: ElementRef<HTMLInputElement>, private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_AUTOCOMPLETE_SCROLL_STRATEGY) scrollStrategy: any,
        @Optional() private dir: Directionality,
        @Optional() @Host() private formField: MatFormField,
        @Optional() @Inject(DOCUMENT) private document: any,
        private injector: Injector,
        // @breaking-change 8.0.0 Make `_viewportRuler` required.
        private viewportRuler?: ViewportRuler) {

            super(elementRef, overlay, viewContainerRef, zone, changeDetectorRef, scrollStrategy, dir, formField, document, viewportRuler);

            this.msisdnDirective = new MsisdnDirective(elementRef);
    }

    ngOnInit(): void {

        this.msisdnDirective.ngOnInit();

        this.msisdnDirective.registerOnChange((value) => { this._onChange(value); });
    }

    writeValue(value: any) {

        this.msisdnDirective.writeValue(value);

        super.writeValue(value);
    }

    validate(control: AbstractControl): ValidationErrors {
        return this.msisdnDirective.validate(control);
    }
    registerOnValidatorChange?(fn: () => void): void {
        this.msisdnDirective.registerOnValidatorChange(fn);
    }

    _handleKeydown(event: KeyboardEvent): void {

        this.msisdnDirective.handleKeydown(event);

        // super._handleKeydown(event);
    }

    _handleInput(event: KeyboardEvent): void {

        this.msisdnDirective.handleInput(event);
    }
}
