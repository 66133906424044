import { Component } from '@angular/core';
import { AuthService } from '@assentinel/auth';
import { AuthenticationService } from '../../data/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './send-money.component.html',
  styleUrls: ['./send-money.component.scss']
})
export class SendMoneyComponent {

    authenticated = false

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        axAuthService: AuthService) {

        axAuthService.isAuthenticated().subscribe((authenticated: boolean) => this.authenticated = authenticated)
    }

    isRegistered(): boolean {

        return this.authService.isRegistered()
    }

    sendMoney() {

        if (this.authenticated) {

            this.authService.gotoWebapp()

        } else if (this.authService.isRegistered()) {

            this.router.navigate([ 'login' ])
            window.scrollTo({ top:0, behavior: 'smooth' })


        } else {

            this.router.navigate([ 'register' ])
            window.scrollTo({ top:0, behavior: 'smooth' })
        }
    }
}
