import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { Location } from '@angular/common'
import { createCustomElement } from '@angular/elements'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router, RouterModule } from '@angular/router'

import {
    AxAuthModule,
    CREDENTIAL_PROVIDER_CONFIG
} from '@assentinel/auth'
import { AxMsisdnModule } from '@assentinel/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons'

import { MenuBarComponent } from './elements/menu-bar/menu-bar.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component'
import { PasswordForgotComponent } from './components/password-forgot/password-forgot.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { MenuAuthComponent } from './elements/menu-auth/menu-auth.component'
import { SendMoneyComponent } from './elements/send-money/send-money.component'

import { environment } from '../../environments/environment';

@NgModule({
    declarations: [
        MenuBarComponent,
        RegisterComponent,
        LoginComponent,
        PasswordForgotComponent,
        ResetPasswordComponent,
        MenuAuthComponent,
        SendMoneyComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([
            { path: 'register', component: RegisterComponent, data: { class: "" } },
            { path: 'login', component: LoginComponent },
            { path: 'forgot-password', component: PasswordForgotComponent },
            { path: 'reset-password', component: ResetPasswordComponent }

        ], { useHash: true, scrollPositionRestoration: 'top' }),
        
        ReactiveFormsModule,
        FormsModule,
        AxAuthModule,
        AxMsisdnModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,

        Angular2PromiseButtonModule.forRoot() 
    ],
    providers: [
        {
            provide: CREDENTIAL_PROVIDER_CONFIG,
            useValue: {
                storageType: environment.tokenStorage
            }
        }
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class MenuModule {

    constructor(injector: Injector, private router: Router, private location: Location) {

        const menuBarElement = createCustomElement(MenuBarComponent, { injector })
        const menuAuth = createCustomElement(MenuAuthComponent, { injector })
        const sendMoneyBtnElement = createCustomElement(SendMoneyComponent, { injector })

        customElements.define('menu-bar', menuBarElement)
        customElements.define('menu-auth', menuAuth)
        customElements.define('send-money', sendMoneyBtnElement)

        //on every route change tell router to navigate to defined route
        this.location.subscribe((data: any) => {
            console.log("Data subscribe", data);
            this.router.navigateByUrl(data.url);
        });

        //using this router outlet is loaded normaly on init
        this.router.navigateByUrl(this.location.path(true));


        //event subscribe to detect route change inside angular
        this.router.events.subscribe((data) => {
            // console.log(data);
        });
    }
}
