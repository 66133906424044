import { Location } from '@angular/common'
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { Subscription, of } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'
import { CustomerService, NavigationService } from '../../../../data/service'
import { IFee, ILimit, ILimitsAndFees } from '../../../../data/types'
@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {

    loadAmountCtrl = new FormControl()
    loadFormGrp = new FormGroup({ amount: this.loadAmountCtrl })

    loadSubscription: Subscription

    limit: ILimit
    fee: IFee

    @Output()
    back = new EventEmitter()

    constructor(
        private customerService: CustomerService,
        private navService: NavigationService,
        private location: Location
    ) { }

    ngOnInit(): void {

        this.customerService.getLimitsAndFees('CARD')
            .subscribe((limitsAndFees: ILimitsAndFees) => {

                this.limit = limitsAndFees.limit
                this.fee = limitsAndFees.fee

                this.loadAmountCtrl.addValidators(Validators.min(this.limit.min))
                if (this.limit.max) {
                    this.loadAmountCtrl.addValidators(Validators.max(this.limit.max))
                }
            })
    }

    load() {

        console.log('load', this.loadAmountCtrl)

        if (this.loadAmountCtrl.valid) {

            this.loadSubscription = this.customerService
                .cardLoad(this.loadAmountCtrl.value)
                .pipe(
                    switchMap((response: any) => {
                        this.navService.redirectPost(response.fiservUrl, response.params)
                        console.log('redirected to fiserv')
                        return of().pipe(delay(2000))
                    })
                ).subscribe({
                    next: _ => { },
                    error: (error) => {

                        console.log('error', error)

                        switch (error.code) {

                            case 'ER-230': // send min limit
                                this.loadAmountCtrl.setErrors({
                                    min: {
                                        min: error.metadata.limit,
                                        actual: this.loadAmountCtrl.value
                                    }
                                })
                                break

                            case 'ER-240': // send max limit
                                this.loadAmountCtrl.setErrors({
                                    max: {
                                        max: error.metadata.limit,
                                        actual: this.loadAmountCtrl.value
                                    }
                                })
                                break

                            default:
                                this.loadAmountCtrl.setErrors({ 'server-error': true })
                        }
                    }
                })
        }
    }

    firstError(control: AbstractControl) {

        return control.errors && Object.entries(control.errors)[0][0]
    }
    closeAction(): void {
        this.location.back()
    }

}
