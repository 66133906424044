import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@assentinel/forms';
import { ICorridorInfo } from '../../../data';

@Pipe({
  name: 'fxRate'
})
export class FxRatePipe implements PipeTransform {

  transform(corridorInfo: ICorridorInfo | undefined, ...args: unknown[]): number | null{

    if (corridorInfo) {

        const sendCurrency = Currency.of(corridorInfo.sendCurrency);
        const receiveCurrency = Currency.of(corridorInfo.receiveCurrency);

        const displayRate = corridorInfo.fxRate * Math.pow(10, sendCurrency.exponent - receiveCurrency.exponent);
        const precision = Math.pow(10, Math.min(3, Math.max(0, 4 - Math.floor(Math.log10(displayRate)))));

        return Math.round(displayRate * precision) / precision;
    }

    return null;
  }

}
