
import { Component, EventEmitter, Inject, Optional, Output } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { dfltMatDialogLoginConfig, MatDialogLoginConfig } from '../auth.config'
import { MAT_DIALOG_LOGIN_CONFIG } from '../auth.injectors'
import { AuthService } from '../auth.service'
import { AxUser, UsernamePassword } from '../model'
import { Subscription } from 'rxjs'

@Component({
    selector: 'ax-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class AxLoginComponent {

    private _config: MatDialogLoginConfig

    loginSubscription!: Subscription

    usernameLabel: string
    passwordLabel: string
    loginSubmitLabel: string

    authFailure = false

    usernameCtrl = new FormControl('', Validators.required)
    passwordCtrl = new FormControl('', Validators.required)

    @Output()
    loggedIn = new EventEmitter<AxUser>()

    constructor(
        private authService: AuthService,
        @Optional() @Inject(MAT_DIALOG_LOGIN_CONFIG) config: MatDialogLoginConfig) {

        this._config = {
            ...dfltMatDialogLoginConfig,
            ...config
        }

        this.usernameLabel = this._config.usernameLabel
        this.passwordLabel = this._config.passwordLabel
        this.loginSubmitLabel = this._config.loginSubmitLabel
    }

    login() {

        if (this.usernameCtrl.valid && this.passwordCtrl.valid) {

            this.loginSubscription = this.authService.authenticate({
                username: this.usernameCtrl.value,
                password: this.passwordCtrl.value
            } as UsernamePassword)
                .subscribe({
                    next: (user: AxUser) => {
                        this.loggedIn.next(user)
                    },
                    error: (e: any) => {
                        this.authFailure = true
                    }
                })
        }
    }
}
