import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { HarmonyConfig } from '../../shared.config';
import { HARMONY_CONFIG } from '../../shared.injectors';
import { IAddressLookup, IAddress } from '../types/address';

import { Buffer } from "buffer";

@Injectable({
    providedIn: 'root'
})
export class AddressLookupService {

    readonly harmonyClient: HttpClient
    readonly authToken: string

    constructor(
        httpBackend: HttpBackend,
        @Inject(HARMONY_CONFIG) private config: HarmonyConfig
    ) {

        this.harmonyClient = new HttpClient(httpBackend)
        this.authToken = 'Basic ' + Buffer.from(config.username + ":" + config.password).toString('base64')
    }

    lookupAddress(address: string, country: string): Observable<IAddressLookup[]> {

        let sourceOfTruth


        switch (country) { 
            case 'AU':
                sourceOfTruth = 'AUPAF'
                break
            case 'NZ': 
                sourceOfTruth = 'NZPAF'
                break
            default: 
                return throwError(() => new Error(`unsupported country ${country}`))
        }

        return this.harmonyClient.post('https://hosted.mastersoftgroup.com/harmony/rest/v2/address/find', {
                payload: [ {
                    fullAddress: address,
                    country: country
                } ],
                sourceOfTruth: sourceOfTruth,
                featureOptions: { 
                    positionFilter: 'SINGLE, DOORSTOP, ROOFTOP' 
                }
            },
            {
                headers: {
                    Authorization: this.authToken
                }
            }).pipe(
                map((resp: any) => {
                    if (resp.status === 'SUCCESS') {
                        return resp.payload as IAddressLookup[]
                    }
                    throw new Error('Invalid address lookup response')
                })
            )
        }
    
        retrieveAddress(id: string): Observable<IAddress> {

            return this.harmonyClient.post('https://hosted.mastersoftgroup.com/harmony/rest/v2/address/retrieve', {
                payload: [ { 
                    id: id
                } ]
            }, {
                headers: {
                    Authorization: this.authToken
                }
            }).pipe(
                map((resp: any) => {

                    if (resp.status === 'SUCCESS' && resp.payload) {
                        // map the response 
                        return {
                            addressLine: resp.payload[0].fullAddress,
                            flatNumber: resp.payload[0].flatUnitNumber,
                            streetNumber: resp.payload[0].streetNumber,
                            streetName: resp.payload[0].streetName,
                            streetType: resp.payload[0].streetType,
                            suburb: resp.payload[0].locality,
                            state: resp.payload[0].state,
                            postcode: resp.payload[0].postcode
                        } as IAddress
                    } 
                    new Error('Invalid address retrieve response')
                })
            )

        }
}
