<div class="bn-cont">
    <div class="calculate-box">
        <!-- Heading -->
        <h2 class="calculator-title">Rate Calculator</h2>
        <!-- In your component's HTML file -->
        <form [formGroup]="rateCalcForm" (submit)="send()">
            <!-- You Send Section -->
            <label for="send-amount-field" class="input-label">You send</label>
            <div class="input-currency">
                <mat-form-field>
                    <input matInput [axCurrency]="senderCurrency" [formControl]="sendAmountCtrl" placeholder="0.00" min="0" required />
                    <div matSuffix><span class="flag-icons" [ngClass]="senderCountryCode"></span>{{ senderCurrency }}</div>
                    <div class="alert-block">
                        <span class="ico"></span>
                        <div [ngSwitch]="error(sendAmountCtrl)" class="help-tooltip">
                            <span class="rect"></span>
                            <span *ngSwitchCase="'min'" class="txt">The send amount is below the minimum of {{ sendAmountCtrl.getError('min').min | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                            <span *ngSwitchCase="'max'" class="txt">The send amount is above the maximum of {{ sendAmountCtrl.getError('max').max | axCurrency:senderCurrency }} {{ senderCurrency }}</span>
                            <span *ngSwitchDefault class="txt">The send amount is invalid</span>
                        </div>
                    </div>
                </mat-form-field>
            </div>
            <!-- Receiver Gets Section -->
            <label for="receive-amount-field" class="input-label">Receiver gets</label>
            <div class="input-currency">
                <mat-form-field>
                    <input matInput [axCurrency]="receiverCurrency" [formControl]="receiveAmountCtrl" placeholder="0.00" min="0" required />
                    <div matSuffix><span class="flag-icons" [ngClass]="receiverCountryCode"></span>{{ receiverCurrency }}</div>
                    <div class="alert-block">
                        <span class="ico"></span>
                        <div [ngSwitch]="error(receiveAmountCtrl)" class="help-tooltip">
                            <span class="rect"></span>
                            <span *ngSwitchCase="'min'" class="txt">The receiver amount is below the minimum of {{ receiveAmountCtrl.getError('min').min | axCurrency:receiverCurrency }} {{ receiverCurrency }}</span>
                            <span *ngSwitchCase="'max'" class="txt">The receiver amount is above the maximum of {{ receiveAmountCtrl.getError('max').max | axCurrency:receiverCurrency }} {{ receiverCurrency }}</span>
                            <span *ngSwitchDefault class="txt">The receiver amount is invalid</span>
                        </div>
                    </div>
                </mat-form-field>
            </div>

            <!-- Conversion Rate -->
            <div class="calc-info">
                <span class="txt">Currency exchange rate: <b>1</b> {{ senderCurrency }} = <b>{{ corridorInfo | fxRate }}</b> {{ receiverCurrency }}</span>
            </div>

            <!-- Fee and Total Amount Section -->
            <div class="receiver-amount">
                <div class="row fee-row">
                    <div class="cell txt"><span class="txt">Fee:</span></div>
                    <div class="cell"><b>{{ (corridorInfo ? corridorInfo.fee : 0) | axCurrency:senderCurrency }} {{ senderCurrency }}</b></div>
                </div>
                <hr class="separator">
                <div class="row total-row">
                    <div class="cell txt"><span class="txt">Total to Pay:</span></div>
                    <div class="cell"><b>{{ (sendAmountCtrl.value ? sendAmountCtrl.value + corridorInfo.fee: 0) | axCurrency:senderCurrency }} {{ senderCurrency }}</b>
                    </div>
                </div>
            </div>

            <!-- Send Button -->
            <button class="send-button" type="submit">Send</button>
        </form>
        <!-- Trustpilot Rating -->
        <div class="trustpilot-rating">
            <a class="trustpilot" href="https://www.trustpilot.com/review/www.rocketremit.com" target="_blank">
                <span>Rated Excellent on</span>
                <div class="trustpilot-logo"></div>
            </a>
        </div>
    </div>
</div>