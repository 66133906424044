<a href="https://www.rocketremit.com" class="close-btn">
    <mat-icon svgIcon="close"></mat-icon>
</a>

<div class="login dark-background" style="text-align: center;">

  <div class="logo"></div>

  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate="true">
    <div class="row">
      <div class="form-group">
        <mat-form-field hideRequiredMarker="true">
          <mat-label>mobile number</mat-label>
          <input matInput axMsisdn countryCode="AU" [formControl]="usernameCtrl" required>
          <div class="alert-block">
              <span class="ico"></span>
              <div class="help-tooltip">
                  <span class="txt">mobile number is required</span>
                  <span class="txt">mobile number is invalid</span>
                  <span class="rect"></span>
              </div>
          </div> 
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <mat-form-field hideRequiredMarker="true">
          <mat-label>password</mat-label>
          <input matInput type="password" [formControl]="passwordCtrl" required>
          <div class="alert-block">
              <span class="ico"></span>
              <div class="help-tooltip">
                  <span class="txt">password is required</span>
                  <span class="rect"></span>
              </div>
          </div> 
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="authFailure" class="alert alert-warning" role="alert">
      <div>
          <div class="txt">Your mobile number or password is incorrect!</div>
      </div>
    </div>

    <div class="buttons-wrap">
        <div class="buttons-row">
          <button type="submit" class="btn btn-primary">
            <span class="btn-label">LOGIN</span>
            <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
          </button>
        </div>
        <!-- <div class="buttons-row signup">
          <a class="btn btn-skip" style="color: white;" href="https://www.rocketremit.com?utm_source=app&utm_medium=referal&utm_campaign=signup">
            <span class="table-box">
              <span class="table-cell">
                <span class="txt">Don't have an account?</span>
              </span>
            </span>
            <span class="table-box">
              <span class="table-cell">
                <span class="txt">Signup here!</span>
              </span>
            </span>
          </a>
        </div> -->
    </div>

  </form>

</div>