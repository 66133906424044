<div class="rr-load">

    <h3 class="dsb-title">
        <span class="txt">Load your Rocket Remit account:</span>
    </h3>
  
<div class="rr-poli">
    <div class="tab-content">
        <span class="btn-close" (click)="back.emit()">
            <span class="txt">close</span>
            <span class="ico"></span>
        </span>
        <h3 class="lc-title">
            <span class="ico poli"></span>
            <span class="txt">Instant load with POLi:</span>
        </h3>
        <div class="description">
            <p>Load your Rocket Remit account using POLi. POLi works just like Internet Banking but is quicker and easier and funds are loaded instantly.</p>
        </div>
        <form [formGroup]="loadFormGrp" novalidate="true" (ngSubmit)="load()">

            <div class="load-amount">
                <div class="price">
                    <mat-form-field hideRequiredMarker="true">
                        <!-- <mat-label>send amount</mat-label>  -->
                        <input matInput axCurrency="AUD" [formControl]="loadAmountCtrl" required>
                        <span matPrefix class="dollar">$</span>
                        <span matSuffix>AUD</span>
                        <div *ngIf="loadAmountCtrl.invalid" class="alert-block">
                            <span class="ico"></span>
                            <div [ngSwitch]="firstError(loadAmountCtrl)" class="help-tooltip">
                                <span *ngSwitchCase="'required'" class="txt">amount is required</span>
                                <span *ngSwitchCase="'min'" class="txt">amount is below the minimum limit of {{ loadAmountCtrl.errors.min.min | axCurrency:'AUD' }} AUD</span>
                                <span *ngSwitchCase="'max'" class="txt">amount is above the maximum limit of {{ loadAmountCtrl.errors.max.max | axCurrency:'AUD' }} AUD</span>
                                <span class="rect"></span>
                            </div>
                        </div>
                    </mat-form-field>

                    <div class="pr-des">
                        <span class="txt">min POLi amount ${{ limit?.min | axCurrency:'AUD' }}</span>
                                        </div>
                    <div class="fee" *ngIf="fee">
                        <span class="txt">+ ${{ fee.fixed | axCurrency:'AUD' }} fee</span>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-primary" [promiseBtn]="loadSubscription">
                <span class="btn-label">LOAD ACCOUNT NOW</span>
                <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
            </button>
        </form>
        <div class="description">
            <span class="info">Note - first time account loads performed using POLi are subject to physical funds clearance. This means that depending on your bank, the first time you use POLi it may take up to 24 hours for funds to reflect in your Rocket Remit account. Once your account is established, POLi transfers will be instantaneous.</span>
        </div>
    </div>
</div>
</div>