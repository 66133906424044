import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private previousUrl: string;
    private currentUrl: string;

    constructor(router: Router) {

        router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {

            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;

            this.gtmEvent('route-view', {
                path: this.currentUrl,
                referrer: this.previousUrl
            })
        })
    }

    gtmEvent(event: string, params: any = {}): void {
        (<any>window).dataLayer.push({
            event: event,
            ...params
        })
    }
}
