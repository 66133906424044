@import 'variables';

// This style block pertains to name fields on a form.
.row.name {

    // .form-group represents individual input fields or sections in the form.
    .form-group {

        // Padding is set to create spacing between each form group.
        padding: 0 5px;

        // The first form group doesn't have padding on the left for alignment.
        &:first-child {
            padding-left: 0;
        }

        // The last form group doesn't have padding on the right for alignment.
        &:last-child {
            padding-right: 0;
        }

        // For screens with a maximum width of 800px, padding is removed.
        @media (max-width: 800px) {
            padding: 0;
        }
    }
}

// A separate class for a third-party integration called Trustpilot.
.trustpilot {
    padding-bottom: 2.5em;
    padding-top: 0em;

}
