import { Component, ComponentFactoryResolver, Inject, Injector, NgZone, OnInit, Optional, ViewChild, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { Router } from '@angular/router'
import { LoginRouteConfig, dfltLoginRouteConfig } from '../auth.config'
import { LOGIN_ROUTE_CONFIG } from '../auth.injectors'
import { AxLoginComponent } from '../login/login.component'
import { AxUser } from '../model'

@Component({
    templateUrl: './login-route.component.html',
    styleUrls: ['./login-route.component.scss']
})
export class AxLoginRouteComponent implements OnInit {

    @ViewChild('loginComponent', { read: ViewContainerRef , static: true })
    loginRef!: ViewContainerRef

    // @ViewChild(AxLoginComponent, {static: true}) loginComponent!: AxLoginComponent


    private _config: LoginRouteConfig

    constructor(
        private router: Router,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private ngZone: NgZone,
        @Optional() @Inject(LOGIN_ROUTE_CONFIG) config: LoginRouteConfig) { 

            this._config = {
                ...dfltLoginRouteConfig,
                ...config
            }
        }

    ngOnInit(): void {

        const componentFactory = this.resolver.resolveComponentFactory(this._config.loginComponent)

        const componentRef = this.loginRef.createComponent<AxLoginComponent>(componentFactory, undefined, this.injector)
        componentRef.instance.loggedIn.subscribe({
            next: (user: AxUser) => this.router.navigateByUrl(this._config.homePath)
        })
    }
}
