import { Directive, forwardRef, HostListener, ElementRef } from "@angular/core"
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

@Directive({
    selector: '[rrBsb]',
    host: {
        '(blur)': 'onTouched()'
    },
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BsbDirective),
        multi: true,
    }]
})
export class BsbDirective implements ControlValueAccessor {

    private onModelChange = (val) => {}
    onTouched = () => {}

    constructor(private elementRef: ElementRef<HTMLInputElement>) {}

    writeValue(bsb: string): void {

        console.log('write bsb value', bsb)
        this.formatBsb(bsb)
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    @HostListener('keydown', ['$event'])
    handleKeydown(event: any) {

        const keyCode = event.which || event.charCode || event.keyCode

        // backspace || delete || safari delete
        if (keyCode === 8 || keyCode === 46 || keyCode === 63272) {

            event.preventDefault()

            const viewValue = this.viewValue
            const viewValueLength = viewValue.length
            const selectionStart = this.inputSelection.selectionStart
            const selectionEnd = this.inputSelection.selectionEnd
            const selectionRange = Math.abs(selectionEnd - selectionStart) > 0

            let deleteStart = selectionStart
            let deleteEnd = selectionEnd

            // only process delete if no selection or election does not contain number
            if (!selectionRange || !/\d/.test(viewValue.slice(selectionStart, selectionEnd))) {

                // handle backspace if start of selection is not at beginning
                if (keyCode === 8 && selectionStart > 0) {

                    // find the location of the first proceeding deletable (numeric) character
                    let deletableChar = false
                    while (deleteStart > 0 && !deletableChar) {

                        const c = viewValue.charAt(deleteStart - 1)
                        deletableChar = /\d/i.test(c)
                        deleteStart--
                    }
                }

                // handle delete and selection is not at end of value
                if ((keyCode === 46 || keyCode === 63272) && selectionEnd < viewValue.length) {

                    // find the first deletable (numeric) character after the selection
                    let deletableChar = false
                    while (deleteEnd < viewValue.length && !deletableChar) {

                        const c = viewValue.charAt(deleteEnd)
                        deletableChar = /\d/i.test(c)
                        deleteEnd++
                    }
                }
            }

            const newViewModel = viewValue.slice(0, deleteStart) + viewValue.slice(deleteEnd)
            this.formatBsb(newViewModel)

            if (viewValueLength !== selectionStart) {
                this.setCursorPosition(deleteStart)
            }
        }
    }

    @HostListener('input', ['$event'])
    handleInput(event: any) {

        const selectionStart = this.inputSelection.selectionStart

        const viewValue = this.viewValue
        const viewValueLength = viewValue.length
        const keyCode = viewValue.charCodeAt(selectionStart - 1)

        this.formatBsb(this.viewValue)

        if (viewValueLength !== selectionStart) {
            this.setCursorPosition(selectionStart)
        }

        event.preventDefault()
    }

    formatBsb(value: string) {

        if (value) {
            
            let viewValue = ''

            const cleanBsb = value.replace(/\D/g, '')
            if (cleanBsb.length <= 3) {
                viewValue = cleanBsb
            } else {
                viewValue = cleanBsb.substr(0,3) + '-' + cleanBsb.substr(3, 3)
            }

            this.viewValue = viewValue

            this.onModelChange(cleanBsb.substr(0, 6))
        }
        else {

            this.viewValue = ''
            this.onModelChange(null)
        }

    }

    get viewValue() {
        return this.elementRef.nativeElement && this.elementRef.nativeElement.value
    }

    set viewValue(viewValue: string) {
        this.elementRef.nativeElement.value = viewValue
    }

    get inputSelection(): any {

        let selectionStart = 0
        let selectionEnd = 0

        const nativeElement = this.elementRef.nativeElement

        if (typeof nativeElement.selectionStart === 'number' && typeof nativeElement.selectionEnd === 'number') {
            selectionStart = nativeElement.selectionStart
            selectionEnd = nativeElement.selectionEnd
        }

        return {
            selectionStart,
            selectionEnd
        }
    }

    setCursorPosition(position: number) {

        const nativeElement = this.elementRef.nativeElement

        if (nativeElement.setSelectionRange) {
            nativeElement.focus()
            nativeElement.setSelectionRange(position, position)
        }
    }
}
    