import { InjectionToken } from '@angular/core'
import { CredentialsProviderConfig, JwtAuthHandlerConfig, LoginRouteConfig, MatDialogLoginConfig } from './auth.config'
import { RolePrivileges } from './model'

export const CREDENTIAL_PROVIDER_CONFIG = new InjectionToken<CredentialsProviderConfig>('CREDENTIAL_PROVIDER_CONFIG')

export const JWT_AUTH_HANDLER_CONFIG = new InjectionToken<JwtAuthHandlerConfig>('JWT_AUTH_HANDLER_CONFIG')

export const MAT_DIALOG_LOGIN_CONFIG = new InjectionToken<MatDialogLoginConfig>('MAT_DIALOG_LOGIN_CONFIG')

export const LOGIN_ROUTE_CONFIG = new InjectionToken<LoginRouteConfig>('LOGIN_ROUTE_CONFIG')

export const ROLE_PRIVILEGES = new InjectionToken<RolePrivileges>('ROLE_PRIVILEGES')