import { CommonModule } from '@angular/common';
import { ComponentFactory, ComponentFactoryResolver, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AxFocusableControlModule } from '@assentinel/forms';

import { AccountNumNationalIdComponent } from './account-num-national-id/account-num-national-id.component';
import { AccountNumComponent } from './account-num/account-num.component';
import { IndiaBanksComponent } from './india-banks/india-banks.component';
import { MoneyOperatorComponent } from './money-operator-component';
import { NationalIdComponent } from './national-id/national-id.component';
import { BsbDirective } from './pacific-corridors/bsb.directive';
import { PacificCorridorsComponent } from './pacific-corridors/pacific-corridors.component';
import { RocketWalletComponent } from './rocket-wallet/rocket-wallet.component';

const componentMap = {
    'account-num': AccountNumComponent,
    'india-banks': IndiaBanksComponent,
    'national-id': NationalIdComponent,
    'pacific-corridors': PacificCorridorsComponent,
    'rocket-wallet': RocketWalletComponent,
    'account_num_national_id': AccountNumNationalIdComponent,
}

@NgModule({
    declarations: [
        AccountNumComponent,
        IndiaBanksComponent,
        NationalIdComponent,
        PacificCorridorsComponent,
        RocketWalletComponent,
        BsbDirective,
        AccountNumNationalIdComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AxFocusableControlModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule
    ],
})
export class MoneyOperatorModule {

    constructor(private resolver: ComponentFactoryResolver) { }

    resolveMoneyOperatorComponentFactory(moneyOperator: string): ComponentFactory<MoneyOperatorComponent> {

        return this.resolver.resolveComponentFactory(componentMap[moneyOperator]);
    }
}
