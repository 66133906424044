export interface ICountry {
    alpha2: string
    alpha3: string
    code: string
    name: string
    nationality: string
}

export const countries: ICountry[] = [
    { alpha2: "AF", alpha3: "AFG", code: "004", name: "Afghanistan", nationality: "Afghan"},
    { alpha2: "AX", alpha3: "ALA", code: "248", name: "Aland Islands", nationality: "Alandic" }, // ***
    { alpha2: "AL", alpha3: "ALB", code: "008", name: "Albania", nationality: "Albanian" },
    { alpha2: "DZ", alpha3: "DZA", code: "012", name: "Algeria", nationality: "Algerian" },
    { alpha2: "AS", alpha3: "ASM", code: "016", name: "American Samoa", nationality: "American Samoan" }, // ***
    { alpha2: "AD", alpha3: "AND", code: "020", name: "Andorra", nationality: "Andorran" },
    { alpha2: "AO", alpha3: "AGO", code: "024", name: "Angola", nationality: "Angolan" },
    { alpha2: "AI", alpha3: "AIA", code: "660", name: "Anguilla", nationality: "Anguillan" }, //
    { alpha2: "AG", alpha3: "ATG", code: "028", name: "Antigua and Barbuda", nationality: "Antiguan and Barbudan" },
    { alpha2: "AR", alpha3: "ARG", code: "032", name: "Argentina", nationality: "Argentinian" },
    { alpha2: "AM", alpha3: "ARM", code: "051", name: "Armenia", nationality: "Armenian" },
    { alpha2: "AW", alpha3: "ABW", code: "533", name: "Aruba", nationality: "Aruban" },
    { alpha2: "AU", alpha3: "AUS", code: "036", name: "Australia", nationality: "Australian" },
    { alpha2: "AT", alpha3: "AUT", code: "040", name: "Austria", nationality: "Austrian" },
    { alpha2: "AZ", alpha3: "AZE", code: "031", name: "Azerbaijan", nationality: "Azerbaijani" },
    { alpha2: "BS", alpha3: "BHS", code: "044", name: "Bahamas", nationality: "Bahamian" },
    { alpha2: "BH", alpha3: "BHR", code: "048", name: "Bahrain", nationality: "Bahraini" },
    { alpha2: "BD", alpha3: "BGD", code: "050", name: "Bangladesh", nationality: "Bangladeshi" },
    { alpha2: "BB", alpha3: "BRB", code: "052", name: "Barbados", nationality: "Barbadian" },
    { alpha2: "BY", alpha3: "BLR", code: "112", name: "Belarus", nationality: "Belarusian" },
    { alpha2: "BE", alpha3: "BEL", code: "056", name: "Belgium", nationality: "Belgian" },
    { alpha2: "BZ", alpha3: "BLZ", code: "084", name: "Belize", nationality: "Belizean" },
    { alpha2: "BJ", alpha3: "BEN", code: "204", name: "Benin", nationality: "Beninese" },
    { alpha2: "BM", alpha3: "BMU", code: "060", name: "Bermuda", nationality: "Bermudian" }, // ***
    { alpha2: "BT", alpha3: "BTN", code: "064", name: "Bhutan", nationality: "Bhutanese" },
    { alpha2: "BO", alpha3: "BOL", code: "068", name: "Bolivia", nationality: "Bolivian" },
    // { alpha2: "BQ", alpha3: "BES", code: "535", name: "Bonaire, Sint Eustatius and Saba", nationality: "" },
    { alpha2: "BA", alpha3: "BIH", code: "070", name: "Bosnia and Herzegovina", nationality: "Bosnian" },
    { alpha2: "BW", alpha3: "BWA", code: "072", name: "Botswana", nationality: "Batswana" },
    // { alpha2: "BV", alpha3: "BVT", code: "074", name: "Bouvet Island", nationality: "" },
    { alpha2: "BR", alpha3: "BRA", code: "076", name: "Brazil", nationality: "Brazilian" },
    // { alpha2: "IO", alpha3: "IOT", code: "086", name: "British Indian Ocean Territory", nationality: "" },
    { alpha2: "VG", alpha3: "VGB", code: "092", name: "British Virgin Islands", nationality: "British Virgin Islander" }, // ***
    { alpha2: "BN", alpha3: "BRN", code: "096", name: "Brunei Darussalam", nationality: "Bruneian" }, // ***
    { alpha2: "BG", alpha3: "BGR", code: "100", name: "Bulgaria", nationality: "Bulgarian" },
    { alpha2: "BF", alpha3: "BFA", code: "854", name: "Burkina Faso", nationality: "Burkinabe" },
    { alpha2: "BI", alpha3: "BDI", code: "108", name: "Burundi", nationality: "Burundian" },
    { alpha2: "KH", alpha3: "KHM", code: "116", name: "Cambodia", nationality: "Cambodian" },
    { alpha2: "CM", alpha3: "CMR", code: "120", name: "Cameroon", nationality: "Cameroonian" },
    { alpha2: "CA", alpha3: "CAN", code: "124", name: "Canada", nationality: "Canadian" },
    { alpha2: "CV", alpha3: "CPV", code: "132", name: "Cape Verde", nationality: "Cape Verdean" }, // ***
    { alpha2: "KY", alpha3: "CYM", code: "136", name: "Cayman Islands", nationality: "Caymanian" }, // ***
    { alpha2: "CF", alpha3: "CAF", code: "140", name: "Central African Republic", nationality: "Central African" },
    { alpha2: "TD", alpha3: "TCD", code: "148", name: "Chad", nationality: "Chadian" },
    { alpha2: "CL", alpha3: "CHL", code: "152", name: "Chile", nationality: "Chilean" },
    { alpha2: "CN", alpha3: "CHN", code: "156", name: "China", nationality: "Chinese" },
    { alpha2: "CX", alpha3: "CXR", code: "162", name: "Christmas Island", nationality: "Christmas Islander" }, // ***
    // { alpha2: "CC", alpha3: "CCK", code: "166", name: "Cocos (Keeling) Islands", nationality: "" },
    { alpha2: "CO", alpha3: "COL", code: "170", name: "Colombia", nationality: "Colombian" },
    { alpha2: "KM", alpha3: "COM", code: "174", name: "Comoros", nationality: "Comorian" },
    { alpha2: "CG", alpha3: "COG", code: "178", name: "Congo (Brazzaville)", nationality: "Congolese" },
    { alpha2: "CD", alpha3: "COD", code: "180", name: "Congo, (Kinshasa)", nationality: "Kinshasan" },
    { alpha2: "CK", alpha3: "COK", code: "184", name: "Cook Islands", nationality: "Cook Islander" }, // ***
    { alpha2: "CR", alpha3: "CRI", code: "188", name: "Costa Rica", nationality: "Costa Rican" },
    { alpha2: "CI", alpha3: "CIV", code: "384", name: "Côte d'Ivoire", nationality: "Ivorian" },
    { alpha2: "HR", alpha3: "HRV", code: "191", name: "Croatia", nationality: "Croatian" },
    { alpha2: "CU", alpha3: "CUB", code: "192", name: "Cuba", nationality: "Cuban" },
    { alpha2: "CW", alpha3: "CUW", code: "531", name: "Curaçao", nationality: "Curaçaoan" }, // ***
    { alpha2: "CY", alpha3: "CYP", code: "196", name: "Cyprus", nationality: "Cypriot" },
    { alpha2: "CZ", alpha3: "CZE", code: "203", name: "Czech Republic", nationality: "Czech" },
    { alpha2: "DK", alpha3: "DNK", code: "208", name: "Denmark", nationality: "Danish" },
    { alpha2: "DJ", alpha3: "DJI", code: "262", name: "Djibouti", nationality: "Djiboutian" },
    { alpha2: "DM", alpha3: "DMA", code: "212", name: "Dominica", nationality: "Dominican" },
    { alpha2: "DO", alpha3: "DOM", code: "214", name: "Dominican Republic", nationality: "Dominican" },
    { alpha2: "EC", alpha3: "ECU", code: "218", name: "Ecuador", nationality: "Ecuadorian" },
    { alpha2: "EG", alpha3: "EGY", code: "818", name: "Egypt", nationality: "Egyptian" },
    { alpha2: "SV", alpha3: "SLV", code: "222", name: "El Salvador", nationality: "Salvadorian" },
    { alpha2: "GQ", alpha3: "GNQ", code: "226", name: "Equatorial Guinea", nationality: "Equatorial Guinean" },
    { alpha2: "ER", alpha3: "ERI", code: "232", name: "Eritrea", nationality: "Eritrean" },
    { alpha2: "EE", alpha3: "EST", code: "233", name: "Estonia", nationality: "Estonian" },
    { alpha2: "ET", alpha3: "ETH", code: "231", name: "Ethiopia", nationality: "Ethiopian" },
    { alpha2: "FK", alpha3: "FLK", code: "238", name: "Falkland Islands (Malvinas)", nationality: "Falkland Islander" }, // ***
    { alpha2: "FO", alpha3: "FRO", code: "234", name: "Faroe Islands", nationality: "Faroe Islander" }, // ***
    { alpha2: "FJ", alpha3: "FJI", code: "242", name: "Fiji", nationality: "Fijian" },
    { alpha2: "FI", alpha3: "FIN", code: "246", name: "Finland", nationality: "Finnish" },
    { alpha2: "FR", alpha3: "FRA", code: "250", name: "France", nationality: "French" },
    { alpha2: "GF", alpha3: "GUF", code: "254", name: "French Guiana", nationality: "(French) Guianan" }, // ***
    { alpha2: "PF", alpha3: "PYF", code: "258", name: "French Polynesia", nationality: "French Polynesian" }, // ***
    // { alpha2: "TF", alpha3: "ATF", code: "260", name: "French Southern Territories", nationality: "" }, // ***
    { alpha2: "GA", alpha3: "GAB", code: "266", name: "Gabon", nationality: "Gabonese" },
    { alpha2: "GM", alpha3: "GMB", code: "270", name: "Gambia", nationality: "Gambian" },
    { alpha2: "GE", alpha3: "GEO", code: "268", name: "Georgia", nationality: "Georgian" },
    { alpha2: "DE", alpha3: "DEU", code: "276", name: "Germany", nationality: "German" },
    { alpha2: "GH", alpha3: "GHA", code: "288", name: "Ghana", nationality: "Ghanaian" },
    { alpha2: "GI", alpha3: "GIB", code: "292", name: "Gibraltar", nationality: "Gibraltarian" }, // ***
    { alpha2: "GR", alpha3: "GRC", code: "300", name: "Greece", nationality: "Greek" },
    { alpha2: "GL", alpha3: "GRL", code: "304", name: "Greenland", nationality: "Greenlander" }, // ***
    { alpha2: "GD", alpha3: "GRD", code: "308", name: "Grenada", nationality: "Grenadian" },
    { alpha2: "GP", alpha3: "GLP", code: "312", name: "Guadeloupe", nationality: "Guadeloupean" }, // ***
    { alpha2: "GU", alpha3: "GUM", code: "316", name: "Guam", nationality: "Guamanian" }, // ***
    { alpha2: "GT", alpha3: "GTM", code: "320", name: "Guatemala", nationality: "Guatemalan" },
    { alpha2: "GG", alpha3: "GGY", code: "831", name: "Guernsey", nationality: "Guernseyman" }, // ***
    { alpha2: "GN", alpha3: "GIN", code: "324", name: "Guinea", nationality: "Guinean" },
    { alpha2: "GW", alpha3: "GNB", code: "624", name: "Guinea-Bissau", nationality: "Bissau-Guinean" },
    { alpha2: "GY", alpha3: "GUY", code: "328", name: "Guyana", nationality: "Guyanese" },
    { alpha2: "HT", alpha3: "HTI", code: "332", name: "Haiti", nationality: "Haitian" },
    // { alpha2: "HM", alpha3: "HMD", code: "334", name: "Heard and McDonald Islands", nationality: "" },
    // { alpha2: "VA", alpha3: "VAT", code: "336", name: "Holy See (Vatican City State)", nationality: "" },
    { alpha2: "HN", alpha3: "HND", code: "340", name: "Honduras", nationality: "Honduran" },
    { alpha2: "HK", alpha3: "HKG", code: "344", name: "Hong Kong, SAR China", nationality: "Hongkonger" }, // ***
    { alpha2: "HU", alpha3: "HUN", code: "348", name: "Hungary", nationality: "Hungarian" },
    { alpha2: "IS", alpha3: "ISL", code: "352", name: "Iceland", nationality: "Icelandic" },
    { alpha2: "IN", alpha3: "IND", code: "356", name: "India", nationality: "Indian" },
    { alpha2: "ID", alpha3: "IDN", code: "360", name: "Indonesia", nationality: "Indonesian" },
    { alpha2: "IR", alpha3: "IRN", code: "364", name: "Iran, Islamic Republic of", nationality: "Iranian" },
    { alpha2: "IQ", alpha3: "IRQ", code: "368", name: "Iraq", nationality: "Iraqi" },
    { alpha2: "IE", alpha3: "IRL", code: "372", name: "Ireland", nationality: "Irish" },
    { alpha2: "IM", alpha3: "IMN", code: "833", name: "Isle of Man", nationality: "Manx" }, // ***
    { alpha2: "IL", alpha3: "ISR", code: "376", name: "Israel", nationality: "Israeli" },
    { alpha2: "IT", alpha3: "ITA", code: "380", name: "Italy", nationality: "Italian" },
    { alpha2: "JM", alpha3: "JAM", code: "388", name: "Jamaica", nationality: "Jamaican" },
    { alpha2: "JP", alpha3: "JPN", code: "392", name: "Japan", nationality: "Japanese" },
    { alpha2: "JE", alpha3: "JEY", code: "832", name: "Jersey", nationality: "Jerseyman" }, // ***
    { alpha2: "JO", alpha3: "JOR", code: "400", name: "Jordan", nationality: "Jordanian" },
    { alpha2: "KZ", alpha3: "KAZ", code: "398", name: "Kazakhstan", nationality: "Kazakhstani" },
    { alpha2: "KE", alpha3: "KEN", code: "404", name: "Kenya", nationality: "Kenyan" },
    { alpha2: "KI", alpha3: "KIR", code: "296", name: "Kiribati", nationality: "I-Kiribati" },
    { alpha2: "KP", alpha3: "PRK", code: "408", name: "Korea (North)", nationality: "North Korean" },
    { alpha2: "KR", alpha3: "KOR", code: "410", name: "Korea (South)", nationality: "South Korean" },
    { alpha2: "KW", alpha3: "KWT", code: "414", name: "Kuwait", nationality: "Kuwaiti" },
    { alpha2: "KG", alpha3: "KGZ", code: "417", name: "Kyrgyzstan", nationality: "Kyrgyz" },
    { alpha2: "LA", alpha3: "LAO", code: "418", name: "Lao PDR", nationality: "Lao" },
    { alpha2: "LV", alpha3: "LVA", code: "428", name: "Latvia", nationality: "Latvian" },
    { alpha2: "LB", alpha3: "LBN", code: "422", name: "Lebanon", nationality: "Lebanese" },
    { alpha2: "LS", alpha3: "LSO", code: "426", name: "Lesotho", nationality: "Mosotho" },
    { alpha2: "LR", alpha3: "LBR", code: "430", name: "Liberia", nationality: "Libyan" },
    { alpha2: "LY", alpha3: "LBY", code: "434", name: "Libya", nationality: "Libyan" },
    { alpha2: "LI", alpha3: "LIE", code: "438", name: "Liechtenstein", nationality: "Liechtensteiner" },
    { alpha2: "LT", alpha3: "LTU", code: "440", name: "Lithuania", nationality: "Lithuanian" },
    { alpha2: "LU", alpha3: "LUX", code: "442", name: "Luxembourg", nationality: "Luxembourger" },
    { alpha2: "MO", alpha3: "MAC", code: "446", name: "Macao, SAR China ", nationality: "Macau" }, // ***
    { alpha2: "MK", alpha3: "MKD", code: "807", name: "Macedonia, Republic of", nationality: "Macedonian" },
    { alpha2: "MG", alpha3: "MDG", code: "450", name: "Madagascar", nationality: "Malagasy" },
    { alpha2: "MW", alpha3: "MWI", code: "454", name: "Malawi", nationality: "Malawian" },
    { alpha2: "MY", alpha3: "MYS", code: "458", name: "Malaysia", nationality: "Malaysian" },
    { alpha2: "MV", alpha3: "MDV", code: "462", name: "Maldives", nationality: "Maldivian" },
    { alpha2: "ML", alpha3: "MLI", code: "466", name: "Mali", nationality: "Malian" },
    { alpha2: "MT", alpha3: "MLT", code: "470", name: "Malta", nationality: "Maltese" },
    { alpha2: "MH", alpha3: "MHL", code: "584", name: "Marshall Islands", nationality: "Marshallese" },
    { alpha2: "MQ", alpha3: "MTQ", code: "474", name: "Martinique", nationality: "Martinican" },
    { alpha2: "MR", alpha3: "MRT", code: "478", name: "Mauritania", nationality: "Mauritanian" },
    { alpha2: "MU", alpha3: "MUS", code: "480", name: "Mauritius", nationality: "Mauritian" },
    { alpha2: "YT", alpha3: "MYT", code: "175", name: "Mayotte", nationality: "Mahoran" }, // ***
    { alpha2: "MX", alpha3: "MEX", code: "484", name: "Mexico", nationality: "Mexican" },
    { alpha2: "FM", alpha3: "FSM", code: "583", name: "Micronesia, Federated States of", nationality: "Micronesian" },
    { alpha2: "MD", alpha3: "MDA", code: "498", name: "Moldova", nationality: "Moldovan" },
    { alpha2: "MC", alpha3: "MCO", code: "492", name: "Monaco", nationality: "Monégasque" },
    { alpha2: "MN", alpha3: "MNG", code: "496", name: "Mongolia", nationality: "Mongolian" },
    { alpha2: "ME", alpha3: "MNE", code: "499", name: "Montenegro", nationality: "Montenegrin" },
    { alpha2: "MS", alpha3: "MSR", code: "500", name: "Montserrat", nationality: "Montserratian" }, // ***
    { alpha2: "MA", alpha3: "MAR", code: "504", name: "Morocco", nationality: "Moroccan" },
    { alpha2: "MZ", alpha3: "MOZ", code: "508", name: "Mozambique", nationality: "Mozambican" },
    { alpha2: "MM", alpha3: "MMR", code: "104", name: "Myanmar", nationality: "Burmese" },
    { alpha2: "NA", alpha3: "NAM", code: "516", name: "Namibia", nationality: "Namibian" },
    { alpha2: "NR", alpha3: "NRU", code: "520", name: "Nauru", nationality: "Nauruan" },
    { alpha2: "NP", alpha3: "NPL", code: "524", name: "Nepal", nationality: "Nepalese" },
    { alpha2: "NL", alpha3: "NLD", code: "528", name: "Netherlands", nationality: "Dutch" },
    // { alpha2: "AN", alpha3: "", code: "", name: "Netherlands Antilles", nationality: "Netherlands Antillean" }, // ***
    { alpha2: "NC", alpha3: "NCL", code: "540", name: "New Caledonia", nationality: "New Caledonian" }, // ***
    { alpha2: "NZ", alpha3: "NZL", code: "554", name: "New Zealand", nationality: "New Zealander" },
    { alpha2: "NI", alpha3: "NIC", code: "558", name: "Nicaragua", nationality: "Nicaraguan" },
    { alpha2: "NE", alpha3: "NER", code: "562", name: "Niger", nationality: "Nigerien" },
    { alpha2: "NG", alpha3: "NGA", code: "566", name: "Nigeria", nationality: "Nigerian" },
    { alpha2: "NU", alpha3: "NIU", code: "570", name: "Niue", nationality: "Niuean" },
    { alpha2: "NF", alpha3: "NFK", code: "574", name: "Norfolk Island", nationality: "Norfolk Islander" }, // ***
    { alpha2: "MP", alpha3: "MNP", code: "580", name: "Northern Mariana Islands", nationality: "Northern Mariana Islander" }, // ***
    { alpha2: "NO", alpha3: "NOR", code: "578", name: "Norway", nationality: "Norwegian" },
    { alpha2: "OM", alpha3: "OMN", code: "512", name: "Oman", nationality: "Omani" },
    { alpha2: "PK", alpha3: "PAK", code: "586", name: "Pakistan", nationality: "Pakistani" },
    { alpha2: "PW", alpha3: "PLW", code: "585", name: "Palau", nationality: "Palauan" },
    { alpha2: "PS", alpha3: "PSE", code: "275", name: "Palestinian Territory", nationality: "Palestinian" },
    { alpha2: "PA", alpha3: "PAN", code: "591", name: "Panama", nationality: "Panamanian" },
    { alpha2: "PG", alpha3: "PNG", code: "598", name: "Papua New Guinea", nationality: "Papua New Guinean" },
    { alpha2: "PY", alpha3: "PRY", code: "600", name: "Paraguay", nationality: "Paraguayan" },
    { alpha2: "PE", alpha3: "PER", code: "604", name: "Peru", nationality: "Peruvian" },
    { alpha2: "PH", alpha3: "PHL", code: "608", name: "Philippines", nationality: "Filipino" },
    { alpha2: "PN", alpha3: "PCN", code: "612", name: "Pitcairn", nationality: "Pitcairn Islanders" }, // ***
    { alpha2: "PL", alpha3: "POL", code: "616", name: "Poland", nationality: "Polish" },
    { alpha2: "PT", alpha3: "PRT", code: "620", name: "Portugal", nationality: "Portuguese" },
    { alpha2: "PR", alpha3: "PRI", code: "630", name: "Puerto Rico", nationality: "Puerto Rican" }, // ***
    { alpha2: "QA", alpha3: "QAT", code: "634", name: "Qatar", nationality: "Qatari" },
    { alpha2: "RE", alpha3: "REU", code: "638", name: "Réunion", nationality: "Réunionese" }, // ***
    { alpha2: "RO", alpha3: "ROU", code: "642", name: "Romania", nationality: "Romanian" },
    { alpha2: "RU", alpha3: "RUS", code: "643", name: "Russian Federation", nationality: "Russian" },
    { alpha2: "RW", alpha3: "RWA", code: "646", name: "Rwanda", nationality: "Rwandan" },
    { alpha2: "SH", alpha3: "SHN", code: "654", name: "Saint Helena", nationality: "Saint Helenian" },
    { alpha2: "KN", alpha3: "KNA", code: "659", name: "Saint Kitts and Nevis", nationality: "Kittitian" },
    { alpha2: "LC", alpha3: "LCA", code: "662", name: "Saint Lucia", nationality: "Saint Lucian" },
    // { alpha2: "PM", alpha3: "SPM", code: "666", name: "Saint Pierre and Miquelon", nationality: "Saint-Pierrais" },
    { alpha2: "VC", alpha3: "VCT", code: "670", name: "Saint Vincent and Grenadines", nationality: "Vincentian and Grenadinian" }, // ***
    // { alpha2: "BL", alpha3: "BLM", code: "BLM", name: "Saint-Barthélemy", nationality: "Barthélemois" },
    { alpha2: "MF", alpha3: "MAF", code: "663", name: "Saint-Martin (French part)", nationality: "Saint-Martinois" }, // ***
    { alpha2: "WS", alpha3: "WSM", code: "882", name: "Samoa", nationality: "Samoan" },
    { alpha2: "SM", alpha3: "SMR", code: "674", name: "San Marino", nationality: "Sammarinese" },
    { alpha2: "ST", alpha3: "STP", code: "678", name: "Sao Tome and Principe", nationality: "São Toméan" },
    { alpha2: "SA", alpha3: "SAU", code: "682", name: "Saudi Arabia", nationality: "Saudi" },
    { alpha2: "SN", alpha3: "SEN", code: "686", name: "Senegal", nationality: "Senegalese" },
    { alpha2: "RS", alpha3: "SRB", code: "688", name: "Serbia", nationality: "Serbian" },
    { alpha2: "SC", alpha3: "SYC", code: "690", name: "Seychelles", nationality: "Seychellois" },
    // { alpha2: "", alpha3: "", code: "", name: "Scotland", nationality: "Scottish" },
    { alpha2: "SL", alpha3: "SLE", code: "694", name: "Sierra Leone", nationality: "Sierra Leonean" },
    { alpha2: "SG", alpha3: "SGP", code: "702", name: "Singapore", nationality: "Singaporean" },
    { alpha2: "SX", alpha3: "SXM", code: "534", name: "Sint Maarten (Dutch part)", nationality: "Sint Maartener" }, // ***
    { alpha2: "SK", alpha3: "SVK", code: "703", name: "Slovakia", nationality: "Slovak" },
    { alpha2: "SI", alpha3: "SVN", code: "705", name: "Slovenia", nationality: "Slovenian" },
    { alpha2: "SB", alpha3: "SLB", code: "090", name: "Solomon Islands", nationality: "Solomon Islander" },
    { alpha2: "SO", alpha3: "SOM", code: "706", name: "Somalia", nationality: "Somali" },
    { alpha2: "ZA", alpha3: "ZAF", code: "710", name: "South Africa", nationality: "South African" },
    // { alpha2: "GS", alpha3: "SGS", code: "239", name: "South Georgia and the South Sandwich Islands", nationality: "South Georgian" },
    { alpha2: "SS", alpha3: "SSD", code: "728", name: "South Sudan", nationality: "South Sudanese" },
    { alpha2: "ES", alpha3: "ESP", code: "724", name: "Spain", nationality: "Spanish" },
    { alpha2: "LK", alpha3: "LKA", code: "144", name: "Sri Lanka", nationality: "Sri Lankan" },
    { alpha2: "SD", alpha3: "SDN", code: "729", name: "Sudan", nationality: "Sudanese" },
    { alpha2: "SR", alpha3: "SUR", code: "740", name: "Suriname", nationality: "Surinamese" },
    // { alpha2: "SJ", alpha3: "SJM", code: "744", name: "Svalbard and Jan Mayen Islands", nationality: "" },
    { alpha2: "SZ", alpha3: "SWZ", code: "748", name: "Swaziland", nationality: "Swazi" },
    { alpha2: "SE", alpha3: "SWE", code: "752", name: "Sweden", nationality: "Swedish" },
    { alpha2: "CH", alpha3: "CHE", code: "756", name: "Switzerland", nationality: "Swiss" },
    { alpha2: "SY", alpha3: "SYR", code: "760", name: "Syrian Arab Republic (Syria)", nationality: "Syrian" },
    { alpha2: "TW", alpha3: "TWN", code: "158", name: "Taiwan", nationality: "Taiwanese" },
    { alpha2: "TJ", alpha3: "TJK", code: "762", name: "Tajikistan", nationality: "Tajikistani" },
    { alpha2: "TZ", alpha3: "TZA", code: "834", name: "Tanzania, United Republic of", nationality: "Tanzanian" },
    { alpha2: "TH", alpha3: "THA", code: "764", name: "Thailand", nationality: "Thai" },
    { alpha2: "TL", alpha3: "TLS", code: "626", name: "Timor-Leste", nationality: "East Timorese" },
    { alpha2: "TG", alpha3: "TGO", code: "768", name: "Togo", nationality: "Togolese" },
    // { alpha2: "TK", alpha3: "TKL", code: "772", name: "Tokelau", nationality: "Tokelauan" },
    { alpha2: "TO", alpha3: "TON", code: "776", name: "Tonga", nationality: "Tongan" },
    { alpha2: "TT", alpha3: "TTO", code: "780", name: "Trinidad and Tobago", nationality: "Trinidadian and Tobagonian" },
    { alpha2: "TN", alpha3: "TUN", code: "788", name: "Tunisia", nationality: "Tunisian" },
    { alpha2: "TR", alpha3: "TUR", code: "792", name: "Türkiye", nationality: "Turkish" },
    { alpha2: "TM", alpha3: "TKM", code: "795", name: "Turkmenistan", nationality: "Turkmenistani" },
    { alpha2: "TC", alpha3: "TCA", code: "796", name: "Turks and Caicos Islands", nationality: "Turks and Caicos Islander" }, // ***
    { alpha2: "TV", alpha3: "TUV", code: "798", name: "Tuvalu", nationality: "Tuvaluan" },
    { alpha2: "UG", alpha3: "UGA", code: "800", name: "Uganda", nationality: "Ugandan" },
    { alpha2: "UA", alpha3: "UKR", code: "804", name: "Ukraine", nationality: "Ukrainian" },
    { alpha2: "AE", alpha3: "ARE", code: "784", name: "United Arab Emirates", nationality: "Emirati" },
    { alpha2: "GB", alpha3: "GBR", code: "826", name: "United Kingdom", nationality: "British" },
    { alpha2: "US", alpha3: "USA", code: "840", name: "United States of America", nationality: "American" },
    { alpha2: "UY", alpha3: "URY", code: "858", name: "Uruguay", nationality: "Uruguayan" },
    // { alpha2: "UM", alpha3: "UMI", code: "581", name: "US Minor Outlying Islands", nationality: "" },
    { alpha2: "UZ", alpha3: "UZB", code: "860", name: "Uzbekistan", nationality: "Uzbekistani" },
    { alpha2: "VU", alpha3: "VUT", code: "548", name: "Vanuatu", nationality: "Vanuatuan" },
    { alpha2: "VE", alpha3: "VEN", code: "862", name: "Venezuela (Bolivarian Republic)", nationality: "Venezuelan" },
    { alpha2: "VN", alpha3: "VNM", code: "704", name: "Vietnam", nationality: "Vietnamese" },
    { alpha2: "VI", alpha3: "VIR", code: "850", name: "Virgin Islands, US", nationality: "American Virgin Islander" }, // ***
    // Welsh
    { alpha2: "WF", alpha3: "WLF", code: "876", name: "Wallis and Futuna Islands", nationality: "Wallisian" }, // ***
    // { alpha2: "EH", alpha3: "ESH", code: "732", name: "Western Sahara", nationality: "" },
    { alpha2: "YE", alpha3: "YEM", code: "887", name: "Yemen", nationality: "Yemeni " },
    { alpha2: "ZM", alpha3: "ZMB", code: "894", name: "Zambia", nationality: "Zambian" },
    { alpha2: "ZW", alpha3: "ZWE", code: "716", name: "Zimbabwe", nationality: "Zimbabwean" }
]
