<div class="registration-form f-sign-up">
    <div class="form-box">
        <div class="wrapper">
            <form name="registerForm" [formGroup]="registerForm" (ngSubmit)="register()" novalidate="true" autocomplete="off">
                <a class="btn-hide-form" [routerLink]="['/']">
                    <span class="txt">close</span>
                    <span class="ico"></span>
                </a>
                <h2 class="form-title">
                    <span class="txt">Register with Rocket Remit.</span>
                </h2>
                <div class="row name">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>first name</mat-label>
                            <input matInput name="first-name" type="text" formControlName="givenName" required="true" />
                            <div class="alert-block">
                                <span class="ico"></span>
                                <div class="help-tooltip">
                                    <span class="txt">your first name is required</span>
                                    <span class="rect"></span>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>middle name</mat-label>
                            <input matInput name="middle-name" type="text" formControlName="middleName" />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>last name</mat-label>
                            <input matInput name="last-name" type="text" formControlName="familyName" required="true" />
                            <div class="alert-block">
                                <span class="ico"></span>
                                <div class="help-tooltip">
                                    <span class="txt">your last name is required</span>
                                    <span class="rect"></span>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>   
                </div>
                <div class="row">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>mobile number</mat-label>
                            <input matInput axMsisdn countryCode="AU" name="mobileNumber" formControlName="msisdn" required="true"/>
                            <div class="alert-block">
                                <span class="ico"></span>
                                <div [ngSwitch]="error(msisdnCtrl)" class="help-tooltip">
                                    <span class="rect"></span>
                                    <span *ngSwitchCase="'required'" class="txt">Mobile number is required</span>
                                    <span *ngSwitchCase="'invalid-region'" class="txt">Mobile number must be Australian</span>
                                    <span *ngSwitchCase="'invalid-type'" class="txt">Must be a mobile number</span>
                                    <span *ngSwitchDefault class="txt">Mobile number is invalid</span>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>email address</mat-label>
                            <input matInput name="emailAddress" type="email" formControlName="email" required="true" />
                            <div class="alert-block">
                                <span class="ico"></span>
                                <div class="help-tooltip">
                                    <span class="txt" [ngSwitch]="error(emailCtrl)">
                                        <ng-container *ngSwitchCase="'required'">Your email is required</ng-container>
                                        <ng-container *ngSwitchDefault>Your email address is invalid</ng-container>
                                    </span>                                    
                                    <span class="rect"></span>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <mat-form-field class="passwordfield" [ngClass]="passwordStrength">
                            <mat-label>password</mat-label>
                            <input matInput name="choosePassword" type="password" formControlName="password" />
                            <div class="alert-block">
                              <span class="ico"></span>
                              <div [ngSwitch]="error(passwordCtrl)" class="help-tooltip">
                                <span class="rect"></span>
                                <span *ngSwitchCase="'required'" class="txt">Password is required</span>
                                <span *ngSwitchCase="'strength'" class="txt">Password strength is low</span>
                                <span *ngSwitchDefault class="txt">Password is invalid</span>
                              </div>
                            </div>
                          </mat-form-field>
                          <div class="password-strength" *ngIf="passwordCtrl">
                            <i class="question-icon">?</i> 
                            <span>Password Strength: <b>{{ passwordStrength == 'NONE' ? 'Low' : passwordStrength | titlecase }}</b></span>
                          </div>
                      </div>                      
                    <div class="form-group">
                        <mat-form-field>
                          <mat-label>promo code (optional)</mat-label>
                          <input matInput name="promo-code" type="text" formControlName="promoCode">
                        </mat-form-field>
                      </div>
                </div>

                <div class="alert alert-warning" role="alert" *ngIf="registerForm.hasError('duplicate')">
                    <a [routerLink]="['/login']">Your mobile number is already registered! Login here</a>
                </div>                
                <div class="row">
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary" [promiseBtn]="registerSubscription">
                            <span class="btn-label">REGISTER!</span>
                            <div class="btn-spinner-wrapper ng-scope"><span class="btn-spinner"></span></div>
                        </button>
                    </div>
                </div>
            </form>
            <a class="trustpilot" href="https://www.trustpilot.com/review/www.rocketremit.com" target="_blank">
                <div class="trustpilot-rating excelent">Excellent</div>
                <div class="trustpilot-logo"><span>Trustpilot</span></div>
            </a>
        </div>
    </div>
</div>