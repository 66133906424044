<div class="row">
    <div class="form-group">
        <mat-form-field>
            <mat-label>rocket wallet id</mat-label>
            <input matInput [formControl]="rocketWalletCtrl" type="text" inputmode="numeric">
            <div *ngIf="rocketWalletCtrl.errors" class="alert-block">
                <span class="ico"></span>
                <div [ngSwitch]="error(rocketWalletCtrl)" class="help-tooltip">
                    <span *ngSwitchCase="'required'" class="txt">rocket wallet required</span>
                    <span *ngSwitchDefault class="txt">rocket wallet is invalid</span>
                    <span class="rect"></span>
                </div>
            </div> 
        </mat-form-field>
    </div>
</div>