import {
    Directive,
    ElementRef,
    Self,
    OnInit
} from '@angular/core';

import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[formControl],[formControlName]'
})
export class FocusableControlDirective implements OnInit {

    constructor(
        @Self() private control: NgControl,
        private readonly elementRef: ElementRef<any>) {
    }

    ngOnInit(): void {

        (this.control.control as any).focus = () => this.elementRef.nativeElement.focus();
    }
}
