import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    openGet(url: string) {

        // this.cordovaService.openLinkInBrowser(url)
    }

    redirectGet(url: string) {

        window.location.href = url
    }

    redirectPost(url: string, params: any) {

        var mapForm = document.createElement("form")
        mapForm.method = "POST"
        mapForm.action = url
        Object.keys(params).forEach(function (param) {
            var mapInput = document.createElement("input")
            mapInput.type = "hidden"
            mapInput.name = param
            mapInput.setAttribute("value", params[param])
            mapForm.appendChild(mapInput)
        });
        document.body.appendChild(mapForm)
        mapForm.submit()
    }
}
