<div class="row">
    <div class="form-group ifsc">
        <mat-form-field>
            <mat-label>IFSC</mat-label>
            <input matInput [formControl]="ifscCtrl" type="text">
            <div *ngIf="ifscCtrl.errors" class="alert-block">
                <span class="ico"></span>
                <div [ngSwitch]="error(ifscCtrl)" class="help-tooltip">
                    <span *ngSwitchCase="'required'" class="txt">IFSC required</span>
                    <span *ngSwitchDefault class="txt">IFSC not supported</span>
                    <span class="rect"></span>
                </div>
            </div> 
            <mat-hint><div>{{ bankName }}</div><div>{{ branchName }}</div></mat-hint>
        </mat-form-field>
        <div></div>
    </div>
</div>
<div class="row">
    <div class="form-group">
        <mat-form-field>
            <mat-label>account number</mat-label>
            <input matInput [formControl]="accountNumCtrl" type="text" inputmode="numeric">
            <div *ngIf="accountNumCtrl.errors" class="alert-block">
                <span class="ico"></span>
                <div [ngSwitch]="error(accountNumCtrl)" class="help-tooltip">
                    <span *ngSwitchCase="'required'" class="txt">account number required</span>
                    <span *ngSwitchDefault class="txt">account number is invalid</span>
                    <span class="rect"></span>
                </div>
            </div> 
        </mat-form-field>
    </div>
</div>