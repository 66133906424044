<div class="registration-form">
  <a class="btn-hide-form" [routerLink]="['/']">
    <span class="txt">close</span>
    <span class="ico"></span>
  </a>
  <div class="form-box">
      <div class="wrapper">
          <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" novalidate autocomplete="off">
              <h2 class="form-title">
                  <span class="txt">Reset Password</span>
              </h2>
              <p class="description">
                  A password reset code has been sent to your mobile number.<br>
                  Please enter the code and choose a new password.
              </p>
              <div class="row">
                  <div class="col-md-offset-3 col-md-6">
                      <mat-form-field hideRequiredMarker="true">
                          <mat-label>password reset code</mat-label>
                          <input matInput formControlName="token" required="true" />
                          <div class="alert-block" *ngIf="tokenCtrl.invalid">
                            <span class="ico"></span>
                            <div class="help-tooltip">
                              <span class="txt">Invalid password reset code</span>
                              <span class="rect"></span>
                            </div>
                          </div>
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-offset-3 col-md-6">
                    <mat-form-field class="passwordfield" [ngClass]="passwordStrength">
                      <mat-label>password</mat-label>
                      <input matInput name="choosePassword" type="password" formControlName="password" />
                      <div class="alert-block" *ngIf="passwordCtrl.hasError('strengthLow')">
                        <span class="ico"></span>
                        <div class="help-tooltip">
                          <span class="txt">Password strength is low</span>
                          <span class="rect"></span>
                        </div>
                      </div>
                    </mat-form-field>
                    <div class="password-strength" *ngIf="passwordCtrl">
                      <i class="question-icon">?</i> 
                      <span>Password Strength: <b>{{ passwordStrength == 'NONE' ? 'Low' : passwordStrength | titlecase }}</b></span>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="form-group">
                      <button type="submit" class="btn btn-primary"><span class="btn-label">SUBMIT</span></button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</div>
