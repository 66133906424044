import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

  /**
   * A custom Angular form validator for evaluating password strength.
   * This validator uses the determinePasswordStrength method to evaluate the password,
   * and returns a validation error if the password strength is low, otherwise null.
   *
   * @param {AbstractControl} control - The form control being validated.
   * @returns {ValidationErrors | null} - A validation error object or null if the password strength is not low.
   */
  export const passwordStrengthValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
        return null;
    }
    
    return determinePasswordStrength(value) === 'LOW' ? { strength: 'Low' } : null;
  };

/**
 * Evaluates the strength of a provided password based on its composition and length.
 * 
 * Strengths are classified as:
 * - `None`: Empty or no input.
 * - `High`: Must contain at least one lowercase letter, one uppercase letter, one number, and one special character.
 * - `Medium`: Must contain at least one lowercase letter, one uppercase letter, and one number.
 * - `Low`: Passwords that don't meet the criteria for either high or medium strengths.
 *
 * @param {string} password - The password string to evaluate.
 * @returns {'None' | 'Low' | 'Medium' | 'High'} - A string representation of the password strength.
 */
export function determinePasswordStrength(password: string): 'NONE' | 'LOW' | 'MEDIUM' | 'HIGH' {

    const mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])');

    if (!password || !password.trim()) {
        return 'NONE';
    } else if (strongRegex.test(password)) {
        return 'HIGH';
    } else if (mediumRegex.test(password)) {
        return 'MEDIUM';
    } else {
        return 'LOW';
    }
}