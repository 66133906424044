import { Pipe, PipeTransform } from '@angular/core'
import { Currency } from '../model'

const CURRENCY_FORMAT_REGEXP = /^(\w{3})\{(\d+)\.(\d+)\}$/

export function parseCurrencyInfo(currencyInfo: string | Currency): Currency {

    if (currencyInfo instanceof Currency) {
        return currencyInfo
    } else if (typeof currencyInfo === 'string') {

        if (/\w{3}/.test(currencyInfo)) {

            const currency = Currency.of(currencyInfo)

            if (currency != null) {
                return currency
            } else {
                throw new Error('unknown currency code: ' + currencyInfo)
            }
        }

        const parts = currencyInfo.match(CURRENCY_FORMAT_REGEXP)

        if (parts !== null) {

            const code = parts[1]
            const exponent = parseInt(parts[2], 10)
            const precision = parseInt(parts[3], 10)

            if (isNaN(exponent)) {
                throw new Error('invalid integer ' + parts[2])
            }
            if (isNaN(precision)) {
                throw new Error('invalid integer ' + parts[3])
            }

            return new Currency(code, exponent, precision)
        }
    }

    throw new Error('invalid currency info supplied ' + currencyInfo)

}

@Pipe({
    name: 'axCurrency'
})
export class CurrencyPipe implements PipeTransform {

    transform(amount: number|string|null|undefined, currencyInfo: null | string | Currency): string | null {

        if (amount == null || amount === '' || amount !== amount) return null
        if (currencyInfo == null) return `${strToNumber(amount)}`

        const currency = parseCurrencyInfo(currencyInfo)

        return currency.format(strToNumber(amount))
    }
}

/**
 * Transforms a string into a number (if needed).
 */
function strToNumber(value: number | string): number {
    // Convert strings to numbers
    if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
        return Number(value);
    }
    if (typeof value !== 'number') {
        throw new Error(`${value} is not a number`);
    }
    return value;
}