import { Component, EventEmitter, Output } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { delay, map } from 'rxjs/operators'

import { Location } from '@angular/common'
import { CustomerService } from '../../../../data/service/customer.service'
import { ICustomer, IDepositAccount } from '../../../../data/types'

@Component({
    selector: 'rr-eft',
    templateUrl: './eft.component.html',
    styleUrls: ['./eft.component.scss']
})
export class EftComponent {

    readonly msisdn: Observable<string>

    bsb: string
    accountNum: string
    accountName: string

    loading = new BehaviorSubject<boolean>(true)
    spinner = this.loading.pipe(delay(500))

    @Output()
    back = new EventEmitter()

    constructor(customerService: CustomerService,
        private location: Location
    ) {

        this.msisdn = customerService.customer
            .pipe(
                map((customer: ICustomer) => customer.msisdn)
            )

        customerService.eftLoad()
            .subscribe({
                next: (depsitAccount: IDepositAccount) => {
                    this.bsb = depsitAccount.bsb
                    this.accountNum = depsitAccount.accountNum
                    this.accountName = depsitAccount.accountName
                },
                error: () => { },
                complete: () => this.loading.next(false)
            })
    }
    closeAction(): void {
        this.location.back()
    }
}
