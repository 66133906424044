import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { MenuModule } from './menu/menu.module';
import { RateCalculatorModule } from './rate-calculator/rate-calculator.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UserAgentInterceptor } from '@rr/dashboard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DASHBOARD_CONFIG } from '@rr/dashboard';
import { environment } from '../environments/environment';
import { CredentialsProvider, JWT_AUTH_HANDLER_CONFIG, NoopCredentialsProvider } from '@assentinel/auth';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MenuModule,
    RateCalculatorModule,
  ],
  providers: [
    UserAgentInterceptor,
    { provide: HTTP_INTERCEPTORS, useExisting: UserAgentInterceptor, multi: true },
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'outline',
            hideRequiredMarker: true
        },
        multi: false
    },
    { provide: DASHBOARD_CONFIG, useValue: environment },
    {
        provide: JWT_AUTH_HANDLER_CONFIG,
        useValue: {
            host: environment.serverUrl,
            loginEndpoint: '/v3/auth/login',
            validateEndpoint: '/v3/auth/validate',
            excludeRequestUrls: [ 
                '/v3/register',
                '/v3/auth/password/.*',
                '/v3/remit/corridor/.*'
             ],
        }
    },
    { 
        provide: CredentialsProvider, 
        useClass: NoopCredentialsProvider
    }
  ]
})
export class AppModule { 
  

  ngDoBootstrap() {}
}