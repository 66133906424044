import {
    Directive,
    HostListener,
    ElementRef,
    forwardRef,
    Input
} from '@angular/core'
import {
    ControlValueAccessor,
    Validator,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    AbstractControl,
    ValidationErrors
} from '@angular/forms'
import { Currency } from '../model'
import { CurrencyPipe, parseCurrencyInfo } from '../pipes/currency.pipe'


@Directive({
    selector: '[axCurrency]',
    providers: [
        CurrencyPipe,
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CurrencyDirective),
        multi: true,
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => CurrencyDirective),
        multi: true
    }]
})
export class CurrencyDirective implements ControlValueAccessor, Validator {

    @Input('axCurrency')
    set currencyInfo(currencyInfo: string | Currency) {

        if (currencyInfo) {
            this._currency = parseCurrencyInfo(currencyInfo)
        } else {
            this.viewValue = ''
            return
        }

        if (this._modelValue) {
            this.formatCurrency(this._modelValue)
        } else {
            this.viewValue = ''
        }
    }

    private _currency!: Currency
    private _isDisabled: boolean = false
    private _modelValue: number | null = null

    private onModelChange = (_: number | null) => {}

    constructor(
        private elementRef: ElementRef<HTMLInputElement>,
        private currencyPipe: CurrencyPipe
    ) {

        elementRef.nativeElement.type = 'text'
        elementRef.nativeElement.inputMode = 'decimal'
    }

    writeValue(amount: number): void {

        this._modelValue = amount

        if (this._modelValue) {
            this.formatCurrency(this._modelValue)
        } else {
            this.viewValue = ''
        }
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null
    }
    registerOnValidatorChange?(fn: () => void): void {

    }
/*
    @HostListener('keydown', ['$event'])
    handleKeydown(event: any) {

        const keyCode = event.which || event.charCode || event.keyCode

        console.log('keydown', keyCode)

        // backspace || delete || safari delete
        if (keyCode === 8 || keyCode === 46 || keyCode === 63272) {
        }
        // left or right arrows
        // tslint:disable-next-line:one-line
        else if (keyCode === 37 || keyCode === 39) {
        }
        // numbers 0-9
        // tslint:disable-next-line:one-line
        else if (keyCode >= 48 && keyCode <= 57) {
        }
        // decimal point symbol
        // tslint:disable-next-line:one-line
        else if (keyCode === 190) {
        }
        // minus symbol
        // tslint:disable-next-line:one-line
        else if (keyCode === 189) {
        }
        // ignore all remaining keys
        // tslint:disable-next-line:one-line
        else {
            event.preventDefault()
        }
    }
*/
    @HostListener('input', ['$event'])
    handleInput(event: any) {

        const viewValue = this.viewValue

        if (viewValue) {
            this._modelValue = this._currency.toAmountMinor(+viewValue.replace(/[^\d\.]/, ''))
        } else {
            this._modelValue = null
        }

        this.onModelChange(this._modelValue)
    }

    @HostListener('blur', ['$event'])
    handleBlur(event: any) {

        if (this._modelValue != null) {
            this.formatCurrency(this._modelValue)
        }
    }

    get viewValue() {
        return this.elementRef.nativeElement && this.elementRef.nativeElement.value
    }

    set viewValue(viewValue: string|null) {

        this.elementRef.nativeElement.value = viewValue ? viewValue : ''
    }

    formatCurrency(amount: number) {

        this.viewValue = this.currencyPipe.transform(amount, this._currency)
    }
}
