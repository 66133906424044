import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CurrencyDirective } from './directives/currency.directive';
import { MsisdnDirective } from './directives/msisdn.directive';

import { CurrencyPipe } from './pipes/currency.pipe';
import { MsisdnPipe } from './pipes/msisdn.pipe';

import { FocusableControlDirective } from './directives/reactive_directives/focusable-control.directive';

@NgModule({
    declarations: [
        CurrencyDirective,
        CurrencyPipe
    ],
    imports: [
        ReactiveFormsModule
    ],
    exports: [
        CurrencyDirective,
        CurrencyPipe
    ]
})
export class AxCurrencyModule { }


@NgModule({
    declarations: [
        MsisdnDirective,
        MsisdnPipe
    ],
    imports: [
        ReactiveFormsModule
    ],
    exports: [
        MsisdnDirective,
        MsisdnPipe
    ]
})
export class AxMsisdnModule { }

@NgModule({
    declarations: [
        FocusableControlDirective
    ],
    imports: [
        ReactiveFormsModule
    ],
    exports: [
        FocusableControlDirective
    ]
})
export class AxFocusableControlModule { }