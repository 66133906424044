import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import { HttpClient } from '@angular/common/http'

import { combineLatest, Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

import { DashboardConfig } from '../../../../../dashboard.config'
import { DASHBOARD_CONFIG } from '../../../../../dashboard.config.token'
import { IMoneyOperator } from '../../../../../data/types'
import { MoneyOperatorComponent } from '../money-operator-component'

@Component({
    selector: 'app-pacific-corridors',
    templateUrl: './pacific-corridors.component.html',
    styleUrls: [
        './pacific-corridors.component.scss',
        './anz-icon.css',
        './bsp-icon.css',
        './kinabank-icon.css',
        './westpac-icon.css'
    ]
})
export class PacificCorridorsComponent implements MoneyOperatorComponent, OnInit {

    private moneyOperator: IMoneyOperator

    bsbCtrl = new FormControl(null, [ Validators.required ])
    accountNumCtrl = new FormControl(null, [ Validators.required ])

    private onModelChange = (val) => {}
    private onValidatorChange = () => {}

    constructor(
        private http: HttpClient,
        @Inject(DASHBOARD_CONFIG) private config: DashboardConfig
    ) {

        combineLatest([ this.bsbCtrl.valueChanges, this.accountNumCtrl.valueChanges ])
            .subscribe(([bsb, accountNum]: [string, string]) => { 
                this.onModelChange({ bsb: bsb, accountNum: accountNum })
            })

        combineLatest([ this.bsbCtrl.statusChanges, this.accountNumCtrl.statusChanges ])
            .subscribe(_ => this.onValidatorChange())
    }
    
    setMoneyOperator(moneyOperator: IMoneyOperator): void {
        this.moneyOperator = moneyOperator
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {

        this.bsbCtrl.setAsyncValidators((control: AbstractControl): Observable<ValidationErrors | null> => {

            const bsb = control.value
            if (!bsb) {
                return of({ required: true })
            }

            if (bsb.length === 6) {

                return this.http.get(`${this.config.serverUrl}/v3/remit/agent/${this.moneyOperator.id}/check/${bsb}`)
                    .pipe(
                        map(() => null),
                        catchError(() => of({ 'bsb-invalid': true }))
                    )
            }

            return of({ 'bsb-invalid': true })
        })

        this.bsbCtrl.statusChanges.subscribe(_ => this.updateAccountNumValidator())

        this.updateAccountNumValidator()
    }

    updateAccountNumValidator(): void {

        if (this.bankCode == '088') {
            this.accountNumCtrl.setValidators([ Validators.required, Validators.pattern(/^\d{10}$/) ])
        } else {
            this.accountNumCtrl.setValidators([ Validators.required, Validators.pattern(/^\d{2,}$/) ])
        }
        this.accountNumCtrl.updateValueAndValidity()
    }

    writeValue(accountDetails: any) {

        if (accountDetails) {

            this.bsbCtrl.setValue(accountDetails.bsb)
            this.accountNumCtrl.setValue(accountDetails.accountNum)

            this.updateAccountNumValidator()
        }
    }

    registerOnChange(fn: any) {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any) {
    }

    validate(control: AbstractControl): ValidationErrors {
        
        if (this.bsbCtrl.invalid) {
            return this.bsbCtrl.errors
        }

        return this.accountNumCtrl.errors 
    }

    registerOnValidatorChange?(fn: () => void): void {
       this.onValidatorChange = fn
    }

    focus() {

        if (this.bsbCtrl.invalid) {
            (this.bsbCtrl as any).focus()
        }
        else if (this.accountNumCtrl.invalid) {
            (this.accountNumCtrl as any).focus()
        }
    }

    get bankCode() {

        const bsb = this.bsbCtrl.value

        if (bsb && bsb.length >= 3) {

            return bsb.substr(0, 3)
        }

        return '000'
    }

    error(formControl: FormControl) {

        return formControl.errors && Object.entries(formControl.errors)[0][0];
    }
}
