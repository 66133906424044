import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs'
import { catchError, delay, map, share, tap } from 'rxjs/operators'

import { AuthService } from '@assentinel/auth'

// import { environment } from '../../environments/environment'

import { DashboardConfig } from '../../dashboard.config'
import { DASHBOARD_CONFIG } from '../../dashboard.config.token'
import {
    IBalance,
    ICustomer,
    ICustomerAllDetails,
    ICustomerDetails,
    IDepositAccount,
    ILimitsAndFees,
    IRemitSubject,
    PendingTransactionResults,
    ServerError,
    TransactionLogResults
} from '../types'

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private balance: Subject<IBalance>
    private favorites: Subject<IRemitSubject[]>

    private userRegistrationStatus: string
    private userSurveyStatus: string
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        @Inject(DASHBOARD_CONFIG) private config: DashboardConfig
    ) { }

    get customer(): Observable<ICustomer> {

        return this.authService.user
            .pipe(
                map(user => {
                    return {
                        msisdn: user.username,
                        givenName: user.firstName,
                        familyName: user.surname
                    } as ICustomer
                })
            )
    }

    getBalance(): Observable<IBalance> {

        if (!this.balance) {
            this.refreshBalance()
        }

        return this.balance.asObservable()
    }

    refreshBalance(): Observable<IBalance> {

        if (!this.balance) {

            console.log('no previously defined balance')
            this.balance = new BehaviorSubject<IBalance>({ available: 0, pending: 0 })
        }

        this.http
            .get<IBalance>(`${this.config.serverUrl}/v3/customer/balance`)
            .pipe(
                catchError((error: any) => throwError(new ServerError(error))),
            )
            .subscribe((response: any) => {

                console.log('got balance: ', response)

                if (response.status === 'SUCCESS') {

                    const balance = {
                        available: response.availableBalance,
                        pending: response.pendingBalance
                    }

                    this.balance.next(balance)
                }

                if (response.status === 'ERROR') {
                    throw new ServerError(response.message)
                }
            })

        return this.balance.asObservable()
    }

    getLimitsAndFees(type: string): Observable<ILimitsAndFees> {

        return this.http
            .get<ILimitsAndFees>(`${this.config.serverUrl}/v3/customer/load/fees_limits/${type}`)
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

    cardLoad(amount: number): Observable<any> {

        return this.http
            .post(`${this.config.serverUrl}/v3/customer/load/card`,
                {
                    amount: amount,
                    ...this.config.fiserv
                })
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

    eftLoad(): Observable<IDepositAccount> {

        return this.http
            .get<IDepositAccount>(`${this.config.serverUrl}/v3/customer/load/eft`)
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

    poliLoad(amount: number): Observable<any> {

        return this.http
            .post(`${this.config.serverUrl}/v3/customer/load/poli`,
                {
                    amount: amount,
                    ...this.config.poli
                })
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

    getFavorites(): Observable<IRemitSubject[]> {

        if (!this.favorites) {

            this.refreshFavorites().subscribe()
        }

        return this.favorites
    }

    refreshFavorites(): Observable<IRemitSubject[]> {

        if (!this.favorites) {

            this.favorites = new BehaviorSubject<IRemitSubject[]>([])
        }

        const favorites = this.http
            .get<IRemitSubject[]>(`${this.config.serverUrl}/v3/customer/favourites`)
            .pipe(
                tap((favourites: IRemitSubject[]) => this.favorites.next(favourites)),
                share()
            )

        favorites.subscribe()

        return favorites
    }

    deleteFavorite(favoriteId: number) {

        this.http
            .delete<IRemitSubject[]>(`${this.config.serverUrl}/v3/customer/favourites/${favoriteId}`)
            .subscribe((favourites: IRemitSubject[]) => this.favorites.next(favourites))
    }

    getTransactionList(startIndex: number, pageSize: number): Observable<TransactionLogResults> {

        return this.http
            .get<TransactionLogResults>(`${this.config.serverUrl}/v3/customer/transactions`, {
                params: {
                    startIndex: startIndex,
                    pageSize: pageSize
                }
            })
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )

    }
    pendingTransactionList(): Observable<PendingTransactionResults> {

        return this.http
            .get<PendingTransactionResults>(`${this.config.serverUrl}/v3/customer/pending_transactions`)
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )

    }

    setRegistrationStatus(status: string): void {
        this.userRegistrationStatus = status;
    }

    setSurveyStatus(surveyStatus: string): void {
        this.userSurveyStatus = surveyStatus;
    }

    get registrationStatus(): string {
        return this.userRegistrationStatus;
    }
    get surveyStatus(): string {
        return this.userSurveyStatus;
    }

    getOccupations(): string[] {
        return [
            "Accommodation", "Accounting", "Advertising", "Agriculture", "Air Transport", "Appliances And Electrical Equipment", "Architectural Services",
            "Assembler", "Automobile Driver", "Automotive Electrician", "Banking", "Bar Attendant", "Boat And Caravan Dealer", "Boiler And Plate Work", "Bricklayer",
            "Building Construction", "Business And Professional Associations", "Business Services", "Butcher", "Cabinetmaker", "Cafes And Restaurants",
            "Caretaker", "Carpentry", "Cashier", "Cement", "Ceramic Goods", "Civil Engineer", "Cleaner", "Clerk", "Clothing", "Communication", "Information Technology",
            "Community Services", "Computing Professional", "Concreting", "Construction", "Counsellor", "Creative Arts", "Data Processing",
            "Defence", "Dental Nurses", "Dentistry", "Department And General Stores", "Education", "Electrical", "Employment Services", "Entertainment", "Fabrics And Household Textiles",
            "Farmer", "Floor And Wall Tiling", "Food Processing Machinery", "Food Products", "Footwear", "Forklift And Related Driver", "Freight Forwarding", "Furniture", "Gardeners",
            "Gas", "General Manager", "Glass And Glass Products", "Guards And Security Officers", "Hairdressers Beauty Salons", "Health Services", "Heating And Air Conditioning", "Home Duties", "Hospitals",
            "Housekeeper", "Industrial Machinery And Equipment", "Insurance", "Investment", "Labourer", "Laundries And Dry-Cleaners", "Leather And Leather Products", "Legal", "Libraries", "Liquor Stores",
            "Logging", "Lotteries", "Machine Operator", "Manufacturing", "Meat Products", "Mechanical Engineers", "Medicine", "Messengers And Delivery Officers", "Metal Products", "Migrant",
            "Mining", "Motor Vehicles", "Museums And Art Galleries", "Newsagents Stationers And Booksellers", "Non-Bank Finance", "Non-Building Construction", "Nurseries", "Nursing Homes",
            "Office Equipment And Computer Servicers", "Panel Beaters", "Pensioner", "Pest Control Services", "Pharmacies", "Photographers", "Photography Services", "Plant Hire And Leasing", "Plastering And Plaster Fixing",
            "Plastic And Related Products", "Plumbing", "Postal Clerks And Officers", "Production Manager", "Public Servant", "Publishing", "Quality Controllers", "Quantity Surveyors", "Radio And Television Services",
            "Rail Transport", "Real Estate Agent", "Receptionist", "Refrigerators And Household Appliances", "Registered Nurse", "Restaurants Hotels And Clubs",
            "Retailing", "Retired", "Road Transport", "Roof Tiling", "Rubber Products", "Sales And Marketing Managers", "Sales", "Teacher", "Screen Printer", "Second Hand Goods Dealer",
            "Service Stations", "Sewerage And Stormwater Drainage", "Shipping Agent", "Shoemaking And Repairing", "Smash Repairer", "Social Worker", "Sport And Recreation",
            "Stone Products", "Storage", "Student", "Surveying Services", "Taxi And Other Road Passenger Transport", "Technical Services", "Teller", "Textile Product", "Tobacco",
            "Toolmakers", "Tradespersons", "Transport Equipment", "Travel Agency Services", "Truck Driver", "Unemployed", "Vehicle Tradespersons", "Veterinary Services",
            "Waiters And Waitresses", "Wall And Floor Tilers", "Watchmakers And Jewellers", "Water Heating Systems", "Water Sewerage And Drainage", "Water Transport", "Wholesalers", "Other"
        ]
    }

    getUserProfile(): Observable<ICustomerAllDetails> {

        return this.http
            .get<ICustomerAllDetails>(`${this.config.serverUrl}/v3/customer`)
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

    saveDetails(details: ICustomerDetails) {

        return this.http
            .patch(`${this.config.serverUrl}/v3/customer`, details)
            .pipe(
                catchError((response: any) => throwError(() => new ServerError(response.error)))
            )
    }

}
