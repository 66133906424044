import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { IMoneyOperator } from '../../../../../data/types'
import { MoneyOperatorComponent } from '../money-operator-component';

@Component({
    selector: 'app-rocket-wallet',
    templateUrl: './rocket-wallet.component.html',
    styleUrls: ['./rocket-wallet.component.scss']
})
export class RocketWalletComponent implements MoneyOperatorComponent, OnInit {

    rocketWalletCtrl = new FormControl(null, [ Validators.required, Validators.pattern(/^\+\d{14}$/) ])

    private onModelChange = (val) => {}
    private onValidatorChange = () => {}

    constructor() { }

    setMoneyOperator(moneyOperator: IMoneyOperator): void {
    }
    
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {

        this.rocketWalletCtrl
            .valueChanges
            .subscribe((rocketWallet: string) => {
                this.onModelChange({ rocketWalletId: rocketWallet })
            })
        
        this.rocketWalletCtrl.statusChanges
            .subscribe(_ => this.onValidatorChange())
    }

    writeValue(accountDetails: any) {

        if (accountDetails) {

            this.rocketWalletCtrl.setValue(accountDetails.rocketWalletId)
        }
    }

    registerOnChange(fn: any) {
        this.onModelChange = fn
    }

    registerOnTouched(fn: any) {
    }

    validate(control: AbstractControl): ValidationErrors {
        
        return this.rocketWalletCtrl.errors 
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn
    }

    focus() {

        if (this.rocketWalletCtrl.invalid) {
            (this.rocketWalletCtrl as any).focus()
        }
    }

    error(formControl: FormControl) {

        return formControl.errors && Object.entries(formControl.errors)[0][0];
    }
}
