// For all variables, see bower_components/bootstrap/scss/_variables.scss

/* ==========================================================================
   Brand Colors
   ========================================================================== */

$brand-colour-1:                   #1e94d1;
$brand-colour-1H:                  #22a7eb;
$brand-colour-1A:                  #1b86bd;
$brand-colour-2:                   #9fc351;
$brand-colour-2H:                  #b5de5c;
$brand-colour-2A:                  #93b44b;
$brand-colour-title:               #2F3C43;
$brand-colour-text:                #6C777D;

$alert-colour:                     #D0021B;

$gray-dark:                 #373a3c;
$gray:                      #55595c;
$gray-light:                #b4c1d2;
$gray-lighter:              #dce1e7;
$gray-lightest:             #ecf1f6;

$brand-primary:             $brand-colour-1;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;




/* ==========================================================================
   Globals
   ========================================================================== */

$body-bg:                    #fff;
$body-color:                 $gray-dark;
$enable-flex:                false;
$enable-rounded:             true;



/* ==========================================================================
   Typography
   ========================================================================== */

$font-family-sans-serif:     'Source Sans Pro', sans-serif;
$font-family-kefa:           'kefaregular';
$font-family:                $font-family-sans-serif;
$font-size-root:             16px;
$link-color:                 $brand-primary;
$link-hover-color:           darken($link-color, 15%);
$link-hover-decoration:      underline;

//Headers
$font-size-h1:               2.5rem;
$font-size-h2:               2rem;
$font-size-h3:               1.75rem;
$font-size-h4:               1.5rem;
$font-size-h5:               1.25rem;
$font-size-h6:               1rem;


//$registration-status-transition-duration: .07s;
$registration-status-transition-duration:   .12s;
$registration-transition-duration:          .12s;
$transition-duration:                       .12s;

$colorText: #6C777D;
$duration: .07s;
$colorBrand1: #1e94d1;
$colorBrand1H: #22a7eb;
$colorBrand1A: #1b86bd;
$colorBrand2: #9fc351;
$colorBrand2H: #b5de5c;
$colorBrand2A: #93b44b;
$colorTitle: #2F3C43;


$rr-dialog-border-width: 2px;
$rr-dialog-border-radius: 9 / 14 * 1em;