<div class="ax-auth-login">
<div mat-dialog-title class="ax-login-title">Login</div>

<form (ngSubmit)="login()">
    <div mat-dialog-content>
        <div class="ax-credentials">
            <mat-form-field class="ax-username">
                <mat-label>{{ usernameLabel }}</mat-label>
                <input matInput [formControl]="usernameCtrl" />
                <mat-error *ngIf="usernameCtrl.invalid">{{ usernameLabel }} required</mat-error>
            </mat-form-field>
            <mat-form-field class="ax-pasword">
                <mat-label>{{ passwordLabel }}</mat-label>
                <input matInput type="password" [formControl]="passwordCtrl" />
                <mat-error *ngIf="passwordCtrl.invalid">{{ passwordLabel }} required</mat-error>
            </mat-form-field>
        </div>
        <div class="ax-auth-failure" *ngIf="authFailure">
            Invalid {{ usernameLabel }} or {{ passwordLabel }}
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit">{{ loginSubmitLabel }}</button>
    </div>
</form>
</div>