import { Component, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AuthenticationService } from '../../data/services/authentication.service';
import { determinePasswordStrength, passwordStrengthValidator } from '../../../shared/data/functions/password.functions';
import { ServerError } from '@rr/dashboard';
import { Subscription } from 'rxjs';
import { mobileRegionValidator, mobileTypeValidator } from '@assentinel/forms';
import { PhoneNumberType } from 'google-libphonenumber';
import { AnalyticsService } from '@rr/shared';

/**
 * The `RegisterComponent` provides a form for user registration. It uses Angular's
 * reactive forms for form handling, validation, and submission. It also communicates
 * with the backend server to submit the registration data and handle responses.
 *
 * @property {FormControl} mobileNumberCtrl - Control for the user's mobile number.
 * @property {FormControl} emailCtrl - Control for the user's email address.
 * @property {FormControl} passwordCtrl - Control for the user's password.

 * @property {FormGroup} registerForm - Form group containing all registration-related controls.
 */
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class RegisterComponent {

    analyticsService = inject(AnalyticsService)
    private gaClientId: string | null = null;

    // Define form controls
    msisdnCtrl = new FormControl('', { nonNullable: true, validators: [ Validators.required, mobileRegionValidator('AU'), mobileTypeValidator(PhoneNumberType.MOBILE) ]});
    emailCtrl = new FormControl('', { nonNullable: true, validators:[ Validators.required, Validators.email ] });
    passwordCtrl = new FormControl('', { nonNullable: true, validators:[ Validators.required, passwordStrengthValidator ] });
    registerSubscription: Subscription

    // Register form
    registerForm = new FormGroup({
        givenName: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
        middleName: new FormControl<string | null>(null),
        familyName: new FormControl('', { nonNullable: true, validators: Validators.required }),
        msisdn: this.msisdnCtrl,
        email: this.emailCtrl, 
        password: this.passwordCtrl,
        promoCode: new FormControl<string | null>(null),
    });

    /**
     * The constructor initializes the HttpClient and PasswordService dependencies.
     *
     * @param {HttpClient} http - The HttpClient for making HTTP requests.
     * @param {PasswordService} passwordService - The service for handling password-related operations.
     */
    constructor(private authService: AuthenticationService) {
        this.setGAClientID();
    }

    setGAClientID() {
        this.gaClientId = this.getGAClientIDFromCookie();
    }

    getGAClientIDFromCookie(): string | null {
        const gaCookie = document.cookie
          .split('; ')
          .find(row => row.startsWith('_ga='));
        if (gaCookie) {
          const gaCookieValue = gaCookie.split('=')[1];
          const parts = gaCookieValue.split('.');
          if (parts.length === 4) {
            return `${parts[2]}.${parts[3]}`;
          }
        }
        return null;
    }

    get passwordStrength(): string {

        return determinePasswordStrength(this.passwordCtrl.value)
    }

    /**
     * Handles the registration form submission. Validates the form data and makes an API call
     * to register the user. If the registration is successful, the response is logged. For errors, 
     * appropriate messages and flags are set based on the error details.
     */
    register() {

        if (this.registerForm.valid) {
            const registrationData = {
                ...this.registerForm.getRawValue(),
                gaClientId: this.gaClientId
            };
            this.registerSubscription = this.authService
                .signup(registrationData)
                .subscribe({
                    next: (response) => {
                        
                        this.analyticsService.gtmEvent('registration', {
                            promoCode: this.registerForm.controls.promoCode.value
                        })

                        this.authService.rememberDetails(this.msisdnCtrl.value)
                        this.authService.gotoWebapp()
                    },
                    error: (error: ServerError) => {

                        // Check the error code for duplicate account
                        if (error.code == 'ER-260') {
           
                            this.registerForm.setErrors({ duplicate: true })

                        } else {
   

                        }
                    }
                });

        } else {

            console.warn('Registration form is invalid!', this.registerForm);
        }
    }
    /**
     * Returns the key of the first error from the provided form control. Useful for error messaging.
     * @param {AbstractControl} control - The form control to inspect for errors.
     * @returns {string} - The key of the first error, or undefined if no errors are present.
     */
    error(control: AbstractControl) {

        return control.errors && Object.entries(control.errors)[0][0];
    }
}
