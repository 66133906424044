import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'

import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons'

import { AxCurrencyModule, AxFocusableControlModule, AxMsisdnModule } from '@assentinel/forms'

import { CardComponent } from './components/card/card.component'
import { PoliComponent } from './components/poli/poli.component'
import { EftComponent } from './components/eft/eft.component'
import { PoliFirstTimeLoadComponent } from './components/poli/poli-first-time-load/poli-first-time-load.component'

@NgModule({
    declarations: [
        CardComponent,
        EftComponent,
        PoliComponent,
        PoliFirstTimeLoadComponent
    ],
    exports: [
        CardComponent,
        EftComponent,
        PoliComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AxCurrencyModule,
        AxFocusableControlModule,
        AxMsisdnModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        Angular2PromiseButtonModule.forRoot()
    ]
})
export class LoadModule { }
