import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'


import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons'

import { AxCurrencyModule, AxFocusableControlModule, AxMsisdnModule } from 'libraries/assentinel/forms'
// import { AxFormFieldModule } from 'libraries/assentinel/material'

import { MobileWalletComponent } from './compontents/mobile-wallet/mobile-wallet.component'
import { MsisdnFavouritesDirective } from './compontents/mobile-wallet/msisdn-favourites.directive'
import { RemitCompleteComponent } from './compontents/remit-complete/remit-complete.component'

@NgModule({
  declarations: [
      MobileWalletComponent,
      MsisdnFavouritesDirective,
      RemitCompleteComponent
  ],
  exports: [
      MobileWalletComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AxCurrencyModule, 
    AxFocusableControlModule, 
    AxMsisdnModule,
    // AxFormFieldModule,

    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    Angular2PromiseButtonModule.forRoot()
  ]
})
export class RemitModule { }
