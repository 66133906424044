import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@assentinel/auth';
import { Currency } from '@assentinel/forms';
import { RemitService, ICorridorInfo } from '@rr/dashboard';
import { Subscription, interval } from 'rxjs';
import { AuthenticationService } from '../../../menu/data/services/authentication.service';


/**
 * Component responsible for calculating and displaying exchange rates.
 */
@Component({
    templateUrl: './rate-calculator.component.html',
    styleUrls: [ './rate-calculator.component.scss' ],
})
export class RateCalculatorComponent implements OnInit {
    // Inputs for sender and receiver details.
    @Input() senderCountryCode!: string;
    @Input() receiverCountryCode!: string;

    // Subscription to handle regular updates.
    private refreshSubscription?: Subscription;
    private authenticated = false



    // Form controls.
    sendAmountCtrl = new FormControl<number | undefined>(undefined, [Validators.required])
    receiveAmountCtrl = new FormControl<number | undefined>(undefined, [Validators.required])

    rateCalcForm = new FormGroup({
        sendAmount: this.sendAmountCtrl,
        receiveAmount: this.receiveAmountCtrl
    })

    corridorInfo?: ICorridorInfo; // Stores data related to currency corridor.

    constructor(
        private remitService: RemitService,
        private authService: AuthenticationService,
        axAuthService: AuthService,
        private router: Router
    ) {
        axAuthService.isAuthenticated().subscribe((authenticated: boolean) => this.authenticated = authenticated)
        this.initializeValueChangeListeners();
    }
    /**
     * Component initialization lifecycle hook.
     * Fetches the corridor info and sets up a refresh interval.
     */
    ngOnInit(): void {
        this.getCorridorInfo();
        this.refreshSubscription = interval(10 * 60 * 1000)
            .subscribe(() => this.getCorridorInfo());
    }
    /**
     * Component destruction lifecycle hook.
     * Cleans up any active subscriptions.
     */
    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    get receiverCountry(): string | null {

        return this.corridorInfo?.receiveCountry
    }

    get senderCurrency(): Currency | null {

        return this.corridorInfo?.sendCurrency ? Currency.of(this.corridorInfo?.sendCurrency) : null
    }

    get receiverCurrency(): Currency | null {
        
        return this.corridorInfo?.receiveCurrency ? Currency.of(this.corridorInfo?.receiveCurrency) : null
    }

    /**
     * Initialize listeners for form controls.
     */
    private initializeValueChangeListeners(): void {

        this.sendAmountCtrl.valueChanges.subscribe(value => {

            if (value && this.corridorInfo?.fxRate) {
                const calculatedValue = value * this.corridorInfo.fxRate;
                this.receiveAmountCtrl.setValue(calculatedValue, { emitEvent: false });
            } else {
                this.receiveAmountCtrl.setValue(undefined, { emitEvent: false });
            }
        });

        this.receiveAmountCtrl.valueChanges.subscribe(value => {

            if (value && this.corridorInfo?.fxRate) {
                const calculatedValue = value / this.corridorInfo.fxRate;
                this.sendAmountCtrl.setValue(calculatedValue, { emitEvent: false });
            } else {
                this.sendAmountCtrl.setValue(undefined, { emitEvent: false });
            }
        });
    }

    /**
     * Fetch the corridor information based on sender and receiver country codes.
     */
    private getCorridorInfo(): void {

        this.remitService.getCorridorInfo(this.senderCountryCode, this.receiverCountryCode)
            .subscribe({
                next: (res: ICorridorInfo) => {

                    this.corridorInfo = res;

                    this.sendAmountCtrl.clearValidators()
                    this.receiveAmountCtrl.clearValidators()

                    if (this.corridorInfo.sendLimit) {

                        this.sendAmountCtrl.addValidators(Validators.min(this.corridorInfo.sendLimit.min))
                        this.sendAmountCtrl.addValidators(Validators.max(this.corridorInfo.sendLimit.max))
                    }

                    if (this.corridorInfo.receiveLimit) {

                        this.receiveAmountCtrl.addValidators(Validators.min(this.corridorInfo.receiveLimit.min))
                        this.receiveAmountCtrl.addValidators(Validators.max(this.corridorInfo.receiveLimit.max))
                    }
                },
                error: (error) => {
                    console.error('Error fetching corridor info:', error);
                }
            });
    }

    /**
     * 
     */
    send() {

        if (this.sendAmountCtrl.valid) {

            if (this.authenticated) {

                this.authService.gotoWebapp()
    
            } else if (this.authService.isRegistered()) {
    
                this.router.navigate([ 'login' ])
                window.scrollTo({ top:0, behavior: 'smooth' })
    
    
            } else {
    
                this.router.navigate([ 'register' ])
                window.scrollTo({ top:0, behavior: 'smooth' })
            }
        }
    }


    /**
     * Returns the key of the first error from the provided form control. Useful for error messaging.
     * @param {AbstractControl} control - The form control to inspect for errors.
     * @returns {string} - The key of the first error, or undefined if no errors are present.
     */
    error(control: AbstractControl) {

        return control.errors && Object.entries(control.errors)[0][0];
    }
}
