import { Inject, Injectable, Optional } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { dfltLoginRouteConfig, LoginRouteConfig } from './auth.config'
import { LOGIN_ROUTE_CONFIG } from './auth.injectors'
import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    private _config: LoginRouteConfig

    constructor(
        private authService: AuthService,
        private router: Router,
        @Optional() @Inject(LOGIN_ROUTE_CONFIG) config: LoginRouteConfig) {

        this._config = {
            ...dfltLoginRouteConfig,
            ...config
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.authService.authenticated
            .pipe(
                map(authenticated => { 
                    return authenticated ? true : this.router.parseUrl(this._config.loginPath)
                }),
                catchError((e) => { 
                    return of(this.router.parseUrl(this._config.loginPath)) 
                })
            )
    }
}
